import { computed, onMounted, ref, watch } from 'vue';
import { LogTargetType } from '@/config/views/management/log/components/logTarget/logTypeSelect.define';
import { makeRows } from '@/common/utils/gridUtils';
import { ConditionRowData } from '@/config/views/alertGroup/alertRuleWindow.types';
import { useInternational } from '@/common/locale';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { getTargetLogPathListLogPathV8ControllerAxios } from '@/openapi/metav8Over/api/log-path-v8-controller-api';
import { CustomColumnsReadonly } from '@/common/utils';
import { TargetPathItem } from '@/openapi/metav8Over/model';
import { getHostPathListPathControllerAxios } from '@/openapi/metaV6/api/path-controller-api';

export interface Props {
  info: ConditionRowData['event'];
}

export interface Emit {
  (e: 'update:info', value: ConditionRowData['event']): void;
}

export const PATH_COLUMNS: CustomColumnsReadonly<keyof TargetPathItem | 'radio'> = [
  {
    caption: '',
    field: 'radio',
    type: 'number', // pathId
    searchable: false,
    width: 40,
  },
  {
    caption: 'Path Name',
    field: 'pathName',
    type: 'string',
    width: 175,
  },
  {
    caption: 'Log Type',
    field: 'targetType',
    type: 'string',
    width: 175,
    searchable: false,
  },
  {
    caption: 'Path',
    field: 'filePath',
    type: 'string',
    width: 175,
  },
  {
    caption: 'Filter',
    field: 'filterTypeValues',
    rendererType: 'chip-cell',
    type: 'string',
  },
  {
    caption: 'Parser',
    field: 'logParserItems',
    type: 'string',
    rendererType: 'chip-cell',
    searchable: false,
  },
  {
    caption: 'Path ID',
    field: 'pathId',
    type: 'number',
    hide: true,
  },
] as const;

type PathRow = [
  radio: number,
  name: string,
  targetType: LogTargetType,
  filePath: string,
  filterTypeValues: string[],
  logParserItems: string[],
  pathId: number,
];

export const PATH_GRID_IDX = {
  name: 1,
  targetType: 2,
  pathId: 6,
} as const;

const usePath = (props: Props, emit: Emit) => {
  const { t } = useInternational();
  const pathAccordion = [{ title: t('WORD.SELECT_PATH'), data: null }];

  const pathSearchWord = ref('');
  const selectedLogTypes = ref<LogTargetType[]>([]);

  const pathGridList = ref<PathRow[]>([]);
  const filteredPathGridList = computed(() => {
    if (!selectedLogTypes.value?.length) {
      return pathGridList.value;
    }

    return pathGridList.value.filter((row) =>
      selectedLogTypes.value.includes(row[PATH_GRID_IDX.targetType]),
    );
  });

  const findRowById = (pathId: string) =>
    pathGridList.value.find((row) => `${row[PATH_GRID_IDX.pathId]}` === `${pathId}`);

  const selectedPathId = computed({
    get: () => props.info.variableCondition.pathId[0],
    set: (pathId) => {
      emit('update:info', {
        ...props.info,
        variableCondition: {
          ...props.info.variableCondition,
          pathId: pathId ? [pathId] : [],
        },
      });
    },
  });
  const selectedPath = computed(() => findRowById(selectedPathId.value));

  const onSelectPath = (pathId: string) => {
    if (pathId === selectedPathId.value) {
      return;
    }

    selectedPathId.value = pathId;
  };

  const fetchPathList = async ({
    loggingId,
    targetIds,
  }: {
    loggingId: string;
    targetIds: string[];
  }) => {
    if (!loggingId || !targetIds.length) {
      pathGridList.value = [];
      return;
    }
    try {
      let data;
      const isUuid = Number.isNaN(Number(loggingId));
      if (isUuid) {
        const { data: res = {} } = await getTargetLogPathListLogPathV8ControllerAxios({
          loggingId,
          targetIds,
          frameName: FRAME_NAMES.CONFIG_USER_ALERT_RULE_WINDOW.PATH_LIST,
        });
        data = res.data?.targetPathItems;
      } else {
        const { data: res = {} } = await getHostPathListPathControllerAxios({
          loggingId: +loggingId,
          hostIds: targetIds,
          frameName: FRAME_NAMES.CONFIG_USER_ALERT_RULE_WINDOW.PATH_LIST,
        });
        data = res.data?.hostPathItems;
      }

      pathGridList.value = makeRows(data || [], PATH_COLUMNS, {
        radio: (_, row) => row?.pathId,
        filterTypeValues: (filterTypeValues) =>
          filterTypeValues?.map(({ filterDetail }) => filterDetail),
        logParserItems: (logParserItems) =>
          logParserItems?.map(({ parserPattern }) => parserPattern),
      });
    } catch (e) {
      pathGridList.value = [];
      console.log(e);
    }
  };

  watch(
    [() => props.info.variableCondition.loggingId[0], () => props.info.targets],
    ([loggingId, targets]) => {
      fetchPathList({
        loggingId,
        targetIds: targets.map(({ tagId: targetId }) => targetId),
      });
    },
    { immediate: true },
  );

  return {
    pathAccordion,

    selectedLogTypes,

    filteredPathGridList,
    pathSearchWord,
    selectedPath,
    selectedPathId,
    onSelectPath,
  };
};

const useSetting = () => {
  const { t } = useInternational();

  const settingAccordion = [{ title: t('WORD.ALERT_SETTING'), data: null }];

  return {
    settingAccordion,
  };
};

type PeriodUnit = 'm' | 'h' | 'd';

const postPeriodValidation = {
  isNumber: (val: string) => /^[0-9]+$/.test(val),
  inRange: ({ val, unit }: { val: string; unit: PeriodUnit }) => {
    const valNum = parseInt(val, 10);
    const range = {
      m: {
        min: 5,
        max: 4320,
      },
      h: {
        min: 1,
        max: 72,
      },
      d: {
        min: 1,
        max: 3,
      },
    };
    return valNum >= range[unit].min && valNum <= range[unit].max;
  },
};

export const parsePostPeriod = (period: string) =>
  (/^p([0-9]*)([a-z])$/.exec(period || '')?.slice(1) || []) as [val: string, unit: PeriodUnit];

export const validatePostPeriod = (period: string | null) => {
  if (period === null) {
    return true;
  }
  const [val, unit] = parsePostPeriod(period);
  if (!val || !unit) {
    return false;
  }
  return postPeriodValidation.inRange({ val, unit });
};
const formatPostPeriod = ({ val, unit }: { val?: string | number; unit?: PeriodUnit } = {}) =>
  !val || !unit ? null : `p${val}${unit}`;

const usePeriodSetting = (props: Props, emit: Emit) => {
  const { t } = useInternational();

  const periodUnitItems = [
    { name: t('WORD.MINUTE'), value: 'm' },
    { name: t('WORD.HOUR'), value: 'h' },
    { name: t('WORD.DAY'), value: 'd' },
  ];

  const selectedPeriodUnit = ref<PeriodUnit>('d');
  const selectedPeriodVal = ref('3');

  const selectedPeriod = computed<string | null>({
    get: () => (props.info.postPeriod != null ? 'custom' : null),
    set: (period) => {
      if (period !== null) {
        emit('update:info', {
          ...props.info,
          postPeriod:
            period === 'custom' ? `p${selectedPeriodVal.value}${selectedPeriodUnit.value}` : period,
        });
        return;
      }
      emit('update:info', {
        ...props.info,
        postPeriod: null,
      });
    },
  });

  const periodErrorMsg = ref('');
  const onPeriodValInput = (val: string) => {
    if (!postPeriodValidation.isNumber(val)) {
      selectedPeriodVal.value = selectedPeriodVal.value.slice(0, -1);
      return;
    }

    const periodInfo = { val, unit: selectedPeriodUnit.value };
    selectedPeriod.value = formatPostPeriod(periodInfo);

    if (!postPeriodValidation.inRange(periodInfo)) {
      periodErrorMsg.value = t('MESSAGE.ALERT_OWN_POSTING_PERIOD_ERR');
      return;
    }

    periodErrorMsg.value = '';
  };

  watch(selectedPeriodUnit, (unit) => {
    const periodInfo = { val: selectedPeriodVal.value, unit };
    selectedPeriod.value = formatPostPeriod(periodInfo);

    if (!postPeriodValidation.inRange(periodInfo)) {
      periodErrorMsg.value = t('MESSAGE.ALERT_OWN_POSTING_PERIOD_ERR');
      return;
    }

    periodErrorMsg.value = '';
  });

  onMounted(() => {
    const period = props.info.postPeriod;
    if (!period) {
      return;
    }

    const [val, unit] = parsePostPeriod(period);
    selectedPeriodUnit.value = (unit || 'd') as PeriodUnit;
    selectedPeriodVal.value = val || '3';
  });

  return {
    selectedPeriod,
    periodUnitItems,
    selectedPeriodUnit,
    selectedPeriodVal,
    periodErrorMsg,
    onPeriodValInput,
  };
};

export const setup = (props: Props, emit: Emit) => {
  const {
    pathAccordion,

    selectedLogTypes,

    filteredPathGridList,
    pathSearchWord,
    selectedPath,
    onSelectPath,
  } = usePath(props, emit);

  const { settingAccordion } = useSetting();

  const {
    selectedPeriod,
    periodUnitItems,
    selectedPeriodUnit,
    selectedPeriodVal,
    periodErrorMsg,
    onPeriodValInput,
  } = usePeriodSetting(props, emit);

  return {
    pathAccordion,

    selectedLogTypes,

    filteredPathGridList,
    pathSearchWord,
    selectedPath,
    onSelectPath,

    settingAccordion,

    selectedPeriod,
    periodUnitItems,
    selectedPeriodUnit,
    selectedPeriodVal,
    periodErrorMsg,
    onPeriodValInput,
  };
};
