<script setup lang="ts">
import SimpleTextTooltip from '@/common/components/molecules/simpleTextTooltip/SimpleTextTooltip.vue';
import { Props, setup } from './featuredIcon.setup';

const props = withDefaults(defineProps<Props>(), {
  iconCode: 'icon-multi',
  size: 'small',
});
const { featuredIcon, tooltipMouseEvent, tooltipText, onMouseEventInTarget } = setup(props);
</script>

<template>
  <template v-if="props.iconCode">
    <ev-icon
      :icon="featuredIcon"
      :size="props.size"
      class="featured-icon"
      @mouseenter="onMouseEventInTarget"
      @mouseleave="onMouseEventInTarget"
    />
    <simple-text-tooltip
      :mouse-event="tooltipMouseEvent"
      :text="tooltipText"
      base-pos="bottom"
    />
  </template>
</template>
