import { CSSProperties, nextTick, ref, watch } from 'vue';

// ## Comment: targetElement 기준
export type TooltipPosition = 'left' | 'right' | 'top' | 'bottom';

export interface Slots {
  default?: () => any;
}

export interface Props {
  mouseEvent: MouseEvent | null;
  basePos?: TooltipPosition;
  customClass?: string;
  showTail?: boolean;
  teleportTo?: string;
  text?: string;
  nonePadding?: boolean;
}

interface WindowSize {
  width: number;
  height: number;
  scrollX: number;
  scrollY: number;
}

interface GetAlignPosParams {
  tooltipRect: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  targetRect: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  windowSize: WindowSize;
  showTail: boolean;
}
const getVerticalPos = ({
  tooltipRect,
  targetRect,
  windowSize,
  showTail,
}: GetAlignPosParams): string => {
  let res = targetRect.y;

  if (showTail) {
    res = targetRect.y + targetRect.height / 2 - tooltipRect.height / 2;
  } else if (
    targetRect.y + tooltipRect.height > windowSize.height &&
    targetRect.y - tooltipRect.height > 0
  ) {
    res = targetRect.y - tooltipRect.height;
  }

  return `${res + window.scrollY}px`;
};
const getHorizontalPos = ({
  tooltipRect,
  targetRect,
  windowSize,
  showTail,
}: GetAlignPosParams): string => {
  let res = targetRect.x;

  if (showTail) {
    res = targetRect.x + targetRect.width / 2 - tooltipRect.width / 2;
  } else if (
    targetRect.x + tooltipRect.width > windowSize.width &&
    targetRect.x - tooltipRect.width > 0
  ) {
    res = targetRect.x - tooltipRect.width;
  }

  return `${res + window.scrollX}px`;
};

const getPosAndTooltipStyle = ({
  tooltipElement,
  target,
  windowSize,
  baseArrowPos,
  showTail,
}: {
  tooltipElement: HTMLElement;
  target: HTMLElement;
  windowSize: WindowSize;
  baseArrowPos: TooltipPosition;
  showTail: boolean;
}): { position: TooltipPosition; style: CSSProperties } => {
  const GAP = 15;
  const ARROW_SIZE = 4;
  const styleInfoByPos: Record<string, CSSProperties> = {};
  const possiblePos: TooltipPosition[] = [];
  const tooltipRect = tooltipElement.getBoundingClientRect();
  const targetRect = target.getBoundingClientRect();
  const { scrollX, scrollY } = windowSize;
  let position: TooltipPosition;
  let style: CSSProperties;
  let xp: number;
  let yp: number;

  const topPos = getVerticalPos({
    tooltipRect,
    targetRect,
    windowSize,
    showTail,
  });

  // ## right
  xp = targetRect.x + targetRect.width + tooltipRect.width + GAP + ARROW_SIZE;
  if (xp < windowSize.width) {
    possiblePos.push('right');
    styleInfoByPos.right = {
      left: `${targetRect.x + targetRect.width + GAP + scrollX}px`,
      top: topPos,
    };
  }

  // ## left
  xp = targetRect.x - tooltipRect.width - GAP - ARROW_SIZE;
  if (xp > 0) {
    possiblePos.push('left');
    styleInfoByPos.left = {
      left: `${targetRect.x - tooltipRect.width - GAP + scrollX}px`,
      top: topPos,
    };
  }

  const leftPos = getHorizontalPos({
    tooltipRect,
    targetRect,
    windowSize,
    showTail,
  });
  // ## top
  yp = targetRect.y - tooltipRect.height - GAP - ARROW_SIZE;
  if (yp > 0) {
    possiblePos.push('top');
    styleInfoByPos.top = {
      left: leftPos,
      top: `${targetRect.y - tooltipRect.height - GAP + scrollY}px`,
    };
  }

  // ## bottom
  yp = targetRect.y + targetRect.height + tooltipRect.height + GAP + ARROW_SIZE;
  if (yp < windowSize.height) {
    possiblePos.push('bottom');
    styleInfoByPos.bottom = {
      left: leftPos,
      top: `${targetRect.y + targetRect.height + GAP + scrollY}px`,
    };
  }

  if (styleInfoByPos[baseArrowPos]) {
    position = baseArrowPos;
    style = styleInfoByPos[baseArrowPos];
  } else {
    position = possiblePos?.[0] ?? 'right';
    style = styleInfoByPos?.[position] ?? {};
  }

  return {
    position,
    style,
  };
};

export const setup = (props: Required<Props>) => {
  const tooltipPosition = ref<TooltipPosition>(props.basePos);
  const tooltipRef = ref<HTMLElement | null>(null);
  const tooltipStyle = ref<CSSProperties>({});

  watch(
    () => props.mouseEvent,
    async (evt) => {
      // ## Comment: Text 갱신 시간
      await nextTick();
      // ## Comment: MouseEvent 에는 target 관련 type 이 정의되어 있지 않음.
      const targetElement = evt?.target ? (evt.target as HTMLElement) : null;
      const tooltipElement = tooltipRef.value;
      const windowSize = {
        width: window.innerWidth,
        height: window.innerHeight,
        scrollX: window.scrollX,
        scrollY: window.scrollY,
      };
      tooltipStyle.value = {
        zIndex: -100,
        left: 0,
        top: 0,
        visibility: 'hidden',
      };

      await nextTick();

      if (targetElement && tooltipElement) {
        const { position, style } = getPosAndTooltipStyle({
          tooltipElement,
          windowSize,
          target: targetElement,
          baseArrowPos: props.basePos,
          showTail: props.showTail,
        });

        tooltipPosition.value = position;
        tooltipStyle.value = {
          ...style,
        };
      }
    },
  );

  return {
    tooltipPosition,
    tooltipRef,
    tooltipStyle,
  };
};
