import { ref, computed } from 'vue';
import { useInternational } from '@/common/locale';
import { useSimpleTextTooltip } from '@/common/components/molecules/simpleTextTooltip/simpleTextTooltip.uses';
import { IconType } from '@/types/icon.types';
import type { ComposerTranslation } from 'vue-i18n';
import type { Size } from '@/types/common.types';

const ICON_INFO: Record<string, IconType> = {
  multi: 'icon-multi',
  infra: 'icon-featured-infrastructure',
  host: 'icon-featured-host',
  python: 'icon-python',
  kafka: 'icon-kafka',
  java: 'icon-java',
  app: 'icon-featured-application',
  k8s: 'icon-kubernetes-primary',
  log: 'icon-featured-log',
  sms: 'icon-sms',
  mysql: 'icon-mysql-color',
  oracle: 'icon-oracle',
  postgresql: 'icon-postgresql',
  sqlserver: 'icon-sqlserver-color',
  redis: 'icon-redis',
} as const;

type IconCode = (typeof ICON_INFO)[keyof typeof ICON_INFO];

export interface Props {
  iconCode: IconCode;
  size?: Size;
}

const useTooltip = (t: ComposerTranslation) => {
  const tooltipText = t('MESSAGE.UI.DASHBOARD.FEATURED_ICON_TOOLTIP');
  const tooltipMouseEvent = ref<MouseEvent | null>(null);
  const { onMouseEventInTarget } = useSimpleTextTooltip(tooltipMouseEvent);

  return {
    tooltipMouseEvent,
    tooltipText,
    onMouseEventInTarget,
  };
};

export const setup = (props: Props) => {
  const { t } = useInternational();
  const featuredIcon = computed<IconType>(() => ICON_INFO[props.iconCode]);
  const { tooltipMouseEvent, tooltipText, onMouseEventInTarget } = useTooltip(t);

  return {
    featuredIcon,
    tooltipMouseEvent,
    tooltipText,
    onMouseEventInTarget,
  };
};
