import animationData from '@/common/assets/loading/loading.json';
import { useInternational } from '@/common/locale';
import { usePropertyStore } from '@/common/stores/property';
import { AnimationItem } from 'lottie-web';
import lottie from 'lottie-web/build/player/lottie_light';
import { ref, onMounted, onUnmounted, computed } from 'vue';

const setup = () => {
  const { t } = useInternational();
  const customOptions = computed(() => usePropertyStore().getOptions?.loginOptions());
  const container = ref<HTMLDivElement | null>(null);
  let loadingScreenAnimation: AnimationItem | null = null;

  onMounted(() => {
    if (container.value) {
      loadingScreenAnimation = lottie.loadAnimation({
        container: container.value,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
      });
    }
  });

  onUnmounted(() => {
    if (loadingScreenAnimation) {
      loadingScreenAnimation.destroy();
    }
  });

  return {
    t,
    container,
    customOptions,
  };
};

export { setup };
