<script setup lang="ts">
import BreadCrumbElementLink from './BreadCrumbElementLink.vue';
import { type Emit, type Props, setup } from './breadCrumbElement.setup';

const props = defineProps<Props>();

const emit = defineEmits<Emit>();

const { singleMenu, nestedMenu, multiViewExpand, currentMultiViewExpandedMenu, onLinkClick } =
  setup(props, emit);
</script>

<template>
  <div
    v-if="singleMenu?.length"
    class="bread-crumb-element"
  >
    <bread-crumb-element-link
      v-for="child in singleMenu"
      :key="child.value"
      :menu="child"
      :mode="props.mode"
      :current-instance="props.currentInstance"
      :current-multi-view-expanded-menu="currentMultiViewExpandedMenu"
      @instance-multi-view-expand="(evt) => multiViewExpand(child, evt)"
      @link-clicked="() => onLinkClick(child)"
    >
      {{ child.text }}
    </bread-crumb-element-link>
  </div>
  <template
    v-for="menu in nestedMenu"
    :key="menu.value"
  >
    <div
      v-if="menu.children?.length"
      class="bread-crumb-element"
    >
      <strong class="bread-crumb-element__title">
        {{ menu.text }}
      </strong>
      <bread-crumb-element-link
        v-for="child in menu?.children"
        :key="child.value"
        :menu="child"
        @link-clicked="() => onLinkClick(child)"
      >
        {{ child.text }}
      </bread-crumb-element-link>
    </div>
  </template>
</template>

<style scoped lang="scss">
.bread-crumb-element {
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 4px;
    border-top: 1px solid var(--app-header-breadcrumb-border-color);
  }

  &__title {
    padding: 4px 12px 0;
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    color: var(--app-header-path-font-color);
  }
}
</style>
