/**
 * 10분 데이터
 */
import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { setEvBarChartData } from '@/common/utils/chartUtils';
import { convertCamelToCapital, getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { getTopFunctionPostgresqlV7ControllerAxios } from '@/openapi/postgresqlV7/api/postgresql-v7-controller-api';
import { TopFunctionItem } from '@/openapi/postgresqlV7/model';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { addXmUserEnvControllerAxios } from '@/openapi/metav8Over/api/xm-user-env-controller-api';

export interface TopFunctionState {
  topFunction: TopFunctionItem[];
  orderBy: string;
  errorStatusText: string;
}

const TOP_FUNCTION_ORDER_BY = 'PG_TOP_FUNCTION_ORDER_BY';
export const topFunction: Module<TopFunctionState, RootState> = {
  namespaced: true,
  state: {
    topFunction: [],
    orderBy: 'calls',
    errorStatusText: '',
  },
  mutations: {
    setTopFunction(state: TopFunctionState, data: TopFunctionItem[]) {
      state.topFunction = data;
    },
    setOrderBy: (state: TopFunctionState, orderByValue: string) => {
      state.orderBy = orderByValue;
    },
    setErrorStatusText: (state: TopFunctionState, errorStatusText: string) => {
      state.errorStatusText = errorStatusText;
    },
  },
  actions: {
    fetchTopFunctionOrderBy: async ({ commit, rootGetters }) => {
      const data = rootGetters['userEnv/getUserEnvMap'].get(TOP_FUNCTION_ORDER_BY);
      commit('setOrderBy', data || 'calls');
    },
    saveTopFunctionOrderBy: async ({ commit }, orderByValue: string) => {
      try {
        await commit('setOrderBy', orderByValue);
        await addXmUserEnvControllerAxios({
          request: [
            {
              key: TOP_FUNCTION_ORDER_BY,
              value: orderByValue,
            },
          ],
        });
        commit(
          'userEnv/setUserEnvMap',
          { key: TOP_FUNCTION_ORDER_BY, value: orderByValue },
          { root: true },
        );
      } catch (e: any) {
        console.log(e);
      }
    },
    async fetchTopFunction({ getters, commit }, { instanceId }) {
      const frameName = FRAME_NAMES.POSTGRESQL_SINGLE_VIEW.SQL_FUNCTION_TOP_FUNCTION;
      try {
        const orderBy = getters.getOrderBy;
        const { data } = await getTopFunctionPostgresqlV7ControllerAxios({
          instanceId,
          sort: orderBy,
          frameName: `${frameName} - ${convertCamelToCapital(orderBy)}`,
        });
        commit('setTopFunction', data.data);
        commit('setErrorStatusText', '');
        commit('postgresqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'postgresqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getTopFunction: (state: TopFunctionState) => state.topFunction,
    getFunctionName: (state: TopFunctionState) =>
      state.topFunction.map((v) => `${v.schemaName}.${v.functionName}`).reverse(),
    getCalls: (state: TopFunctionState) => setEvBarChartData(state.topFunction.map((v) => v.diff)),
    getErrorStatusText: (state: TopFunctionState) => state.errorStatusText,
    getOrderBy: (state: TopFunctionState) => state.orderBy,
  },
};
