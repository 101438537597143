import { WidgetUUID } from '@/common/utils';
import { inject, ref } from 'vue';
import { WIDGET_CLICK_KEY } from './useWidgetClickProvide';

export const useWidgetClickInject = () => {
  return inject(WIDGET_CLICK_KEY, {
    clickedWidgetId: ref(null),
    clickedWidgetInfo: ref<Map<WidgetUUID, Map<string, string | number | boolean | undefined>>>(
      new Map(),
    ),
    updateClickedWidgetInfo: () => {},
  });
};
