import { FilterUUID, WidgetUUID } from '@/common/utils';
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { FilterData } from '@/dashboard/types/filter';
import { cloneDeep } from 'lodash-es';
import { standardTimeToUtcZeroTime } from '@/common/utils/commonUtils';
import { encodeArgParam } from '../utils/dashboardUtils';
import { setInitFilterValue } from '../components/widgetSettingsWindow/filterWidgetSettings/filterData/filterData.utils';
import { WidgetModeType } from '../utils/types';

type FilterSearchInfo = Map<WidgetUUID, Map<FilterUUID, FilterData>>;
type FilterSearchInfoValue = FilterSearchInfo extends Map<any, infer V> ? V : never;

export const useFilterSearchStore = defineStore('filterSearchStore', () => {
  const filterInfo = ref<FilterSearchInfo>(new Map());

  const filterToSearch = ref<FilterSearchInfo>(new Map());
  const filterWidgetUUIDToSearch = ref<WidgetUUID | null>(null);

  const clearWidgetFilterInfo = (widgetUUID: WidgetUUID) => {
    const filters = filterInfo.value.get(widgetUUID);
    filters?.forEach((filter, key) => {
      filters.set(key, setInitFilterValue(filter));
    });
  };

  const getFilterInfo = (widgetUUID: WidgetUUID) => {
    return filterInfo.value.get(widgetUUID);
  };

  const getFilterData = (widgetUUID: WidgetUUID, filterUUID: FilterUUID) => {
    return filterInfo.value.get(widgetUUID)?.get(filterUUID);
  };

  const updateFilterInfo = <Data extends FilterData>(
    widgetUUID: WidgetUUID,
    filterUUID: FilterUUID,
    filterData: Data,
  ) => {
    if (!filterInfo.value.get(widgetUUID)) {
      filterInfo.value.set(widgetUUID, new Map());
    }
    return filterInfo.value.get(widgetUUID)?.set(filterUUID, filterData);
  };

  const setFilterWidgetUUIDToSearch = (widgetUUID: WidgetUUID | null) => {
    filterWidgetUUIDToSearch.value = widgetUUID;
  };

  const updateFilterToSearch = (
    widgetUUID: WidgetUUID,
    filterDatas: Map<FilterUUID, FilterData>,
  ) => {
    filterToSearch.value.set(widgetUUID, cloneDeep(filterDatas));
  };

  // string의 경우 ''로 감싼다.
  const getFilterValue = (widgetId: WidgetUUID, filterId: FilterUUID): string | undefined => {
    const filterData = filterToSearch.value.get(widgetId)?.get(filterId);
    switch (filterData?.filterType) {
      case 'toggle':
      case 'checkbox':
      case 'text':
      case 'number':
        return encodeArgParam(filterData.value);
      case 'calendar':
        return encodeArgParam(standardTimeToUtcZeroTime(filterData.value));
      case 'selectbox':
      case 'selectbox2':
      case 'target':
        switch (filterData.selectType) {
          case 'single':
            return encodeArgParam(filterData.value.optionItem);
          case 'multiple':
            return encodeArgParam(filterData.value.optionItems);
          default:
            return undefined;
        }
      default:
        return undefined;
    }
  };

  /**
   * widget setting에서 사용할 value
   */
  const filterInfoCopy = ref<FilterSearchInfoValue | undefined>();

  const syncFilterInfoCopy = (widgetUUID: WidgetUUID) => {
    filterInfoCopy.value = cloneDeep(filterInfo.value.get(widgetUUID));
  };

  const clearWidgetCopyFilterInfo = () => {
    filterInfoCopy.value?.forEach((filter, key) => {
      filterInfoCopy.value?.set(key, setInitFilterValue(filter));
    });
  };

  const getCopyFilterData = (filterUUID: FilterUUID) => {
    return filterInfoCopy.value?.get(filterUUID);
  };

  const deleteCopyFilterData = (filterUUID: FilterUUID) => {
    return filterInfoCopy.value?.delete(filterUUID);
  };

  const updateCopyFilterInfo = <Data extends FilterData>(
    filterUUID: FilterUUID,
    filterData: Data,
  ) => {
    if (!filterInfoCopy.value) {
      filterInfoCopy.value = new Map();
    }
    return filterInfoCopy.value.set(filterUUID, filterData);
  };

  const syncFilterInfo = (widgetUUID: WidgetUUID) => {
    if (filterInfoCopy.value) {
      if (!filterInfo.value.get(widgetUUID)) {
        filterInfo.value.set(widgetUUID, new Map());
      }
      filterInfoCopy.value.forEach((value, key) => {
        filterInfo.value.get(widgetUUID)?.set(key, value);
      });
    } else {
      filterInfo.value.delete(widgetUUID);
    }
  };

  const clearWidgetFilterValues = (mode: WidgetModeType, widgetUUID: WidgetUUID) => {
    if (mode === 'preview') {
      clearWidgetCopyFilterInfo();
    } else {
      clearWidgetFilterInfo(widgetUUID);
    }
  };

  const getWidgetFilterData = (
    mode: WidgetModeType,
    widgetUUID: WidgetUUID,
    filterUUID: FilterUUID,
  ) => {
    if (mode === 'preview') {
      return getCopyFilterData(filterUUID);
    }
    return getFilterData(widgetUUID, filterUUID);
  };

  const updateWidgetFilterData = <Data extends FilterData>(
    mode: WidgetModeType,
    widgetUUID: WidgetUUID,
    filterUUID: FilterUUID,
    newFilterData: Data,
  ) => {
    if (mode === 'preview') {
      updateCopyFilterInfo(filterUUID, newFilterData);
    } else {
      updateFilterInfo(widgetUUID, filterUUID, newFilterData);
    }
  };

  const isFilterSetted = (filterData: FilterData) => {
    if (filterData && filterData.required) {
      switch (filterData.filterType) {
        case 'text':
          return !(filterData.value == null || filterData.value === '');
        case 'number':
          return !(filterData.value == null);
        case 'calendar':
          return !(filterData.value == null || filterData.value === '');
        case 'selectbox':
        case 'selectbox2':
        case 'target':
          switch (filterData.selectType) {
            case 'single':
              return !(filterData.value.optionItem == null || filterData.value.optionItem === '');
            case 'multiple':
              return !(filterData.value.optionItems.length === 0);
            default:
              return true;
          }
        default:
          return true;
      }
    }
    return true;
  };

  const isWidgetRequiredNotSetted = (mode: WidgetModeType, widgetUUID: WidgetUUID) => {
    if (mode === 'preview') {
      const filters = filterInfoCopy.value;
      return filters ? Array.from(filters)?.some(([, item]) => !isFilterSetted(item)) : true;
    }
    const filters = filterInfo.value.get(widgetUUID);
    return filters ? Array.from(filters)?.some(([, item]) => !isFilterSetted(item)) : true;
  };

  const route = useRoute();
  watch(
    () => route.params.id,
    async () => {
      filterInfo.value = new Map();
      filterToSearch.value = new Map();
    },
  );

  return {
    filterInfo,
    getFilterInfo,
    getFilterData,
    filterToSearch,
    filterWidgetUUIDToSearch,
    setFilterWidgetUUIDToSearch,
    updateFilterToSearch,
    getFilterValue,
    updateFilterInfo,

    filterInfoCopy,
    syncFilterInfoCopy,
    getCopyFilterData,
    deleteCopyFilterData,
    updateCopyFilterInfo,

    getWidgetFilterData,
    clearWidgetFilterValues,
    updateWidgetFilterData,

    syncFilterInfo,

    isWidgetRequiredNotSetted,
  };
});
