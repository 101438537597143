import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getV7InstancesDatabaseInstanceV7ControllerAxios } from '@/openapi/database/api/database-instance-v7-controller-api';
import { getEnableInstancesDatabaseInstanceV8ControllerAxios } from '@/openapi/database/api/database-instance-v8-controller-api';
import type { DatabaseEnabledCountResponse } from '@/openapi/database/model/database-enabled-count-response';
import type { DbInstanceParentItemV7 } from '@/openapi/database/model/db-instance-parent-item-v7';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { useStore } from '@/common/store';
import router from '@/common/router';
import type { BreadCrumbMenuInfo } from '@/common/components/molecules/breadCrumbs/breadCrumbs.types';
import { BASE_MENU_VALUE } from '@/common/components/organisms/baseMenu/baseMenu.define';

export type InstanceType = 'singleView' | 'multiView';

export const GNB_DB_LISTS = [
  BASE_MENU_VALUE.ORACLE,
  BASE_MENU_VALUE.SQLSERVER,
  BASE_MENU_VALUE.MYSQL,
  BASE_MENU_VALUE.PG,
  BASE_MENU_VALUE.REDIS,
  BASE_MENU_VALUE.CUBRID,
  BASE_MENU_VALUE.MONGODB,
] as const;

export interface CurrentInstance {
  multiView: {
    name: string;
    id: string;
  };
  singleView: {
    name: string;
    id: string;
  };
  currentDbInstanceType?: InstanceType;
}

export const useInstanceStore = defineStore('instance', () => {
  const store = useStore();

  const instances = ref<DbInstanceParentItemV7[]>();
  const currentInstance = ref<CurrentInstance>({
    multiView: {
      name: 'Multi View',
      id: '',
    },
    singleView: {
      name: 'Single View',
      id: '',
    },
    currentDbInstanceType: 'multiView',
  });

  /**
   * 1. breadCrumbs에 사용
   */
  const getInstances = async () => {
    const { data } = await getV7InstancesDatabaseInstanceV7ControllerAxios({
      databaseTypes: [...GNB_DB_LISTS],
      frameName: FRAME_NAMES.INSTANCE_VIEW.INSTANCE_LIST,
    });

    instances.value = data.data;
  };

  const findMultiView = (id?: string) => {
    return instances.value?.find((instance) => instance.children?.some((child) => child.id === id));
  };

  const setCurrentInstance = (instance: Partial<CurrentInstance>) => {
    currentInstance.value = {
      ...currentInstance.value,
      ...instance,
    };
  };

  /**
   * 1. breadCrumbs
   * 2. Maxgauge 모드 > GNB
   */
  const changeInstance = async (menu: BreadCrumbMenuInfo) => {
    try {
      if (menu.instanceType === 'singleView') {
        const foundMultiView = findMultiView(menu.value);

        await store.dispatch(`${menu.dbType}SingleViewEnv/saveLastSelectedId`, {
          folderId: foundMultiView?.id, // multiView id
          instanceId: menu.value, // singleView id
        });
        setCurrentInstance({
          multiView: {
            name: foundMultiView?.name ?? 'Not Found',
            id: foundMultiView?.id ?? 'Not Found',
          },
          singleView: {
            name: menu.text ?? 'Not Found',
            id: menu.value ?? 'Not Found',
          },
          currentDbInstanceType: 'singleView',
        });
      } else if (menu.instanceType === 'multiView') {
        await store.dispatch(`${menu.dbType}MultiViewEnv/saveLastSelectedId`, {
          folderId: menu.value, // multiView id
        });
        setCurrentInstance({
          multiView: {
            name: menu.text ?? 'Not Found',
            id: menu.value ?? 'Not Found',
          },
          currentDbInstanceType: 'multiView',
        });
      }

      await router.replace('/loading');
      await router.replace(`/${menu.dbType}/${menu.instanceType}`);
    } catch (error) {
      console.log(error);
    }
  };

  const changeGNBInstance = (rootPath: string, instanceType: InstanceType) => {
    const [, routeRootPath, routeInstanceType] = router.currentRoute.value.path.split('/');
    if (routeRootPath === rootPath && routeInstanceType === instanceType) return;

    const findInstance = instances.value?.find((instance) => instance.type === rootPath);
    const findSingleView = findInstance?.children?.[0];

    let menuInfo: BreadCrumbMenuInfo = {
      dbType: rootPath,
      instanceType,
    };

    if (instanceType === 'multiView') {
      menuInfo = {
        ...menuInfo,
        text: findInstance?.name,
        value: findInstance?.id,
      };
    } else {
      menuInfo = {
        ...menuInfo,
        text: findSingleView?.name,
        value: findSingleView?.id,
      };
    }

    changeInstance(menuInfo);
  };

  /**
   * 맥스게이지 모드에서 사용
   */
  const instanceCount = ref<DatabaseEnabledCountResponse>();
  const getInstanceCount = async () => {
    const { data } = await getEnableInstancesDatabaseInstanceV8ControllerAxios({});

    instanceCount.value = data.data?.[0];
  };

  return {
    instances,
    currentInstance,
    getInstances,
    findMultiView,
    setCurrentInstance,
    changeInstance,
    changeGNBInstance,

    instanceCount,
    getInstanceCount,
  };
});
