import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { setEvBarChartData } from '@/common/utils/chartUtils';
import { convertCamelToCapital, getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { getSqlStatErrCntGridMysqlV7ControllerAxios } from '@/openapi/mysqlV7/api/mysql-v7-controller-api';
import { SqlStatErrCntGridItemV7 } from '@/openapi/mysqlV7/model';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { addXmUserEnvControllerAxios } from '@/openapi/metav8Over/api/xm-user-env-controller-api';

export interface TopSqlQueryErrorState {
  topSqlQueryError: SqlStatErrCntGridItemV7[];
  orderBy: string;
  errorStatusText: string;
}

const TOP_SQL_QUERY_ERROR_ORDER_BY_KEY = 'MYSQL_TOP_SQL_QUERY_ERROR_ORDER_BY';
export const topSqlQueryError: Module<TopSqlQueryErrorState, RootState> = {
  namespaced: true,
  state: {
    topSqlQueryError: [],
    orderBy: '',
    errorStatusText: '',
  },
  mutations: {
    setTopSqlQueryError(state: TopSqlQueryErrorState, data: SqlStatErrCntGridItemV7[]) {
      state.topSqlQueryError = data;
    },
    setOrderBy: (state: TopSqlQueryErrorState, orderByValue: string) => {
      state.orderBy = orderByValue;
    },
    setErrorStatusText: (state: TopSqlQueryErrorState, errorStatusText: string) => {
      state.errorStatusText = errorStatusText;
    },
  },
  actions: {
    fetchTopSqlQueryErrorOrderBy: ({ commit, rootGetters }) => {
      const data = rootGetters['userEnv/getUserEnvMap'].get(TOP_SQL_QUERY_ERROR_ORDER_BY_KEY);
      commit('setOrderBy', data || 'errCount');
    },
    saveTopSqlQueryErrorOrderBy: async ({ commit }, orderByValue: string) => {
      try {
        await commit('setOrderBy', orderByValue);
        await addXmUserEnvControllerAxios({
          request: [
            {
              key: TOP_SQL_QUERY_ERROR_ORDER_BY_KEY,
              value: orderByValue,
            },
          ],
        });
        commit(
          'userEnv/setUserEnvMap',
          { key: TOP_SQL_QUERY_ERROR_ORDER_BY_KEY, value: orderByValue },
          { root: true },
        );
      } catch (e: any) {
        console.log(e);
      }
    },
    async fetchTopSqlQueryError({ getters, commit }, { instanceId }) {
      const frameName = 'TopSqlQueryError';
      try {
        const orderBy = getters.getOrderBy;
        const { data } = await getSqlStatErrCntGridMysqlV7ControllerAxios({
          instanceId,
          sort: orderBy,
          size: 5,
          isFullText: false,
          frameName: `${
            FRAME_NAMES.MYSQL_SINGLE_VIEW.SQL_QUERY_ERROR_TOP_SQL
          } ${convertCamelToCapital(orderBy === 'errCount' ? 'errorCount' : orderBy)}`,
        });
        commit('setTopSqlQueryError', data.data);
        commit('setErrorStatusText', '');
        commit('mysqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'mysqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getTopSqlQueryError: (state: TopSqlQueryErrorState) => state.topSqlQueryError,
    getDigests: (state: TopSqlQueryErrorState) =>
      state.topSqlQueryError.map((v) => v.digest).reverse(),
    getValues: (state: TopSqlQueryErrorState) =>
      setEvBarChartData(
        state.topSqlQueryError.map((v) => {
          switch (state.orderBy) {
            case 'errCount':
              return v.errors;
            case 'warnCount':
              return v.warnings;
            default:
              return 0;
          }
        }),
      ),
    getErrorStatusText: (state: TopSqlQueryErrorState) => state.errorStatusText,
    getOrderBy: (state: TopSqlQueryErrorState) => state.orderBy,
  },
};
