import { makeRows } from '@/common/utils/gridUtils';
import { columnFormatter } from '@/alert/utils/utils';
import { AlertTargetItem } from '@/alert/components/alertTargetList/alertTargetList.types';
import { CustomColumn, CustomColumnsReadonly } from '@/common/utils/types';
import { AlertType } from '@/alert/components/alertDetail/alertDetail.types';
import { isDateTime } from '@/common/utils/time.utils';
import { utcZeroTimeToStandardTime } from '@/common/utils/commonUtils';
import { useStatInfoStore } from '@/common/stores/stat-info';
import { ASTER_KEY } from '@/dashboard/utils/define';
import { isK8sLoggingAlertCategory } from '@/config/views/alertGroup/alertRuleWindow.uses';
import { UserAlertType } from '@/config/views/alertGroup/alertRuleWindow.types';

export const TARGET_TYPE = {
  ALL: 'all',
  TARGETS: 'targets',
  TAGS: 'tags',
  NONE: 'none',
} as const;

export const ALERT_RULE_NAME_ICON = 'icon-child-right';

interface MakeAlertRowsParam {
  data: any[];
  columns: CustomColumn[] | CustomColumnsReadonly;
  targetFieldName?: string;
  targetType?: (typeof TARGET_TYPE)[keyof typeof TARGET_TYPE];
  formatter?: { [key in string]?: (v, item) => any };
  alertType?: AlertType;
  hasNullRow?: boolean;
}

interface TagsAndTargetsOts {
  type?: Capitalize<UserAlertType>;
}

const getItems = {
  tags: (items, { type }: TagsAndTargetsOts = {}): { tags: AlertTargetItem[] } => {
    if (!items?.length || !Array.isArray(items)) {
      return { tags: [] };
    }

    const formatName = ({ tagKey, tagName, tagId }) => {
      const isK8sEventLoggingAlert =
        type === 'Event' && isK8sLoggingAlertCategory({ category: tagKey });
      const showCategory = !isK8sEventLoggingAlert;

      if (showCategory) {
        return `${tagKey}:${tagName || tagId || ''}`;
      }

      if (tagId === ASTER_KEY) {
        return `${tagKey}:${tagId}`;
      }

      return `${tagName || tagId || ''}`;
    };

    return {
      tags: Array.from(
        new Map(
          items.map((item) => [
            item.tagId,
            {
              id: item.tagId,
              name: formatName(item),
              category: item.tagKey,
            },
          ]),
        ).values(),
      ),
    };
  },
  targets: (items): { targets: AlertTargetItem[] } => {
    if (!items?.length || !Array.isArray(items)) {
      return { targets: [] };
    }

    const formatName = ({ category, targetName, targetId }) => {
      const showCategory = category !== 'process';

      if (showCategory) {
        return `${category}:${targetName || targetId}`;
      }

      return `${targetName || targetId}`;
    };

    return {
      targets: Array.from(
        new Map(
          items.map((item) => {
            return [
              item.targetId,
              {
                id: item.targetId,
                name: formatName(item),
                category: item.category,
              },
            ];
          }),
        ).values(),
      ),
    };
  },
};

export const tagsAndTargetsController = {
  [TARGET_TYPE.TARGETS]: (data) => getItems.targets(data),
  [TARGET_TYPE.TAGS]: (data, options: TagsAndTargetsOts = {}) => getItems.tags(data, options),
  [TARGET_TYPE.ALL]: (data, options: TagsAndTargetsOts = {}) => ({
    ...getItems.targets(
      data
        ?.map((item) => item.targetItems)
        .flat()
        .filter((item) => !!item),
    ),
    ...getItems.tags(
      data
        ?.map((item) => item.tagItems)
        .flat()
        .filter((item) => !!item),
      options,
    ),
  }),
};

export const useAlertTagsAndTargets = () => {
  const statInfoStore = useStatInfoStore();
  const alertNameMapper = (v: string) => {
    return statInfoStore.getStatInfo({
      statId: v,
    })?.name;
  };

  const makeAlertRows = ({
    data,
    columns,
    targetFieldName = 'targets',
    targetType = TARGET_TYPE.ALL,
    formatter = {},
    hasNullRow = false,
  }: MakeAlertRowsParam) => {
    if (!data || !columns) {
      return [];
    }
    let alertData: any[] = data;
    if (hasNullRow) {
      alertData = alertData.filter((item) => !!item);
    }
    alertData = alertData.map((item) => {
      const tagsAndTargets =
        targetType === TARGET_TYPE.NONE
          ? {}
          : tagsAndTargetsController[targetType](item[targetFieldName], { type: item.type });
      return {
        ...item,
        ...tagsAndTargets,
      };
    });

    const mergedFormatter = {
      ...columnFormatter,
      alertName: alertNameMapper,
      ...formatter,
    };
    return makeRows(alertData, columns, mergedFormatter);
  };

  const makeAlertTreeRows = ({
    data,
    columns,
    targetFieldName = 'targets',
    targetType = TARGET_TYPE.ALL,
    formatter = {},
    hasNullRow = false,
  }: MakeAlertRowsParam) => {
    if (!data?.length) {
      return [];
    }
    let alertData: any[] = data;
    if (hasNullRow) {
      alertData = alertData.filter((item) => !!item);
    }

    const mergedFormatter = {
      ...columnFormatter,
      alertName: alertNameMapper,
      ...formatter,
    };

    return alertData.map((item) => {
      const formattedItem = columns.reduce(
        (acc, cur: CustomColumn) => {
          const { field } = cur;
          acc[field] = item[field];

          if (isDateTime(acc[field])) {
            acc[field] = utcZeroTimeToStandardTime(acc[field]);
          }

          if (mergedFormatter[field]) {
            acc[field] = mergedFormatter[field]!(acc[field], item);
          }

          return acc;
        },
        {} as Record<string, any>,
      );

      const tagsAndTargets =
        targetType === TARGET_TYPE.NONE
          ? {}
          : tagsAndTargetsController[targetType](item[targetFieldName]);

      return {
        ...formattedItem,
        ...tagsAndTargets,
        children: item.child?.length
          ? makeAlertTreeRows({
              data: item.child,
              columns,
              targetFieldName,
              targetType,
              formatter,
              hasNullRow,
            }).map((childItem) => ({
              ...childItem,
              ruleName: ALERT_RULE_NAME_ICON,
              uncheckable: true,
              isChild: true,
              children: undefined,
            }))
          : undefined,
      };
    });
  };

  return {
    tagsAndTargetsController,
    makeAlertRows,
    makeAlertTreeRows,
  };
};
