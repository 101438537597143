<script setup lang="ts">
import { setup } from './sessionTimer.setup';

const { t, isVisibleTimer, isExpiredSessionTime, displaySessionTime, isLessThanFiveMinutes } =
  setup();
</script>

<template>
  <div
    v-if="isVisibleTimer"
    :class="['login-session-timer', { 'login-session-timer--highlight': isLessThanFiveMinutes }]"
  >
    <div class="login-session-timer__time">
      <ev-icon
        icon="ev-icon-time"
        size="small"
      />
      <span class="display-time">{{ displaySessionTime }}</span>
    </div>
    <ev-button
      shape="border"
      class="login-session-timer__extend-btn"
      :disabled="isExpiredSessionTime"
    >
      {{ t('WORD.EXTENSION') }}
    </ev-button>
  </div>
</template>

<style scoped lang="scss">
.login-session-timer {
  display: flex;
  position: relative;
  flex-direction: row;
  gap: 7px;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 12px;
  margin: 0;

  &--highlight {
    *:not(:last-child) {
      color: var(--color-red-05) !important;
    }
  }

  &__time {
    display: flex;
    flex-direction: row;
    gap: 4px;
    .display-time {
      font-size: 14px;
      font-weight: 400;
    }
  }

  &__extend-btn {
    height: 20px !important;
  }
}
</style>
