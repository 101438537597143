import { computed, inject, onMounted, ref, watch } from 'vue';
import { getVisibleTagItems } from '@/common/utils/tag.util';
import { AlertTargetItem } from '@/alert/components/alertTargetList/alertTargetList.types';
import { useSlideDetailStore } from '@/common/stores/slide-detail';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { useInstanceDetail } from '@/database/utils/instanceDetail.uses';

export interface Props {
  targetList: AlertTargetItem[];
  shownCount?: number;
  isExpand?: boolean;
  isClickable?: boolean;
}

const LINKED_CATEGORY = ['database'];
const isClickableTag = (isClickable?: boolean, category?: string) =>
  isClickable && LINKED_CATEGORY.includes(category ?? '');

const useTargetDetail = (props: Props) => {
  const slideDetailStore = useSlideDetailStore();
  const { selectedSlideTabPanel } = storeToRefs(slideDetailStore);
  const { openInstanceDetail } = useInstanceDetail();

  const emitOpenDetail = inject<Function>('emitOpenDetail', () => {});

  const openDetail = async (item: AlertTargetItem) => {
    if (!isClickableTag(props.isClickable, item.category)) {
      return;
    }
    const [, name] = (item.name || '').split(':');
    await openInstanceDetail({
      instanceName: name,
      instanceId: item.id!,
      init: !selectedSlideTabPanel.value,
      slideCurrentState: {
        keepOpen: true,
      },
    });

    if (typeof emitOpenDetail === 'function') {
      emitOpenDetail();
    }
  };

  return {
    openDetail,
  };
};

const setup = (props: Props) => {
  const { name } = useRoute();
  const isBrowserPopup = computed(() => name === 'BrowserPopup');
  const { openDetail } = useTargetDetail(props);

  const tagWrapperRef = ref<HTMLElement>();

  const computedTargetList = computed(
    () =>
      props.targetList?.map((item) => {
        item.clickable = isClickableTag(props.isClickable, item.category);
        return item;
      }) ?? [],
  );
  const visibleTagItems = ref<AlertTargetItem[]>([]);

  const moreCount = ref<number>(0);
  const getMoreCount = () => {
    if (!computedTargetList.value?.length || props.isExpand) {
      return 0;
    }
    return computedTargetList.value.length - visibleTagItems.value.length;
  };
  const setVisibleTagItems = () => {
    if (!tagWrapperRef.value || !computedTargetList.value?.length) {
      return;
    }
    if (props.isExpand) {
      visibleTagItems.value = computedTargetList.value;
      moreCount.value = 0;
      return;
    }
    if (typeof props.shownCount === 'number') {
      visibleTagItems.value = computedTargetList.value.slice(0, props.shownCount);
      moreCount.value = computedTargetList.value.length - props.shownCount;
      return;
    }

    visibleTagItems.value = getVisibleTagItems<string>({
      wrapperEl: tagWrapperRef.value,
      itemList: computedTargetList.value?.map((item) => ({ ...item, value: item.name })),
      options: {
        wrapperPadding: 0,
        tagMaxWidthPercent: 1,
      },
    });
    moreCount.value = getMoreCount();
  };

  const ro = new ResizeObserver(() => {
    setVisibleTagItems();
  });

  watch(computedTargetList, setVisibleTagItems, { immediate: true, deep: true });

  onMounted(() => {
    if (!props.isExpand) {
      ro.observe(tagWrapperRef.value!);
    } else {
      setVisibleTagItems();
    }
  });

  const tooltipList = computed(() => {
    return moreCount.value ? [...props.targetList] : [];
  });

  const targetsTooltipRef = ref();
  const onClickMore = (e: MouseEvent) => {
    if (moreCount.value > 0) {
      targetsTooltipRef.value.show(e);
    }
  };

  const onClickTag = (item: AlertTargetItem, e?: MouseEvent) => {
    if (!item.clickable || isBrowserPopup.value) {
      return;
    }

    e?.stopPropagation();

    targetsTooltipRef.value.hide();
    openDetail(item);
  };

  return {
    isBrowserPopup,
    tagWrapperRef,
    visibleTagItems,

    tooltipList,
    moreCount,

    targetsTooltipRef,
    onClickMore,
    onClickTag,
  };
};

export { setup };
