import { ref, computed, watch } from 'vue';
import { TimePeriodData } from '@/types/common.types';
import {
  DEFAULT_PERIOD_ITEMS,
  getTimePeriodByRange,
} from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.utils';
import dayjs from 'dayjs';
import { webStorageController } from '@/common/utils/webStorage.util';
import { useRoute } from 'vue-router';
import { isEqual } from 'lodash-es';
import { useInternational } from '@/common/locale';
import { GLOBAL_TIME_RANGE_FORMAT } from '../timePeriodIndicator/timePeriodIndicator.define';

export interface Props {
  modelValue: string;
  periods?: TimePeriodData[];
  fromTime?: string;
  toTime?: string;
  disabled?: boolean;
  timeRangeSecLimit?: number;
  useCalendar?: boolean;
  guideMsg?: string;
}

export interface Emit {
  (e: 'update:modelValue', periodValue: string): void;
  (e: 'change-period', periodValue: string | string[]): void;
  (
    e: 'change-period-by-calendar',
    value: {
      fromTime: string;
      toTime: string;
      period: string;
    },
  ): void;
}

export const setup = (props: Props, emit: Emit) => {
  const { t } = useInternational();

  const isShowDropdown = ref(false);
  const isShowDatePicker = ref(false);
  const dateTimePeriod = ref<string[]>([]);

  const openDatePicker = () => {
    if (props.fromTime && props.toTime && props.modelValue.includes('custom')) {
      dateTimePeriod.value = [props.fromTime, props.toTime];
    } else {
      dateTimePeriod.value = [
        dayjs().startOf('day').format(GLOBAL_TIME_RANGE_FORMAT),
        dayjs().endOf('day').format(GLOBAL_TIME_RANGE_FORMAT),
      ];
    }

    isShowDatePicker.value = !isShowDatePicker.value;
  };

  const closeDatePicker = () => {
    isShowDatePicker.value = false;
  };

  const toggleDropdown = () => {
    if (props.disabled) {
      return;
    }
    isShowDropdown.value = !isShowDropdown.value;
  };

  const closeDropdown = () => {
    isShowDropdown.value = false;
  };

  const closeAll = () => {
    closeDatePicker();
    closeDropdown();
  };

  const usePropsPeriods = !!(props?.periods && props.periods.length);
  const periods = computed<TimePeriodData[]>(() => {
    return (usePropsPeriods ? props.periods : DEFAULT_PERIOD_ITEMS) as TimePeriodData[];
  });

  const quickPeriods = computed<TimePeriodData[]>(() => {
    return periods.value.filter(({ value }) => !value.includes('custom'));
  });

  const recentlySearches = ref<
    {
      id: number;
      value: TimePeriodData;
    }[]
  >([]);

  const route = useRoute();
  const storageKey = ref<string>('');
  watch(
    () => route,
    () => {
      storageKey.value = `timePeriodV2_recently_searches_${route.path}`;

      recentlySearches.value = JSON.parse(
        webStorageController.getItem({
          type: 'local',
          key: storageKey.value,
        }) ?? '[]',
      );
    },
    {
      immediate: true,
      deep: true,
    },
  );

  const MAXIMUM_HISTORY_SIZE = 5;
  const saveRecentlySearch = (param: TimePeriodData) => {
    const duplicatedItemIndex = recentlySearches.value.findIndex(({ value }) =>
      isEqual(value, param),
    );
    if (duplicatedItemIndex > -1) {
      recentlySearches.value.splice(duplicatedItemIndex, 1);
    }

    recentlySearches.value.unshift({
      id: Date.now(),
      value: param,
    });

    if (recentlySearches.value.length > MAXIMUM_HISTORY_SIZE) {
      recentlySearches.value.pop();
    }

    webStorageController.setItem({
      type: 'local',
      key: storageKey.value,
      value: JSON.stringify(recentlySearches.value),
    });
  };

  const deleteRecentlySearch = (index: number) => {
    recentlySearches.value.splice(index, 1);

    webStorageController.setItem({
      type: 'local',
      key: storageKey.value,
      value: JSON.stringify(recentlySearches.value),
    });
  };

  const selectedPeriod = computed({
    get: () => props.modelValue,
    set: (val: string) => {
      if (props.disabled) {
        return;
      }
      emit('update:modelValue', val);
      emit('change-period', val);
    },
  });

  const periodShortName = computed(
    () => periods.value.find(({ value }) => value === selectedPeriod.value)?.shortName,
  );

  const periodName = computed(
    () => periods.value.find(({ value }) => value === selectedPeriod.value)?.name,
  );

  const onClickQuickPeriod = (period: TimePeriodData) => {
    selectedPeriod.value = period.value;
    saveRecentlySearch(period);
    closeAll();
  };

  const onSelectByCalendar = (value: string[]) => {
    const fromTime = dayjs(value[0]).format(GLOBAL_TIME_RANGE_FORMAT);
    const toTime = dayjs(value[1]).format(GLOBAL_TIME_RANGE_FORMAT);
    const period = getTimePeriodByRange(value[0], value[1]);

    emit('change-period-by-calendar', {
      fromTime,
      toTime,
      period,
    });

    saveRecentlySearch({
      name: `${fromTime} ~ ${toTime}`,
      value: `${fromTime} ~ ${toTime}`,
      shortName: period,
    });

    closeAll();
  };

  const onClickRecentlySearch = (selectedValue: TimePeriodData) => {
    if (selectedValue.name.includes('~')) {
      const [fromTime, toTime] = selectedValue.name.split(' ~ ');

      emit('change-period-by-calendar', {
        fromTime,
        toTime,
        period: selectedValue.shortName,
      });
    } else {
      selectedPeriod.value = selectedValue.value;
    }

    closeAll();
  };

  return {
    t,

    isShowDropdown,
    toggleDropdown,
    closeAll,

    periodName,
    periodShortName,

    quickPeriods,
    onClickQuickPeriod,

    recentlySearches,
    onClickRecentlySearch,
    deleteRecentlySearch,

    isShowDatePicker,
    dateTimePeriod,
    openDatePicker,
    closeDatePicker,
    onSelectByCalendar,
  };
};
