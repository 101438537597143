import { type WidgetChartType } from '@/dashboard/utils/types';
import { type StatInfo } from '@/common/components/organisms/statWindow';
import { validStatByChartType } from '@/dashboard/utils/define';
import { StatCategory } from './statCategoryList';

export const sortParentCategory = (category1: string, category2: string) => {
  if (category1 === 'custom') {
    return -1;
  }
  if (category2 === 'custom') {
    return 1;
  }
  return category1.localeCompare(category2);
};

export const sortCategory = (category1: string, category2: string) => {
  if (category1 === 'common') {
    return -1;
  }
  if (category2 === 'common') {
    return 1;
  }
  return category1.localeCompare(category2);
};

export const sortTag = (tag1: string, tag2: string) => {
  return tag1.localeCompare(tag2);
};

export const mapChildCategoryToExact = ({ parentCategory, childCategory }: StatCategory) => {
  if (parentCategory === childCategory) {
    return 'common';
  }
  return childCategory;
};

export const mapChildCategoryWithParent = ({ parentCategory, childCategory }: StatCategory) => {
  return childCategory === 'common' ? parentCategory : childCategory;
};

export const setFilterStatList = ({
  statInfoList,
  tags = [],
  filterByFavorite = false,
  widgetChartType,
}: {
  statInfoList: StatInfo[];
  filterByFavorite?: boolean;
  tags?: string[];
  widgetChartType?: WidgetChartType;
}) => {
  return statInfoList.filter(({ tagList: statTagList, favorite, id }) => {
    const idMatching = validStatByChartType[widgetChartType ?? '']?.includes(id) ?? true;
    const matchesTags = tags?.some((tag) => statTagList.includes(tag));

    if (!tags?.length) {
      if (filterByFavorite) return favorite && idMatching;
      if (widgetChartType && Object.keys(validStatByChartType).includes(widgetChartType))
        return idMatching;
      return true;
    }

    if (widgetChartType && Object.keys(validStatByChartType).includes(widgetChartType)) {
      return filterByFavorite ? favorite && idMatching && matchesTags : idMatching && matchesTags;
    }

    return filterByFavorite ? favorite && matchesTags : matchesTags;
  });
};
