export const VIEW_MODE = {
  EXEMONE: 'exemone',
  MAXGAUGE: 'maxgauge',
  INTERMAX: 'intermax',
  CLOUDMOA: 'cloudmoa',
} as const;

export const PLATFORM = {
  INFRA: 'infrastructure',
  KUBE: 'kubernetes',
  APP: 'application',
  DB: 'database',
} as const;

export const MAXGAUGE_MODE_TARGET_LIST = ['database', 'host'] as const;
export const MAXGAUGE_MODE_TARGET_DB_LIST = [
  'oracle',
  'postgresql',
  'mysql',
  'sqlserver',
  'redis',
] as const;

export const LOGIN_CUSTOMER = {
  EXEMONE: 'EXEMONE',
  HYUNDAICAPITAL: 'HYUNDAICAPITAL',
  LGE: 'LGE',
} as const;
