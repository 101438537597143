<script setup lang="ts">
import ComponentLoading from '@/common/components/molecules/componentLoading/ComponentLoading.vue';
import BreadCrumbElement from './BreadCrumbElement.vue';
import { BREAD_CRUMBS_MODAL_CLASS } from '../breadCrumbs.define';
import { type Emit, type Props, setup } from './breadCrumbsModal.setup';

const props = defineProps<Props>();

const emit = defineEmits<Emit>();

const {
  multiViewChildren,
  multiViewChildrenStyle,

  searchDashboardWord,
  filteredMenuList,

  onMenuMouseLeave,
  onMenuCloseClick,
  instanceMultiViewExpand,
} = setup(props, emit);
</script>

<template>
  <teleport to="body">
    <div
      :class="BREAD_CRUMBS_MODAL_CLASS"
      :style="menuPosition"
      @mouseleave="onMenuMouseLeave"
    >
      <!-- Common, Dashboard -->
      <section :class="`${BREAD_CRUMBS_MODAL_CLASS}__content`">
        <ev-text-field
          v-if="props.mode === 'dashboard' && props.menuList?.children?.length"
          v-model="searchDashboardWord"
          type="search"
          :class="['size-small', `${BREAD_CRUMBS_MODAL_CLASS}__input`]"
        />
        <bread-crumb-element
          :menu-info="filteredMenuList"
          :mode="props.mode"
          :current-instance="props.currentInstance"
          :current-multi-view-expanded-menu="multiViewChildren?.value"
          @instance-multi-view-expand="instanceMultiViewExpand"
          @link-clicked="emit('close')"
        />

        <!-- dashboard 리스트를 불러오는게 느림, favorite 처리할 때 -->
        <component-loading v-if="props.menuList?.children?.length === 0 || props.loading" />
      </section>

      <!-- Instance > MultiView -->
      <section
        v-if="multiViewChildren"
        :style="multiViewChildrenStyle"
        :class="`${BREAD_CRUMBS_MODAL_CLASS}__content`"
      >
        <bread-crumb-element
          :menu-info="multiViewChildren"
          :mode="props.mode"
          :current-instance="props.currentInstance"
          @link-clicked="emit('close')"
        />
      </section>
      <ev-button
        v-if="props.isMenuToggle"
        :style="multiViewChildrenStyle"
        type="icon"
        shape="border"
        :class="`${BREAD_CRUMBS_MODAL_CLASS}__close`"
        @click="onMenuCloseClick"
      >
        <ev-icon icon="icon-close" />
      </ev-button>
    </div>
  </teleport>
</template>

<style scoped lang="scss">
.route-paths-modal {
  display: flex;
  position: fixed;
  z-index: 1000;
  align-items: flex-start;
  pointer-events: none;
  border-radius: 2px;
  @include set-scrollbar();

  &::before {
    position: absolute;
    top: -4px;
    left: 0;
    width: 100%;
    height: 4px;
    pointer-events: all;
    content: '';
  }

  &__close {
    position: absolute;
    top: 0;
    right: -28px;
    pointer-events: all;
  }

  &__content {
    position: relative;
    min-width: 200px;
    max-width: 320px;
    max-height: 240px;
    overflow-y: auto;
    pointer-events: all;
    background-color: var(--app-header-breadcrumb-bg-color);
    box-shadow: var(--dropdown-shadow);

    & + & {
      border-left: 1px solid var(--app-header-breadcrumb-border-color);
    }

    &:empty {
      + .route-paths-modal__close {
        display: none;
      }
    }
  }

  &__input {
    width: 100% !important;
    padding: 4px 8px;

    :deep(.ev-text-field-icon) {
      pointer-events: none;
    }
  }

  &:deep(.component-loading) {
    &:only-child {
      position: relative;
      width: 200px;
      height: 148px;
    }
  }
}
</style>
