import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { store } from '@/common/store';
import { useDashboardListViewStore } from '@/dashboard/stores/dashboard-list-view';
import { useUserEnvStore } from '@/common/stores/user-env';

export interface DashboardList {
  dashboardId: number;
  dashboardName: string;
  url?: string;
}

export interface urlInfo {
  checked: boolean;
  url: string;
  urlName: string;
  index: number;
  isGlobal: boolean;
  id: string;
}

export const useDashboardPopupEnvStore = defineStore('dashboardPopupEnvStore', () => {
  // setupDashBoardPopup.ts 에서 onMounted 훅에서 보장한다는 가정
  const dashboardListViewStore = useDashboardListViewStore();
  const { dashboardList } = storeToRefs(dashboardListViewStore);

  const dashboardName = ref('');
  const dashboardPopupListCnt = ref(0);
  const dashboardPopupList = ref<DashboardList[]>([]);
  const urlInfoList = ref<any>([]);

  const userEnvStore = useUserEnvStore();
  const isMaster = computed(() => store.getters?.['myInfo/getMyRole'] === 'master');

  const setupDashBoardData = ref({
    name: '',
    dashboardList: [] as any,
    urlList: [] as any,
    checked: false,
  });

  const initDashboardPopupListData = () => {
    dashboardPopupListCnt.value = 0;
    dashboardPopupList.value = [];
  };

  const initSetupDashboardData = () => {
    setupDashBoardData.value = {
      name: '',
      dashboardList: [],
      urlList: [],
      checked: false,
    };
  };

  const mergeObjects = (globalObj: urlInfo[], envObj: urlInfo[]) => {
    const mergedList = [...globalObj];

    envObj.forEach((item2) => {
      if (item2.isGlobal) {
        const foundIndex = mergedList.findIndex((item1) => item1.isGlobal && item1.id === item2.id);

        if (foundIndex !== -1) {
          mergedList[foundIndex] = {
            ...mergedList[foundIndex],
            checked: item2.checked,
          };
        }
      } else {
        mergedList.push(item2);
      }
    });

    return mergedList;
  };

  const fetchDashboardPopupEnvInfoForKey = async (envKey?: string) => {
    initDashboardPopupListData();
    if (!envKey) return;
    await userEnvStore.fetchEnvDataByKeys([`${envKey}_global`], true);
    const globalEnvData = userEnvStore.getEnvValue(`${envKey}_global`);
    const envData = userEnvStore.getEnvValue(envKey);

    if ((isMaster.value && globalEnvData) || (!envData && globalEnvData)) {
      const filteredDashboardList = globalEnvData.dashboardList.filter((v) => {
        return dashboardList.value.find((d) => d.dashboardId === v.dashboardId);
      });

      if (globalEnvData.urlList?.length > 0) {
        globalEnvData.urlList.forEach((v) => {
          if (v.checked) {
            filteredDashboardList.push({
              dashboardId: null,
              dashboardName: v.urlName,
              url: v.url,
            });
          }
        });
      }
      setupDashBoardData.value = {
        ...globalEnvData,
        dashboardList: filteredDashboardList,
        urlList: globalEnvData.urlList || [],
      };
      dashboardPopupListCnt.value = setupDashBoardData.value.dashboardList.length;
      dashboardPopupList.value = setupDashBoardData.value.dashboardList;
      dashboardName.value = setupDashBoardData.value.name;
      urlInfoList.value = setupDashBoardData.value.urlList || [];
    } else if (envData) {
      const filteredUrlList =
        globalEnvData?.urlList?.length > 0 || envData.urlList?.length > 0
          ? mergeObjects(globalEnvData?.urlList || [], envData.urlList)
          : [];
      const filteredDashboardList = [...envData.dashboardList].filter((v) => {
        return dashboardList.value.find((d) => d.dashboardId === v.dashboardId);
      });

      if (filteredUrlList?.length > 0) {
        filteredUrlList.forEach((v) => {
          if (v.checked) {
            filteredDashboardList.push({
              dashboardId: null,
              dashboardName: v.urlName,
              url: v.url,
            });
          }
        });
      }

      setupDashBoardData.value = {
        ...envData,
        dashboardList: filteredDashboardList,
        urlList: filteredUrlList,
      };
      dashboardPopupListCnt.value = setupDashBoardData.value.dashboardList.length;
      dashboardPopupList.value = setupDashBoardData.value.dashboardList;
      dashboardName.value = setupDashBoardData.value.name;
      urlInfoList.value = setupDashBoardData.value.urlList || [];
    } else {
      dashboardName.value = '';
      urlInfoList.value = [];
    }
  };

  const saveEnvInfoForKey = async (envKey: string, value: string) => {
    if (isMaster.value) {
      await userEnvStore.setEnvValue([{ key: `${envKey}_global`, value }], true);
    } else {
      await userEnvStore.setEnvValue([{ key: envKey, value }]);
    }

    dashboardName.value = setupDashBoardData.value.name;
  };

  return {
    dashboardPopupListCnt,
    dashboardPopupList,
    setupDashBoardData,
    fetchDashboardPopupEnvInfoForKey,
    saveEnvInfoForKey,
    initSetupDashboardData,
    dashboardName,
    urlInfoList,
  };
});
