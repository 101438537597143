import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useDashboardPopupEnvStore } from '@/common/stores/dashboard-popup';
import { storeToRefs } from 'pinia';
import { store } from '@/common/store';
import dayjs from 'dayjs';
import { DetailInfoData } from '@/common/utils/types';

export interface DashboardKey {
  target: string;
  targetId: string;
  namespace?: string;
}
export const useDashboardJump = () => {
  const isOpenDashboardSettingPopup = ref(false);
  const isOpenDashboardListPopup = ref(false);
  const router = useRouter();

  const { dashboardPopupListCnt, dashboardPopupList, dashboardName } = storeToRefs(
    useDashboardPopupEnvStore(),
  );

  const moveDashboard = (id: number, keyInfo?: DashboardKey) => {
    const tagString = keyInfo?.namespace
      ? `${keyInfo?.target}+${keyInfo?.targetId}+${keyInfo?.namespace}`
      : `${keyInfo?.target}+${keyInfo?.targetId}`;
    router.push({
      name: 'dashboard_Dashboard View',
      params: {
        id,
      },
      query: {
        tag: tagString,
      },
    });
  };

  const viewDashboardList = (infoDataList: DetailInfoData[], keyInfo: DashboardKey) => {
    const popupList = dashboardPopupList.value;
    const popupCount = dashboardPopupListCnt.value;
    const accountInfo = store.getters['myInfo/getAccountInfo'];
    const containerKey = infoDataList?.filter((data) => data.field === 'containerKey')[0]
      ?.values[0];

    if (popupCount > 1) {
      isOpenDashboardListPopup.value = !isOpenDashboardListPopup.value;
      if (isOpenDashboardSettingPopup.value) {
        isOpenDashboardSettingPopup.value = false;
      }
    } else if (popupCount === 1 && popupList?.[0]) {
      const popup = popupList[0];
      isOpenDashboardListPopup.value = false;

      if (popup.dashboardId) {
        moveDashboard(popup.dashboardId, keyInfo);
      } else if (popup.url) {
        const fromTime = +dayjs().tz().subtract(10, 'minutes');
        const toTime = +dayjs().tz();
        const url = popup.url
          .replace('$intermaxFromTime', fromTime.toString())
          .replace('$intermaxToTime', toTime.toString())
          .replace('$timeZone', accountInfo.timezone ?? '')
          .replace('$containerId', containerKey ?? '');

        location.replace(url);
      }
    }
  };

  return {
    isOpenDashboardSettingPopup,
    isOpenDashboardListPopup,
    dashboardPopupListCnt,
    dashboardPopupList,
    dashboardName,
    moveDashboard,
    viewDashboardList,
  };
};
