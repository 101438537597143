import { useUserEnvStore } from '@/common/stores/user-env';
import { CustomColumn, DynamicColumn, DynamicColumnData } from '@/common/utils';
import { calculateRowDepth, makeRows, processTreeNodes } from '@/common/utils/gridUtils';
import { ref } from 'vue';
import { getMergedColumns } from '@/common/utils/gridColumns.utils';
import { applyUserTags, getDynamicGridData } from '@/common/utils/gridDynamic.utils';

const getUpdatedColumns = <Column extends Readonly<CustomColumn> | CustomColumn>({
  baseColumns,
  dynamicColumns,
  newColumns,
  includeColumns,
  excludeColumns,
  changedColumns,
}: {
  baseColumns: Column[];
  dynamicColumns: DynamicColumn[];
  newColumns: string[];
  includeColumns?: string[];
  excludeColumns?: string[];
  changedColumns?: Column[];
}): Column[] => {
  const columnSet = new Set(newColumns);
  (includeColumns ?? []).forEach((columnName) => columnSet.add(columnName));
  (excludeColumns ?? []).forEach((columnName) => columnSet.delete(columnName));

  const findDynamicColumn = dynamicColumns.find((column) => column.field === 'userTags');
  const defaultColumns = changedColumns?.length
    ? changedColumns
    : baseColumns.map((col) =>
        col.caption ? col : { ...findDynamicColumn, ...col, caption: col.field },
      );
  const commonElements = defaultColumns.filter(
    (column: Column) => columnSet.has(column.field) || column.field === 'userTags',
  );

  const newElements = [...columnSet]
    .filter((columnName) => !defaultColumns.some((column) => column.field === columnName))
    .map((columnName) => {
      return {
        ...findDynamicColumn,
        caption: findDynamicColumn?.caption ?? columnName,
        field: columnName,
      };
    }) as Column[];

  return [...commonElements, ...newElements];
};

export const useDynamicColumns = () => {
  const userEnvStore = useUserEnvStore();

  const gridChangeColumnsData = ref<CustomColumn[] | undefined>();

  const applyChangeColumnInfo = (columns: CustomColumn[]) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    gridChangeColumnsData.value = columns.map(({ index, ...rest }) => ({ ...rest }));
  };

  const makeColumnsAndRows = <
    T extends Record<string, any>,
    Column extends Readonly<CustomColumn> | CustomColumn,
  >(
    data: DynamicColumnData<T>[] = [],
    baseColumns: Column[] = [],
    option: {
      defaultColumns?: Column[];
      dynamicColumns?: DynamicColumn[];
      columnOption?: {
        includeColumns?: string[];
        excludeColumns?: string[];
        omitMatchColumns?: string[];
      };
      columnFormatter?: { [K in Column['field']]?: (field: T[K & keyof T], row?: T) => any };
      originTimeFields?: string[];
      columnEnvKey?: string;
    } = {},
  ): { columns: Column[]; rowData: any[] } => {
    const { columns = [], rowData = [] } = data[0] ?? {};
    const {
      dynamicColumns = [],
      columnOption = {},
      columnFormatter,
      originTimeFields,
      columnEnvKey,
    } = option;
    const { includeColumns, excludeColumns } = columnOption ?? {};

    const isTreeRowData = rowData.every((row) => 'children' in row);

    if (dynamicColumns.length === 0 || columns.length === 0) {
      const defaultColumns =
        option?.defaultColumns ??
        gridChangeColumnsData.value ??
        getMergedColumns({
          baseColumns,
          storedColumns: userEnvStore.getEnvValue(columnEnvKey),
        });

      return {
        columns: defaultColumns,
        rowData: makeRows(rowData, defaultColumns, columnFormatter, originTimeFields),
      };
    }

    const baseFields = baseColumns.map((col) => col.field);
    const storedColumns =
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      userEnvStore.getEnvValue(columnEnvKey)?.map(({ index, ...rest }) => rest) ?? [];
    const defaultColumns = getMergedColumns({
      baseColumns,
      storedColumns,
      dynamicColumnFields: columns.filter(
        (field) => ![...baseFields, ...(excludeColumns ?? [])].includes(field),
      ),
    });

    const matchColumns = getUpdatedColumns<Column>({
      baseColumns: defaultColumns,
      changedColumns: option?.defaultColumns ?? gridChangeColumnsData.value,
      dynamicColumns,
      newColumns: columns,
      includeColumns,
      excludeColumns,
    });

    const excludeFields = isTreeRowData
      ? columnOption.omitMatchColumns ?? []
      : baseColumns?.map(({ field }) => field) ?? [];

    const dynamicDataOption = { excludeFields };

    const dynamicDataKey = Object.keys(
      getDynamicGridData({
        row: rowData[0],
        columns: matchColumns,
        baseGridColumns: option?.defaultColumns ?? [],
        option: dynamicDataOption,
      }),
    );

    const processedRowData = isTreeRowData
      ? rowData.map((row) => {
          const maxDepth = calculateRowDepth(row);

          processTreeNodes({
            row,
            maxDepth,
            option: {
              dynamicDataKey,
              handler: ({ row: currentRow }) => {
                applyUserTags({ row: currentRow, dynamicDataKey });
              },
            },
          });

          return row;
        })
      : rowData.map((row) => {
          applyUserTags({ row, dynamicDataKey });
          return row;
        });

    return {
      columns: matchColumns,
      rowData: isTreeRowData
        ? rowData
        : makeRows(processedRowData, matchColumns, columnFormatter, originTimeFields),
    };
  };

  return {
    applyChangeColumnInfo,
    makeColumnsAndRows,
  };
};
