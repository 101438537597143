<script setup lang="ts">
import { setup } from './loadingScreen.setup';

const { t, container, customOptions } = setup();
</script>

<template>
  <div class="loading-screen full-screen">
    <div
      v-if="customOptions.useLoadingAnimation"
      ref="container"
      class="lottie"
    />
    <div
      v-else
      class="loading-screen__base-bg"
    />
    <span class="loading-screen__description">
      {{ t('MESSAGE.LOADING_SCREEN', '요청 화면으로 이동 중입니다!') }}
    </span>
  </div>
</template>

<style scoped lang="scss">
.lottie {
  width: 690px;
  height: 480px;
}
.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #d9eaf9;
  &__base-bg {
    width: 400px;
    height: 264px;
    background: url('@/common/assets/loading-screen-figure.svg') no-repeat center 0 !important;
  }
  &__description {
    font-size: 24px;
    font-weight: 700;
    color: var(--color-blue-10);
  }
}
.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
