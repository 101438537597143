<script setup lang="ts">
import SubMenuElement from './SubMenuElement.vue';
import { type Props, type Emit, setup } from './menuElement.setup';

const props = defineProps<Props>();
const emit = defineEmits<Emit>();

const {
  innerRef,
  innerHeight,
  expandedMenu,
  computedDashboardMenu,
  computedAnalysisBoardMenu,
  onClickButton,
  onHoverButton,

  isMaxgaugeLastMenuElement,
} = setup(props, emit);
</script>

<template>
  <li
    :class="[
      'menu',
      'menu-element',
      `menu-element__${menuInfo.value.toLowerCase()}`,
      { selected },
      { 'menu-element__divider': isMaxgaugeLastMenuElement },
    ]"
  >
    <button
      type="button"
      :class="['menu__title', 'menu-element__title', `menu__${menuInfo.value.toLowerCase()}`]"
      :data-menu="menuInfo.value"
      @click="onClickButton"
      @mouseenter="onHoverButton"
    >
      <div class="menu-element__title-inner menu__title-inner">
        <div
          :class="['menu-element__item-icon', `menu__item-icon--${menuInfo.value.toLowerCase()}`]"
        />
        <h3>{{ menuInfo.text }}</h3>
        <div :class="['menu-element__arrow-icon', 'big', { up: selected, down: !selected }]" />
      </div>
    </button>
    <div :class="['folding-sub-menu', { opened: selected && expandedMenu.expanded }]">
      <ul
        ref="innerRef"
        class="folding-sub-menu__inner"
      >
        <!-- Dashboard -->
        <sub-menu-element
          v-if="menuInfo.dashboardList"
          :menu-info="computedDashboardMenu!"
          :selected="expandedMenu.subMenuValue === menuInfo.dashboardList.value"
        />

        <!-- Analysis Board -->
        <sub-menu-element
          v-if="
            menuInfo.analysisBoardList &&
            props.analysisBoardMenuList &&
            props.analysisBoardMenuList.length > 0
          "
          :menu-info="computedAnalysisBoardMenu!"
          :selected="expandedMenu.subMenuValue === menuInfo.analysisBoardList.value"
        />

        <!-- children -->
        <template v-for="(menu, menuIdx) in menuInfo?.children">
          <template v-if="!menu?.children?.length && menu.text">
            <sub-menu-element
              :key="`${menu.value}_${menuIdx}`"
              :menu-info="menu"
              :selected="expandedMenu.subMenuValue === menu.value"
            />
          </template>

          <template v-else-if="menu.children?.length">
            <li
              :key="`${menu.value}_${menuIdx}`"
              class="mid-menu"
            >
              <div class="mid-menu__title">
                <span v-show="menu.text">{{ menu.text }}</span>
              </div>
              <ul>
                <sub-menu-element
                  v-for="(middleMenuChild, middleMenuChildIndex) in menu.children"
                  :key="`${middleMenuChild.value}_${middleMenuChildIndex}`"
                  :menu-info="middleMenuChild"
                  :selected="expandedMenu.subMenuValue === middleMenuChild.value"
                />
              </ul>
            </li>
          </template>
        </template>
      </ul>
    </div>
  </li>
</template>

<style scoped lang="scss">
.menu {
  &__title {
    margin: 7px 0;
  }

  &__title-inner {
    padding-left: 0;
  }

  &__item-icon {
    background-size: 24px 24px !important;
  }

  &.selected {
    height: fit-content;

    .menu__title {
      background-color: var(--gnb-menu-selected-main-bg-color);
    }

    .menu__title i {
      transform: rotate(180deg);
    }
  }

  &__dashboard .menu__item-icon--dashboard {
    background: url('@/common/assets/icon-nav-dashboard.svg') no-repeat center center;
  }

  &__database .menu__item-icon--database {
    background: url('@/common/assets/icon-nav-database.svg') no-repeat center center;
  }

  &__application .menu__item-icon--application {
    background: url('@/common/assets/icon-nav-app.svg') no-repeat center center;
  }

  &__infrastructure .menu__item-icon--infrastructure {
    background: url('@/common/assets/icon-nav-infra.svg') no-repeat center center;
  }

  &__kubernetes .menu__item-icon--kubernetes {
    background: url('@/common/assets/icon-nav-kube.svg') no-repeat center center;
  }

  &__pa .menu__item-icon--pa {
    background: url('@/common/assets/icon-nav-pa.svg') no-repeat center center;
  }

  /* &__config .menu__item-icon--config {
    background: url('@/common/assets/icon-nav-config.svg') no-repeat center center;
  } */

  &__report .menu__item-icon--report {
    background: url('@/common/assets/icon-nav-report.svg') no-repeat center center;
  }

  &__alert .menu__item-icon--alert {
    background: url('@/common/assets/icon-nav-alert.svg') no-repeat center center;
  }

  &__business .menu__item-icon--business {
    background: url('@/common/assets/icon-nav-service.svg') no-repeat center center;
  }

  &__logs .menu__item-icon--logs {
    background: url('@/common/assets/icon-nav-logs.svg') no-repeat center center;
  }

  &__oracle .menu__item-icon--oracle {
    background: url('@/common/assets/icon-nav-oracle.svg') no-repeat center center;
  }

  &__postgresql .menu__item-icon--postgresql {
    background: url('@/common/assets/icon-nav-postgresql.svg') no-repeat center center;
  }

  &__mysql .menu__item-icon--mysql {
    background: url('@/common/assets/icon-nav-mysql.svg') no-repeat center center;
  }

  &__sqlserver .menu__item-icon--sqlserver {
    background: url('@/common/assets/icon-nav-sqlserver.svg') no-repeat center center;
  }

  &__redis .menu__item-icon--redis {
    background: url('@/common/assets/icon-nav-redis.svg') no-repeat center center;
  }

  &__mongodb .menu__item-icon--mongodb {
    background: url('@/common/assets/icon-nav-mongodb.svg') no-repeat center center;
  }

  &__cubrid .menu__item-icon--cubrid {
    background: url('@/common/assets/icon-nav-cubrid.svg') no-repeat center center;
  }

  &__tool .menu__item-icon--tool {
    background: url('@/common/assets/icon-nav-tool.svg') no-repeat center center;
  }

  .folding-sub-menu {
    max-height: 0;
    transition: max-height $animate-faster;

    &.opened {
      max-height: v-bind('innerHeight');
    }
  }

  &.menu-element__divider,
  .view-mode-maxgauge &.menu-element__database {
    position: relative;

    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--gnb-menu-divider-color);
      content: '';
    }

    .folding-sub-menu.opened {
      margin-bottom: 7px;
    }
  }
}
</style>
