/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  CreateKubernetesClusterRequest,
  KubernetesClusterListResponse,
  NamespaceListResponse,
  ResultResponse,
  UpdateKubernetesClusterRequest,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const removeClusterKubernetesClusterControllerAxios = ({
  clusterId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  clusterId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v6/metav6/kubernetes-clusters/{clusterId}'.replace(
    `{${'clusterId'}}`,
    encodeURIComponent(String(clusterId)),
  );
  const httpMethod = 'delete';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const removeV7ClusterKubernetesClusterControllerAxios = ({
  clusterId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  clusterId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v7/metav7/kubernetes-clusters/{clusterId}'.replace(
    `{${'clusterId'}}`,
    encodeURIComponent(String(clusterId)),
  );
  const httpMethod = 'delete';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getAllV7ClusterKubernetesClusterControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<KubernetesClusterListResponse> => {
  const localVarPath = '/api/v7/metav7/kubernetes-clusters';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getV7NamespacesKubernetesClusterControllerAxios = ({
  clusterId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  clusterId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<NamespaceListResponse> => {
  const localVarPath = '/api/v7/metav7/kubernetes-clusters/{clusterId}/namespaces'.replace(
    `{${'clusterId'}}`,
    encodeURIComponent(String(clusterId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const updateClusterKubernetesClusterControllerAxios = ({
  clusterId,
  request,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  clusterId: string;
  request: UpdateKubernetesClusterRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v6/metav6/kubernetes-clusters/{clusterId}'.replace(
    `{${'clusterId'}}`,
    encodeURIComponent(String(clusterId)),
  );
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const updateV7ClusterKubernetesClusterControllerAxios = ({
  clusterId,
  request,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  clusterId: string;
  request: UpdateKubernetesClusterRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v7/metav7/kubernetes-clusters/{clusterId}'.replace(
    `{${'clusterId'}}`,
    encodeURIComponent(String(clusterId)),
  );
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const addClusterKubernetesClusterControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  request: CreateKubernetesClusterRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v6/metav6/kubernetes-clusters';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const addV7ClusterKubernetesClusterControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  request: CreateKubernetesClusterRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v7/metav7/kubernetes-clusters';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  removeClusterKubernetesClusterControllerAxios,
  removeV7ClusterKubernetesClusterControllerAxios,
  getAllV7ClusterKubernetesClusterControllerAxios,
  getV7NamespacesKubernetesClusterControllerAxios,
  updateClusterKubernetesClusterControllerAxios,
  updateV7ClusterKubernetesClusterControllerAxios,
  addClusterKubernetesClusterControllerAxios,
  addV7ClusterKubernetesClusterControllerAxios,
};
