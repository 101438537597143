import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { DB_TYPE } from '@/common/utils/define';
import { getEnabledInstance, getRtmSelectedInfo } from '@/common/utils/commonUtils';
import { DEFAULT_FOLDER_INFO, FolderInfo } from '@/config/store/monitoringFolder';
import { SlowQueryOption } from '@/common/components/organisms/slowQuery/useSlowQuery';
import { mapMetricNamesToDataIds } from '@/database/utils/utils';
import { addXmUserEnvControllerAxios } from '@/openapi/metav8Over/api/xm-user-env-controller-api';

export interface RankMetricInfo {
  metricName: string;
  metricDisplayName: string;
  unit?: string;
}
interface State {
  selectedFolderId: string | null;
  selectedFolderInfo: FolderInfo;
  selectedInstanceIdBySelectBox: string;
  dbMetricNames: string[];

  rankMetricList: RankMetricInfo[];
  slowQueryOption: SlowQueryOption;

  // 실패한 API의 frame 정보 저장 (key: frameName, value: errorStatusText)
  framesByFailedApi: Map<string, string>;
}

const METRIC_CHART_METRIC_NAMES_KEY = 'MYSQL_METRIC_CHART_METRIC_NAMES';
const SELECTED_ID_KEY = 'MYSQL_MULTI_VIEW_SELECTED_ID';
const SLOW_QUERY_OPTION_KEY = 'MYSQL_MULTI_VIEW_SLOW_QUERY_OPTION';

const SLOW_QUERY_DEFAULT_OPTION: SlowQueryOption = {
  isSetMaxValue: true,
  pointSize: 2,
  maxValue: 60,
};
export const multiViewEnv: Module<State, RootState> = {
  namespaced: true,
  state: {
    selectedFolderId: null,
    selectedFolderInfo: structuredClone(DEFAULT_FOLDER_INFO),
    dbMetricNames: [],
    selectedInstanceIdBySelectBox: '',
    rankMetricList: [
      {
        metricName: 'db_mysql_sort_merge_passes',
        metricDisplayName: 'Sort Merge Passes',
        unit: 'Count',
      },
      {
        metricName: 'db_mysql_global_temp_usage',
        metricDisplayName: 'Global Temp Usage',
        unit: 'Size',
      },
      {
        metricName: 'db_mysql_session_temp_usage',
        metricDisplayName: 'Session Temp Usage',
        unit: 'Size',
      },
      {
        metricName: 'db_mysql_queries_delta',
        metricDisplayName: 'Queries[Delta]',
        unit: 'Count',
      },
      {
        metricName: 'db_mysql_usage',
        metricDisplayName: 'Redo Usage',
        unit: '%',
      },
    ],
    slowQueryOption: {} as SlowQueryOption,

    framesByFailedApi: new Map(),
  },
  mutations: {
    setSelectedFolderId(state: State, id: string) {
      state.selectedFolderId = id;
    },
    setSelectedFolderInfo(state: State, info: State['selectedFolderInfo']) {
      state.selectedFolderInfo = info;
    },
    setFramesByFailedApi: (state: State, failedApiInfo) => {
      state.framesByFailedApi.set(failedApiInfo.frameName, failedApiInfo.statusText);
    },
    deleteFramesByFailedApi: (state: State, frameNameOfFailedApi: string) => {
      if (state.framesByFailedApi.has(frameNameOfFailedApi)) {
        state.framesByFailedApi.delete(frameNameOfFailedApi);
      }
    },
    initFramesByFailedApi: (state: State) => {
      state.framesByFailedApi = new Map();
    },
    initSelectedInfo: (state: State) => {
      state.selectedFolderId = null;
      state.selectedFolderInfo = structuredClone(DEFAULT_FOLDER_INFO);
    },
    setDbMetricName: (state: State, { idx, metricName }: { idx: number; metricName: string }) => {
      state.dbMetricNames[idx] = metricName;
    },
    setDbMetricNames: (state: State, metricNames: string[]) => {
      state.dbMetricNames = metricNames;
    },
    setSelectedInstanceIdBySelectBox: (state: State, instanceId: string) => {
      state.selectedInstanceIdBySelectBox = instanceId;
    },
    setSlowQueryOption: (state: State, slowQueryOption: SlowQueryOption) => {
      state.slowQueryOption = slowQueryOption;
    },
  },
  actions: {
    getDbMetricNames: ({ commit, rootGetters }) => {
      const DEFAULT_NAMES = [
        'db_mysql_innodb_buffer_pool_reads',
        'db_mysql_innodb_buffer_pool_read_requests',
        'db_mysql_tps',
        'db_mysql_buffer_pool_hit_ratio(%)',
      ];
      const metricNamesInUserEnv = rootGetters['userEnv/getUserEnvMap'].get(
        METRIC_CHART_METRIC_NAMES_KEY,
      );

      if (metricNamesInUserEnv) {
        commit(
          'setDbMetricNames',
          mapMetricNamesToDataIds({
            dbType: DB_TYPE.MYSQL,
            metricNames: JSON.parse(metricNamesInUserEnv),
          }),
        );
        return;
      }

      commit('setDbMetricNames', DEFAULT_NAMES);
    },
    saveDbMetricNames: async ({ commit }, metricNames) => {
      await addXmUserEnvControllerAxios({
        request: [
          {
            key: METRIC_CHART_METRIC_NAMES_KEY,
            value: JSON.stringify(metricNames),
          },
        ],
      });
      commit(
        'userEnv/setUserEnvMap',
        { key: METRIC_CHART_METRIC_NAMES_KEY, value: JSON.stringify(metricNames) },
        { root: true },
      );
    },
    fetchLastSelectedId: async ({ commit, dispatch, rootGetters }) => {
      try {
        const data = rootGetters['userEnv/getUserEnvMap'].get(SELECTED_ID_KEY);
        const idInfo = JSON.parse(data ?? '{}');
        const folderList = rootGetters['monitoringFolder/getFolders'];
        const { folderId, selectedInfo } = getRtmSelectedInfo({
          idInfo,
          folderList,
          dbType: DB_TYPE.MYSQL,
          hasInstanceId: false,
        });

        // userEnv에서 조회한 folderId와 실제 화면에 보여져야 할 folderId가 다른 경우
        // e.g. userEnv에서는 A라는 폴더가 저장되어 있지만 A라는 폴더가 삭제된 경우
        if (idInfo.folderId !== folderId) {
          await dispatch('saveLastSelectedId', {
            folderId,
          });
        }

        commit('setSelectedFolderId', folderId);
        commit('setSelectedFolderInfo', selectedInfo);
      } catch (e: any) {
        console.log(e);
      }
    },
    saveLastSelectedId: async ({ commit, rootGetters }, selectedInfo: Record<string, string>) => {
      if (!selectedInfo.folderId) {
        return;
      }
      try {
        commit('setSelectedFolderId', selectedInfo.folderId);
        const selectedFolderInfo =
          rootGetters['monitoringFolder/getFolders'].find(
            (item) => item.folderId === selectedInfo.folderId,
          ) ?? structuredClone(DEFAULT_FOLDER_INFO);
        commit('setSelectedFolderInfo', selectedFolderInfo);

        await addXmUserEnvControllerAxios({
          request: [
            {
              key: SELECTED_ID_KEY,
              value: JSON.stringify(selectedInfo),
            },
          ],
        });
        commit(
          'userEnv/setUserEnvMap',
          { key: SELECTED_ID_KEY, value: JSON.stringify(selectedInfo) },
          { root: true },
        );
      } catch (e: any) {
        console.log(e);
      }
    },
    fetchSlowQueryOption: ({ commit, rootGetters }) => {
      const data = rootGetters['userEnv/getUserEnvMap'].get(SLOW_QUERY_OPTION_KEY);
      commit('setSlowQueryOption', data ? JSON.parse(data) : SLOW_QUERY_DEFAULT_OPTION);
    },
    saveSlowQueryOption: async ({ commit }, slowQueryOption: SlowQueryOption) => {
      try {
        commit('setSlowQueryOption', slowQueryOption);
        await addXmUserEnvControllerAxios({
          request: [
            {
              key: SLOW_QUERY_OPTION_KEY,
              value: JSON.stringify(slowQueryOption),
            },
          ],
        });
        commit(
          'userEnv/setUserEnvMap',
          { key: SLOW_QUERY_OPTION_KEY, value: JSON.stringify(slowQueryOption) },
          { root: true },
        );
      } catch (e: any) {
        console.log(e);
      }
    },
  },
  getters: {
    getSelectedFolderInfo: (state) => state.selectedFolderInfo,
    getInstanceIds: (state) =>
      state.selectedFolderInfo?.instances
        ?.filter(getEnabledInstance(DB_TYPE.MYSQL))
        .map((v) => v.instanceId)
        .slice(0, 15) ?? [],
    getInstances: (state) =>
      state.selectedFolderInfo?.instances?.filter(getEnabledInstance(DB_TYPE.MYSQL)).slice(0, 15) ??
      [],
    isExistFramesByFailedApi: (state: State): boolean => !!state.framesByFailedApi.size,
    getFramesByFailedApi: (state: State) => state.framesByFailedApi,

    getRankMetricList: (state) => state.rankMetricList,
    getRankMetricNames: (state) => state.rankMetricList.map((v) => v.metricName),
    getDbMetricNames: (state) => state.dbMetricNames,
    getDbMetricDisplayNames: (state, getters, rootState, rootGetters) => {
      const displayDbMetricNamesMap = rootGetters['dbMetric/getMetricDisplayNames'];
      const { dbMetricNames } = state;

      const init = [] as { metricName: string; displayName: string }[];
      return dbMetricNames.reduce(
        (acc, cur) => [
          ...acc,
          {
            metricName: cur,
            displayName: displayDbMetricNamesMap.get(cur),
          },
        ],
        init,
      );
    },
    getSelectedInstanceIdBySelectBox: (state) => state.selectedInstanceIdBySelectBox,
    getSlowQueryOption: (state: State) => state.slowQueryOption,
  },
};
