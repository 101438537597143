import { computed, ref } from 'vue';
import {
  getAllMenuIntegratedDashboardGnbV7ControllerAxios,
  patchAnalysisMenuIntegratedDashboardGnbV7ControllerAxios,
  patchDashboardMenuIntegratedDashboardGnbV7ControllerAxios,
  registerAnalysisMenuIntegratedDashboardGnbV7ControllerAxios,
  registerDashboardMenuIntegratedDashboardGnbV7ControllerAxios,
} from '@/openapi/metaV6/api/integrated-dashboard-gnb-v7-controller-api';
import {
  IntegrateDashboardAnalysisPatchRequest,
  IntegrateDashboardGnbPatchRequest,
} from '@/openapi/metaV6/model';
import { defineStore } from 'pinia';
import { cloneDeep } from 'lodash-es';
import { VIEW_MODE } from '@/common/define/common.define';
import { useViewModeStore } from './view-mode';

export interface PlatformDashboardValue {
  name: string;
  favoriteId?: number;
  iconId?: string;
}

export const PLATFORM_MENU = {
  INFRA: 'infrastructure',
  KUBE: 'k8s',
  APP: 'application',
  DB: 'database',
  BUSINESS: 'business',
  LOGS: 'logs',
  ALERT: 'alert',
} as const;

const MAXGAUGE_PLATFORM_MENU = {
  ORACLE: 'oracle',
  MYSQL: 'mysql',
  POSTGRESQL: 'postgresql',
  SQLSERVER: 'sqlserver',
  REDIS: 'redis',
  CUBRID: 'cubrid',
  MONGODB: 'mongodb',
} as const;

export const TOTAL_PLATFORM_MENU = {
  ...PLATFORM_MENU,
  ...MAXGAUGE_PLATFORM_MENU,
} as const;

export type PlatformMenuType = (typeof TOTAL_PLATFORM_MENU)[keyof typeof TOTAL_PLATFORM_MENU];
export type DashboardId = number;
export type PlatformDashboardList = {
  [K in PlatformMenuType]: Record<DashboardId, PlatformDashboardValue>; // number : dashboard id
};

export type AnalysisBoardId = number;
export type PlatformAnalysisBoardList = {
  [K in PlatformMenuType]: Record<AnalysisBoardId, PlatformDashboardValue>; // number : dashboard id
};

export const usePlatformDashboardStore = defineStore('platformDashboard', () => {
  const { viewMode } = useViewModeStore();

  const platformMenu = computed<PlatformMenuType>(() => {
    const menuMapping = {
      [VIEW_MODE.MAXGAUGE]: MAXGAUGE_PLATFORM_MENU,
      [VIEW_MODE.EXEMONE]: PLATFORM_MENU,
    };

    return menuMapping[viewMode] || PLATFORM_MENU;
  });

  const menuByPlatform = ref<PlatformDashboardList>(
    Object.values(platformMenu.value).reduce((acc, key) => {
      acc[key] = {};
      return acc;
    }, {} as PlatformDashboardList),
  );
  const menuByAnalysisPlatform = ref<PlatformAnalysisBoardList>(
    Object.values(platformMenu.value).reduce((acc, key) => {
      acc[key] = {};
      return acc;
    }, {} as PlatformAnalysisBoardList),
  );

  /**
   * 플랫폼의 의존하는 대쉬보드 리스트를 모두 조회한다.
   */
  const getDashboardByPlatformList = async () => {
    const {
      data: { data },
    } = await getAllMenuIntegratedDashboardGnbV7ControllerAxios({});

    const newMenuByPlatform: PlatformDashboardList = Object.values(platformMenu.value).reduce(
      (acc, key) => {
        acc[key] = {};
        return acc;
      },
      {} as PlatformDashboardList,
    );
    const newMenuByAnalysisPlatform: PlatformAnalysisBoardList = Object.values(
      platformMenu.value,
    ).reduce((acc, key) => {
      acc[key] = {};
      return acc;
    }, {} as PlatformAnalysisBoardList);

    Object.keys(newMenuByPlatform).forEach((platform) => {
      data?.forEach((item) => {
        if (item.menuPaths?.includes(platform)) {
          newMenuByPlatform[platform][item.dashboardId] = {
            name: item.dashboardName,
            favoriteId: item.favoriteId,
            iconId: item.iconId,
          } as PlatformDashboardValue;
        }
      });
    });
    menuByPlatform.value = newMenuByPlatform;

    Object.keys(newMenuByAnalysisPlatform).forEach((platform) => {
      data?.forEach((item) => {
        if (item.analysisMenuPaths?.includes(platform)) {
          newMenuByAnalysisPlatform[platform][item.dashboardId] = {
            name: item.dashboardName,
            favoriteId: item.favoriteId,
            iconId: item.iconId,
          } as PlatformDashboardValue;
        }
      });
    });
    menuByAnalysisPlatform.value = newMenuByAnalysisPlatform;
  };

  /**
   * 플랫폼에 대쉬보드 추가
   *
   * @param platform
   * @param dashboardId
   * @param dashboardName
   */
  const addDashboardByPlatform = async (
    platform: PlatformMenuType,
    dashboardId: number,
    dashboardName: string,
  ) => {
    const request: IntegrateDashboardGnbPatchRequest = {
      dashboardId,
      menuPaths: [platform],
    };
    const {
      data: { status },
    } = await registerDashboardMenuIntegratedDashboardGnbV7ControllerAxios({
      request,
    });

    if (status === 'success') {
      menuByPlatform.value![platform][dashboardId] = {
        name: dashboardName,
      };
      return true;
    }
    return false;
  };

  const removeDashboardByPlatForm = async (platform: PlatformMenuType, dashboardId: number) => {
    const request: IntegrateDashboardGnbPatchRequest = {
      dashboardId,
      menuPaths: [platform],
    };

    const {
      data: { status },
    } = await patchDashboardMenuIntegratedDashboardGnbV7ControllerAxios({
      request,
    });
    if (status === 'success') {
      delete menuByPlatform.value?.[platform][dashboardId];
      return true;
    }
    return false;
  };

  const removeDashboardListByPlatform = (dashboardIds: number[]) => {
    const newMenuByPlatform: PlatformDashboardList = cloneDeep(menuByPlatform.value);

    Object.values(platformMenu.value).forEach((platform: string) => {
      dashboardIds.forEach((dashboardId) => {
        if (newMenuByPlatform?.[platform]?.[dashboardId]) {
          delete newMenuByPlatform[platform][dashboardId];
        }
      });
    });

    menuByPlatform.value = newMenuByPlatform;
  };

  /**
   * 플랫폼에 분석보드 추가
   *
   * @param platform
   * @param dashboardId
   * @param dashboardName
   */
  const addAnalysisBoardByPlatform = async (
    platform: PlatformMenuType,
    dashboardId: number,
    dashboardName: string,
  ) => {
    const request: IntegrateDashboardAnalysisPatchRequest = {
      dashboardId,
      analysisMenuPaths: [platform],
    };
    const {
      data: { status },
    } = await registerAnalysisMenuIntegratedDashboardGnbV7ControllerAxios({
      request,
    });

    if (status === 'success') {
      menuByAnalysisPlatform.value![platform][dashboardId] = {
        name: dashboardName,
      };
      return true;
    }
    return false;
  };

  const removeAnalysisBoardByPlatForm = async (platform: PlatformMenuType, dashboardId: number) => {
    const request: IntegrateDashboardAnalysisPatchRequest = {
      dashboardId,
      analysisMenuPaths: [platform],
    };

    const {
      data: { status },
    } = await patchAnalysisMenuIntegratedDashboardGnbV7ControllerAxios({
      request,
    });
    if (status === 'success') {
      delete menuByAnalysisPlatform.value?.[platform][dashboardId];
      return true;
    }
    return false;
  };

  return {
    platformMenu,
    menuByPlatform,
    menuByAnalysisPlatform,
    getDashboardByPlatformList,
    addDashboardByPlatform,
    removeDashboardByPlatForm,
    removeDashboardListByPlatform,
    addAnalysisBoardByPlatform,
    removeAnalysisBoardByPlatForm,
  };
});
