export const setCursorAtEnd = (element: HTMLInputElement) => {
  const { length } = element.value;
  setTimeout(() => {
    element.setSelectionRange(length, length);
  }, 0);
};

const maxValue = {
  hour: 23,
  minute: 59,
  second: 59,
} as const;
export const getTimeString = (value: string, name: 'hour' | 'minute' | 'second') => {
  const numbers = parseInt(value, 10);

  if (Number.isNaN(numbers)) {
    return 'NaN';
  }

  if (numbers < 10 && numbers >= 0) {
    return `0${numbers}`;
  }
  return numbers > maxValue[name] ? maxValue[name].toString() : numbers.toString();
};

export const parseTime = (time: string) => {
  return time.split(':').map((v) => parseInt(v, 10));
};

export const compareTime = (time1: string, time2: string) => {
  const [HH1, mm1, ss1] = parseTime(time1);
  const [HH2, mm2, ss2] = parseTime(time2);

  if (HH1 !== HH2) {
    return HH1 - HH2;
  }
  if (mm1 !== mm2) {
    return mm1 - mm2;
  }
  return ss1 - ss2;
};
