import { RuleCriteria } from '@/alert/components/alertDetail/alertDetail.types';
import { CHECK_BY_COMBINATION } from '@/alert/utils/define';
import AlertTreeGrid from '@/alert/components/alertGrid/AlertTreeGrid.vue';
import AlertGrid from '@/alert/components/alertGrid/AlertGrid.vue';

export const getAlertGridComp = ({ ruleCriteria }: { ruleCriteria: RuleCriteria }) =>
  ruleCriteria === CHECK_BY_COMBINATION ? AlertTreeGrid : AlertGrid;

export const getRuleNameIcon = ({ ruleCriteria }: { ruleCriteria: RuleCriteria }) =>
  ruleCriteria === CHECK_BY_COMBINATION
    ? 'icon-alert-check-by-combination'
    : 'icon-alert-check-by-target';

export const checkModalLinked = (rowData: Record<string, any>): boolean => {
  const { eventType } = rowData ?? {};
  return ['Logging', 'Kubernetes'].includes(eventType ?? '');
};
