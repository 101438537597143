import { ComponentCustomProperties, nextTick, onMounted } from 'vue';
import { store } from '@/common/store';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import {
  DB_TYPE,
  DEFAULT_PRODUCT_THEME,
  ONE_DAY,
  ONE_HOUR,
  ONE_MINUTE,
  PA_TOGGLE_DATA_TYPE,
  PRODUCT_THEMES,
  TIMEZONE_ITEMS,
} from '@/common/utils/define';
import { InstanceInfoReq } from '@/common/api/reqTypes/instanceReq';
import { InstanceInfoRes } from '@/common/api/resTypes/instanceRes';
import { StatItem } from '@/openapi/data/model';
import { SqlLanguage, format } from 'sql-formatter';
import { i18n } from '@/common/locale';
import { CollectInfo, getIntervalMs } from '@/worker/utils';
import {
  DBType,
  FetchInfo,
  FormatSize,
  PAToggleDataType,
  TimezoneNameForApiParam,
} from '@/common/utils/types';
import localeEn from '@/common/locale/en.json';
import { v4 as uuid } from 'uuid';
import packageInfo from '../../../package.json';

export * from './string.utils';
export * from './time.utils';

dayjs.extend(duration);

const getVersion = (type: 'product' | 'front' = 'product') => {
  const [major, minor, patch] = packageInfo.version?.split('.');
  return type === 'product' ? `v${major}.${minor}` : `${major}.${minor}.${patch}`;
};

const onPreventClickBubbling = (callbkClickFunc: () => void): void => {
  callbkClickFunc();
};

const setShowSqlTextClsName = (cls: string): string =>
  `show-api-trace-sql-text__${cls.split(' ').join('---')}`;

const truthyNumber = (v) => typeof v === 'number' && !Number.isNaN(v);

const truthy = (...args) => args.every(truthyNumber);

const convertCamelToCapital = (str: string): string => {
  if (!str) {
    return '';
  }
  const result = str.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

const convertCamelToKebab = (str: string): string =>
  str
    .split('')
    .map((letter, idx) =>
      letter.toUpperCase() === letter ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}` : letter,
    )
    .join('');

const numberWithComma = (v) => {
  const reg = /\B(?=(\d{3})+(?!\d))/g;

  if (truthy(v) || truthy(Number(v))) {
    if (Number.isInteger(v)) {
      return v.toString().replace(reg, ',');
    }
    if (!v) {
      return '';
    }

    const part = v.toString().split('.');
    return part[0].replace(reg, ',') + (part[1] ? `.${part[1]}` : '');
  }

  return false;
};

const formatNumberToLocale = (v: number, locale?: string) => {
  if (truthyNumber(v)) {
    return v.toLocaleString(locale);
  }
  return v;
};

/**
 * if decimalPlaces is 3
 * null -> null
 * undefined -> null
 * 'string' -> null
 * 1 -> 1
 * 1000 -> '1,000'
 * 1.12 -> 1.12
 * 1000.1234 -> 1,000.123
 * 1000.1236 -> 1,000.124
 * @param num
 * @param decimalPlaces
 */
const getRoundedNumberOrNull = (num: any, decimalPlaces: number): number | null => {
  if (truthyNumber(num)) {
    return parseFloat(num.toFixed(decimalPlaces));
  }

  return null;
};

/**
 * if decimalPlaces is 3
 * null -> ''
 * undefined -> ''
 * 'string' -> ''
 * 1 -> '1'
 * 1000 -> '1,000'
 * 1.1 -> '1.1'
 * 1000.1234 -> '1,000.123'
 * 1000.1236 -> '1,000.124'
 * @param num
 * @param decimalPlaces
 */
const getRoundedNumberWithComma = (num: any, decimalPlaces: number): string => {
  if (!truthyNumber(num)) {
    return '';
  }

  if (Number.isInteger(num)) {
    return numberWithComma(num);
  }

  return numberWithComma(parseFloat(num.toFixed(decimalPlaces)));
};

const confirmMsg = (
  ctx: ComponentCustomProperties & Record<string, any>,
  {
    msgStr,
    okCallback,
    cancelCallback,
    title,
    useHTML,
    confirmBtnText = i18n.global.t('WORD.OK'),
    showClose = false,
    showConfirmBtn = true,
    showCancelBtn = true,
  }: {
    msgStr: string;
    okCallback: () => void;
    cancelCallback?: () => void;
    title?: string;
    useHTML?: boolean;
    showClose?: boolean;
    confirmBtnText?: string;
    showConfirmBtn?: boolean;
    showCancelBtn?: boolean;
  },
): void => {
  ctx.$messagebox({
    message: msgStr,
    title,
    useHTML,
    closeOnClickModal: false,
    showClose,
    showConfirmBtn,
    showCancelBtn,
    focusable: true,
    onClose: (type) => {
      if (type === 'ok') {
        okCallback?.();
      } else if (typeof cancelCallback === 'function') {
        cancelCallback?.();
      }
    },
    confirmBtnText,
    cancelBtnText: i18n.global.t('WORD.CANCEL'),
  });
};

const promiseConfirmMsg = (...args: Parameters<typeof confirmMsg>): Promise<boolean> => {
  const [ctx, options] = args;
  return new Promise((resolve) => {
    confirmMsg(ctx, {
      ...options,
      okCallback: () => {
        options.okCallback?.();
        resolve(true);
      },
      cancelCallback: () => {
        options.cancelCallback?.();
        resolve(false);
      },
    });
  });
};

const showSuccessMsg = (
  ctx: ComponentCustomProperties & Record<string, any>,
  msgStr: string,
  durationMs = 3000,
  showClose = false,
  useHTML = false,
  icon = false,
): void => {
  ctx.$message({
    message: msgStr,
    type: 'success',
    duration: durationMs,
    showClose,
    useHTML,
    iconClass: icon ? 'icon-fill-check-circle' : '',
  });
};

const showErrorMsg = (
  ctx: ComponentCustomProperties & Record<string, any>,
  msgStr: string,
  durationMs = 3000,
  showClose = false,
  useHTML = false,
  icon = false,
): void => {
  ctx.$message({
    message: msgStr,
    type: 'error',
    duration: durationMs,
    showClose,
    useHTML,
    iconClass: icon ? 'icon-fill-error-circle' : '',
  });
};

const showWarningMsg = (
  ctx: ComponentCustomProperties & Record<string, any>,
  msgStr: string,
  durationMs = 3000,
  showClose = false,
  useHTML = false,
  icon = false,
): void => {
  ctx.$message({
    message: msgStr,
    type: 'warning',
    duration: durationMs,
    showClose,
    useHTML,
    iconClass: icon ? 'icon-fill-warning' : '',
  });
};

const showLoadingMsg = ({
  ctx,
  msgStr,
  showClose = true,
  icon = true,
  onLoadingMsgClosed = () => {},
}: {
  ctx: ComponentCustomProperties & Record<string, any>;
  msgStr: string;
  showClose?: boolean;
  icon?: boolean;
  onLoadingMsgClosed?: () => void;
}): Record<string, () => void> => {
  return ctx.$message({
    message: msgStr,
    type: 'info',
    useHTML: true,
    duration: 1000000,
    showClose,
    iconClass: icon ? 'icon-refresh' : '',
    onClose() {
      onLoadingMsgClosed();
    },
  });
};

const getErrorMsgOfPromiseReject = (ctx, errRes) => {
  const errorType = errRes?.data?.errorType || errRes?.data?.type || 'Exception';
  return i18n.global.t(`ERROR.API.${errorType}`);
};

const showErrorMsgOfPromiseReject = (ctx, errRes) => {
  const errorType = errRes?.data?.errorType || errRes?.data?.type || 'Exception';
  showErrorMsg(ctx, i18n.global.t(`ERROR.API.${errorType}`));
};

const showInfoMsg = (
  ctx: ComponentCustomProperties & Record<string, any>,
  msgStr: string,
  durationMs = 3000,
  showClose = false,
  icon = false,
): void => {
  ctx.$message({
    message: msgStr,
    type: 'info',
    duration: durationMs,
    showClose,
    iconClass: icon ? 'icon-info-circle' : '',
  });
};

const setScrollTopForFullWindow = () => {
  const windowEl = document.getElementsByClassName('window--type-full');
  const targetEl = windowEl[0]?.getElementsByClassName('ev-window-content');
  if (targetEl && targetEl[0]) {
    targetEl[0].scrollTop = 0;
  }
};

const changeDurationMsToDateTimeFormat = (ms: number | null) => {
  if (ms === null) {
    return '';
  }
  return dayjs.duration(ms).format('YYYY:MM:DD HH:mm:ss');
};

// utcOffset 값을 가져온다
const getUtcOffset = (): number => {
  const { timezone } = store.getters['myInfo/getAccountInfo'];

  return TIMEZONE_ITEMS.find((v) => v.value === timezone)?.utcOffsetMin || 0;
};

// 표준 시 -> UTC 0로 변환
const standardTimeToUtcZeroTime = (
  standardTime?: number | string,
  returnFormat = 'YYYY-MM-DD HH:mm:ss',
): string => {
  if (!standardTime) {
    return standardTime as any;
  }

  const { timezone } = store.getters['myInfo/getAccountInfo'];

  return dayjs(standardTime).tz(timezone, true).utc().format(returnFormat);
};

// UTC 0 -> 표준 시로 변환
const utcZeroTimeToStandardTime = (
  utcZeroTime: undefined | null | string,
  returnFormat = 'YYYY-MM-DD HH:mm:ss',
): string => {
  let utcZeroTimeStr = '';
  if (utcZeroTime) {
    const UTC_0_TIME_STR_LENGTH = utcZeroTime.length;
    if (UTC_0_TIME_STR_LENGTH - 1 === utcZeroTime.lastIndexOf('Z')) {
      utcZeroTimeStr = utcZeroTime.slice(0, UTC_0_TIME_STR_LENGTH - 1);
    } else if (UTC_0_TIME_STR_LENGTH - 4 === utcZeroTime.lastIndexOf(' GMT')) {
      utcZeroTimeStr = utcZeroTime.slice(0, UTC_0_TIME_STR_LENGTH - 4);
    } else {
      utcZeroTimeStr = utcZeroTime;
    }
    const utcOffsetMin = getUtcOffset();
    return dayjs(utcZeroTimeStr).add(utcOffsetMin, 'm').format(returnFormat);
  }
  return utcZeroTimeStr;
};

// 특정 Time에서 +- rangeMs의 fromTime toTime 객체로 변환 (300,000 ms = 5 min)
const convertTimeToRangeTime = (
  collectTime: string,
  rangeMs = 300000,
): {
  fromTime: string;
  toTime: string;
} => {
  if (collectTime) {
    const collectTimeUnixTimestamp = +dayjs(collectTime);
    return {
      fromTime: dayjs(collectTimeUnixTimestamp - rangeMs).format('YYYY-MM-DD HH:mm:ss'),
      toTime: dayjs(collectTimeUnixTimestamp + rangeMs).format('YYYY-MM-DD HH:mm:ss'),
    };
  }
  return {
    fromTime: '',
    toTime: '',
  };
};

// 소수점 digit 자리까지 표기 된 숫자를 리턴한다. default: 3
const roundToDigitNumber = (num: number, digit = 3) => {
  if (num || num === 0) {
    return Math.floor(num * 10 ** digit) / 10 ** digit;
  }
  return num;
};

// 소수점 digit 자리 까지 표기 및 버림, comma 포함 default: 2
const roundToDigit = (num: number | bigint, digit = 2) => {
  if (num > Number.MAX_SAFE_INTEGER || typeof num === 'bigint') {
    return num.toLocaleString();
  }
  let convertNum = num;
  if (`${convertNum}`.includes('e')) {
    convertNum = roundToDigitNumber(num, digit + 2);
  } else if (`${convertNum}`.includes('.')) {
    const arr = `${convertNum}`.split('.');
    convertNum = +`${arr[0]}.${arr[1].substring(0, digit + 2)}`;
  }
  if (convertNum || convertNum === 0) {
    // undefined -> host default language
    return (+`${Math.floor(+`${convertNum}e+${digit}`)}e-${digit}`).toLocaleString(undefined, {
      maximumFractionDigits: digit,
    });
  }
  return convertNum;
};

// 소수점 2자리까지 표기 및 반올림, comma 포함, unit 단위 추가해서 리턴한다.
const convertUnitValue = (val: number | null, unit?: string): string => {
  if (typeof val === 'number' && !Number.isNaN(val)) {
    const valWithDigit = roundToDigitNumber(val, 2);
    const valWithDigitComma = numberWithComma(valWithDigit);
    return unit ? valWithDigitComma + unit : valWithDigitComma;
  }
  return '';
};

const getToggleDigit = (toggle: PAToggleDataType) => (toggle === PA_TOGGLE_DATA_TYPE.SUM ? 1 : 3);

const convertBytesToUnits = (bytes: number, digits = 0) => {
  if ((!!bytes || bytes === 0) && Number.MAX_SAFE_INTEGER > bytes) {
    const unitList = [
      'Byte',
      'KB', // Kilo Byte
      'MB', // Mega Byte
      'GB', // Giga Byte
      'TB', // Tera Byte
      'PB', // Peta Byte
    ];
    const maxUnitIdx = unitList.length - 1;
    const ONE_UNIT = 1024;
    let unitIdx = 0;
    let result;
    const dfs = (currUnitIdx: number, size: number) => {
      if (currUnitIdx > maxUnitIdx) {
        console.log('너무 큰 용량입니다.');
        return;
      }
      if (size < ONE_UNIT) {
        result = size;
        unitIdx = currUnitIdx;
      } else {
        dfs(currUnitIdx + 1, size / ONE_UNIT);
      }
    };
    dfs(unitIdx, bytes);
    return `${roundToDigit(result, digits)} ${unitList[unitIdx]}`;
  }
  return '';
};

const convertMbToGb = (data: number, digits = 3): number => roundToDigitNumber(data / 1024, digits);

const convertNumberToUnits = (() => {
  const numberFormatters = new Map();

  return (number, digits = 0) => {
    if (number === null || number === undefined) {
      return number;
    }
    let numberFormatter = numberFormatters.get(digits);
    if (!numberFormatter) {
      numberFormatter = new Intl.NumberFormat('en', {
        notation: 'compact',
        compactDisplay: 'short',
        maximumFractionDigits: digits,
        minimumFractionDigits: 0,
      });
      numberFormatters.set(digits, numberFormatter);
    }
    return numberFormatter.format(number);
  };
})();

const convertTimeToUnits = (millisecond: number, digits = 1) => {
  if ((!!millisecond || millisecond === 0) && Number.MAX_SAFE_INTEGER > millisecond) {
    const unitList = [
      'ms',
      'sec', // 1000ms = 1sec
      'min', // 60sec = 1min
    ];
    const maxUnitIdx = unitList.length - 1;
    const MS_UNIT = 1000;
    const TIME_UNIT = 60;
    let unitIdx = 0;
    let result;
    const dfs = (currUnitIdx: number, ms: number) => {
      if (currUnitIdx > maxUnitIdx) {
        console.log('너무 큰 숫자입니다.');
        return;
      }
      if (ms < TIME_UNIT && currUnitIdx > 0) {
        result = ms;
        unitIdx = currUnitIdx;
      } else if (ms >= MS_UNIT && currUnitIdx === 0) {
        dfs(currUnitIdx + 1, ms / MS_UNIT);
      } else {
        dfs(currUnitIdx + 1, ms / TIME_UNIT);
      }
    };
    dfs(unitIdx, millisecond);
    if (!unitIdx) {
      return `${result} ms`;
    }
    return `${roundToDigit(result, digits)} ${unitList[unitIdx]}`;
  }
  return '';
};

const convertMsToSec = (
  millisecond: number | string,
  options: { digits?: number; unit?: boolean } = { digits: 3, unit: false },
) => {
  if (!millisecond && millisecond !== 0) {
    return '';
  }
  const { digits = 3, unit = false } = options; // default 값 지정
  const convertedValue = roundToDigit(+millisecond / 1000, digits);
  const unitValue = unit ? ' sec' : '';
  return convertedValue ? `${convertedValue}${unitValue}` : (millisecond as string);
};

// b/s ~ Tb/s 까지 Unit 체크 함수
const convertBpsToUnit = (inputNumber: number): string => {
  if (!inputNumber) return '';

  let value = inputNumber;
  const units = ['b/s', 'Kb/s', 'Mb/s', 'Gb/s', 'Tb/s'];
  let perCheck = 0;

  while (value >= 1000 && perCheck < units.length - 1) {
    value /= 1000;
    perCheck++;
  }

  return `${value.toFixed(2)} ${units[perCheck]}`;
};

// ms(최소) ~ 사용자 지정(최대)로 시간 단위 변환
const convertTimeToUnitsWithMaxUnit = (
  millisecond: number,
  digits = 1,
  maxUnit: 'ms' | 'sec' | 'min' = 'min',
) => {
  if ((!!millisecond || millisecond === 0) && Number.MAX_SAFE_INTEGER > millisecond) {
    const unitList = [
      'ms',
      'sec', // 1000ms = 1sec
      'min', // 60sec = 1min
    ];

    const maxUnitIdx = unitList.indexOf(maxUnit);

    const MS_UNIT = 1000;
    const TIME_UNIT = 60;

    let unitIdx = 0;
    let result = millisecond;

    const dfs = (currUnitIdx: number, ms: number) => {
      if (currUnitIdx > maxUnitIdx) {
        return;
      }

      result = ms;
      unitIdx = currUnitIdx;

      if (currUnitIdx === 0) {
        if (ms >= MS_UNIT) {
          dfs(currUnitIdx + 1, ms / MS_UNIT);
        }
      } else if (ms >= TIME_UNIT) {
        dfs(currUnitIdx + 1, ms / TIME_UNIT);
      }
    };
    dfs(unitIdx, millisecond);
    return {
      convertedValue: roundToDigit(result, digits),
      unit: unitList[unitIdx],
    };
  }
  return {
    convertedValue: '',
    unit: '',
  };
};

const convertMsToDynamicTime = (ms: number, digits = 1, unitList = ['ms', 's', 'm', 'h', 'd']) => {
  if ((!!ms || ms === 0) && Number.MAX_SAFE_INTEGER > ms) {
    const MS_TO_1_SEC = 1000;
    const SEC_TO_1_MIN = 60;
    const MIN_TO_1_HOUR = 60;
    const HOUR_TO_1_DAY = 24;
    if (ms < MS_TO_1_SEC) {
      return `${roundToDigit(ms, digits)} ${unitList[0]}`;
    }
    if (ms < MS_TO_1_SEC * SEC_TO_1_MIN) {
      return `${roundToDigit(ms / MS_TO_1_SEC, digits)} ${unitList[1]}`;
    }
    if (ms < MS_TO_1_SEC * SEC_TO_1_MIN * MIN_TO_1_HOUR) {
      return `${roundToDigit(ms / (MS_TO_1_SEC * SEC_TO_1_MIN), digits)} ${unitList[2]}`;
    }
    if (ms < MS_TO_1_SEC * SEC_TO_1_MIN * MIN_TO_1_HOUR * HOUR_TO_1_DAY) {
      return `${roundToDigit(ms / (MS_TO_1_SEC * SEC_TO_1_MIN * MIN_TO_1_HOUR), digits)} ${
        unitList[3]
      }`;
    }
    return `${roundToDigit(
      ms / (MS_TO_1_SEC * SEC_TO_1_MIN * MIN_TO_1_HOUR * HOUR_TO_1_DAY),
      digits,
    )} ${unitList[4]}`;
  }
  return '';
};

const checkValid = (regExp: RegExp, data: any) => regExp.test(data);

const formatNumWithCommasAndDecimal = (num: number, digits = 0) => {
  if (num || num === 0) {
    return num.toLocaleString(undefined, {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    });
  }
  return num;
};

const formatNumWithCommasAndDecimalStr = (num: number, digits = 0, notNumText = ''): string => {
  const result = formatNumWithCommasAndDecimal(num, digits);
  if (typeof result === 'string') {
    return result;
  }

  return notNumText;
};

const replaceInstanceObjectType = (type: string): string => (type === 'i' ? 'Index' : 'Table');

const replaceInstanceStateClass = (state: string, classPrefix = ''): string => {
  if (['inactived', 'actived'].includes(state)) {
    return classPrefix + state;
  }
  return `${classPrefix}pending`;
};

const trimSqlText = (sqlTextData: string) => {
  if (!sqlTextData) return '';
  return sqlTextData
    .replace(/(--).*/g, '') // -- 주석 제거
    .replace(/\/\*[^+].*?\*\//gs, '') // /* */ 주석 제거
    .replace(/\n/g, ' ') // 줄바꿈 제거
    .replace(/\t/g, ' ') // 탭 제거
    .replace(/ +/g, ' '); // 공백 여러개 제거
};

// mysql 5.7 보다 상위 버전인지 확인
const isOverMysql5dot7Version = (dbVersion: string | null): boolean =>
  Number((dbVersion ?? '').split('.', 2).join('.')) > 5.7;

// mysql.TempExecutorQueryOver8_0 (MySQL 8.0.13 이상)
// mysql.TempExecutorQueryUnder5_7 (MySQL 5.7 ~ 8.0.12 까지)
// mysql 8.0.12 보다 상위 버전인지 확인
const isOverMysql812Version = (dbVersion: string | null): boolean => {
  const dbVersionDotArr = (dbVersion ?? '').split('.');
  if (dbVersionDotArr.length < 2) {
    return +dbVersionDotArr[0] >= 8;
  }
  // 버전이 Major.Minor
  if (dbVersionDotArr.length === 2) {
    return Number((dbVersion ?? '').split('.', 2).join('.')) > 8.0;
  }
  // 버전이 Major.Minor.Path
  if (Number((dbVersion ?? '').split('.', 2).join('.')) > 8.0) {
    // 8.0 이상, 8.1 버전 이후
    return true;
  }
  if (Number((dbVersion ?? '').split('.', 2).join('.')) === 8) {
    // 8.0.x 버전인 경우
    const patchVersion = Number((dbVersion ?? '').split('.')?.[2] ?? 0);
    // 8.0.12 버전까지 TempExecutorQuery
    return patchVersion > 12;
  }
  return false;
};
// mysql 8.0.28 보다 상위 버전인지 확인
const isOverMysql828Version = (dbVersion: string | null): boolean => {
  if (!dbVersion) return false;

  const versionParts = dbVersion.split('.');
  const major = parseInt(versionParts[0], 10) || 0;
  const minor = parseInt(versionParts[1], 10) || 0;
  const patch = parseInt(versionParts[2], 10) || 0;

  // 버전이 8.0 초과인지 체크
  if (major > 8 || (major === 8 && minor > 0)) {
    return true;
  }

  // 8.0.x 버전일 경우 패치 버전 체크
  return major === 8 && minor === 0 && patch >= 28;
};

// 정규 표현식을 사용하여 텍스트 중에 숫자와 마침표를 추출
const extractVersionNumbers = (text: string) => {
  const matches = text.match(/\d+(\.\d+)*/g);
  return matches ? matches.join('.') : '';
};

// baseVersion(기준 버전)과 비교(compareVersion)하는 함수
const compareMysqlVersion = ({
  baseVersion,
  compareVersion,
  operator = '>=',
}: {
  baseVersion: string | null; // 기준 버전
  compareVersion: string | null; // 비교할 버전
  operator: '>='; // 비교 연산자
}): boolean | null => {
  if (!baseVersion || !compareVersion) {
    return null;
  }

  const onlyNumberBaseVersion = extractVersionNumbers(baseVersion);
  const onlyNumberCompareVersion = extractVersionNumbers(compareVersion);

  let baseDotArr = (onlyNumberBaseVersion ?? '').split('.').map((v) => {
    const vInt = parseInt(v, 10);
    return Number.isNaN(vInt) ? 0 : vInt;
  });
  let compareDotArr = (onlyNumberCompareVersion ?? '').split('.').map((v) => {
    const vInt = parseInt(v, 10);
    return Number.isNaN(vInt) ? 0 : vInt;
  });

  const maxDotArrLen = Math.max(baseDotArr.length, compareDotArr.length);

  if (baseDotArr.length < maxDotArrLen) {
    baseDotArr = [...baseDotArr, ...Array(maxDotArrLen - baseDotArr.length).fill(0)];
  }

  if (compareDotArr.length < 3) {
    compareDotArr = [...compareDotArr, ...Array(maxDotArrLen - compareDotArr.length).fill(0)];
  }

  for (let i = 0; i < maxDotArrLen; i++) {
    switch (operator) {
      case '>=':
        return compareDotArr[i] >= baseDotArr[i];
      default:
        return null;
    }
  }

  return false;
};

const getGridColumnFieldSuffixByToggle = (type: string) => (type === 'sum' ? '' : 'Avg');

export const getDbTypeByTypeName = (typeName: string): DBType => {
  switch (typeName) {
    case 'mysql':
      return DB_TYPE.MYSQL;
    case 'oracle':
      return DB_TYPE.ORACLE;
    case 'sqlserver':
      return DB_TYPE.SQLSERVER;
    case 'postgresql':
      return DB_TYPE.POSTGRESQL;
    case 'mongodb':
      return DB_TYPE.MONGODB;
    case 'redis':
      return DB_TYPE.REDIS;
    default:
      return null;
  }
};

const getSqlLanguageByDbType = (dbType: DBType): SqlLanguage => {
  // formatSqlText 함수 안의 로직 분리 했습니다.
  switch (dbType) {
    case DB_TYPE.POSTGRESQL:
      return 'postgresql';
    case DB_TYPE.MYSQL:
      return 'mysql';
    case DB_TYPE.ORACLE:
    case DB_TYPE.SQLSERVER:
      return 'tsql';
    default:
      return 'sql';
  }
};

const formatSqlText = (sqlText: string | null, dbTypeStr: string) => {
  if (!sqlText) {
    return '';
  }

  const dbType = getDbTypeByTypeName(dbTypeStr);
  const language = getSqlLanguageByDbType(dbType);

  let formattingSql = '';
  try {
    formattingSql = format(`${sqlText}`, {
      language,
      tabWidth: 2,
      keywordCase: 'upper',
      linesBetweenQueries: 2,
    });
  } catch (e) {
    formattingSql = '';
    console.error(e);
  }

  return formattingSql;
};

interface StoredData {
  folderId: string | null;
  instanceId: string | null;
  instanceName: string;
  instanceType?: string;
  objectName?: string;
  fromTime?: string;
  toTime?: string;
  fromUtc0Time?: string;
  toUtc0Time?: string;
  top?: number;
}

const setStoredData = (dbType: string) => (data: StoredData) => {
  const storeData = [
    'folderId',
    'instanceId',
    'instanceName',
    'instanceType',
    'objectName',
    'fromTime',
    'toTime',
    'fromUtc0Time',
    'toUtc0Time',
  ];

  Object.keys(data).forEach((key) => {
    if (storeData.includes(key)) {
      const target = key.replace(/^[a-z]/, (char) => char.toUpperCase());
      store.commit(`${dbType}PaCondition/setSelected${target}`, data[key]);
    }
  });
};

const setMysqlSetData = setStoredData('mysql');
const setOracleSetData = setStoredData('oracle');
const setSqlServerSetData = setStoredData('sqlserver');
const setPostgresqlSetData = setStoredData('postgresql');

const makeMetricDisplayNames = (metricNames: StatItem[]) => {
  const metricDisplayNamesMap = new Map();
  metricNames.forEach((info: StatItem) => {
    const { displayName } = info;
    const metricUnit = info.unit ? ` (${info.unit})` : '';
    metricDisplayNamesMap.set(info.dataId, displayName + metricUnit);
  });
  return metricDisplayNamesMap;
};

const getRtmSelectedInfo = ({
  idInfo: { folderId, instanceId },
  folderList,
  dbType,
  hasInstanceId = true, // singleView: true, multiView: false
}) => {
  const selectedInfo = {
    folderId: '',
    instanceId: '',
    selectedInfo: {},
  };

  if (!folderList?.length) {
    return selectedInfo;
  }

  const filteredFolderList = folderList.filter(
    (item) =>
      !!item.instances?.length &&
      item.instances.some((instance) => instance.type === dbType && instance.enabled),
  );
  if (!filteredFolderList.length) {
    return selectedInfo;
  }

  // Folder ID 검증: folderId 없거나 유효하지 않을 경우, 첫번째 폴더의 첫번째 인스턴스 선택
  const isValidFolderId = filteredFolderList.some((v) => v.folderId === folderId);
  if (!folderId || !isValidFolderId) {
    const firstFolderInfo = filteredFolderList[0];
    const firstInstanceInfo = firstFolderInfo.instances.filter(
      (item) => item.type === dbType && item.enabled,
    )[0];
    return {
      folderId: firstFolderInfo.folderId,
      instanceId: firstInstanceInfo.instanceId,
      selectedInfo: hasInstanceId ? firstInstanceInfo : firstFolderInfo,
    };
  }

  // 1. multiView일 경우
  if (!hasInstanceId) {
    const folderInfo = filteredFolderList.find((v) => v.folderId === folderId);
    return {
      folderId: folderInfo.folderId,
      instanceId: '',
      selectedInfo: folderInfo,
    };
  }

  // Instance ID 검증: instanceId 없거나 유효하지 않을 경우, 선택된 폴더의 첫번째 인스턴스 선택
  // 2. singleView일 경우
  const folderInfo = filteredFolderList.find((v) => v.folderId === folderId);
  const isValidInstanceId = folderInfo.instances?.some(
    (v) => v.instanceId === instanceId && v.enabled,
  );
  if (!instanceId || !isValidInstanceId) {
    const firstInstanceInfo = folderInfo.instances.filter(
      (item) => item.type === dbType && item.enabled,
    )[0];
    return {
      folderId: folderInfo.folderId,
      instanceId: firstInstanceInfo.instanceId,
      selectedInfo: firstInstanceInfo,
    };
  }

  // folderId, instanceId 모두 유효할 경우, 선택 정보 반환
  const instanceInfo = folderInfo.instances.find((v) => v.instanceId === instanceId && v.enabled);
  return {
    folderId: folderInfo.folderId,
    instanceId: instanceInfo.instanceId,
    selectedInfo: instanceInfo,
  };
};

const getInstanceByDbType =
  (dbType: string) =>
  (instance: InstanceInfoReq | InstanceInfoRes): boolean => {
    return instance.type === dbType;
  };

const getEnabledInstance =
  (dbType: string) =>
  (instance: InstanceInfoReq | InstanceInfoRes): boolean => {
    if (dbType) {
      return instance.type === dbType && instance.enabled;
    }
    return instance.enabled;
  };

/**
 * API 요청 할 때 필요한 인자들 반환
 * @param { Object } obj
 * @param { string[] } key - API 요청 인자의 key 값 들
 * @returns { Object } - obj 에서 해당 key 값 가져온 후 { key: value }반환
 * */
const getRequireParam = <T, K extends keyof T>(obj: T, key: K[]) =>
  key.reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: obj[cur],
    }),
    {},
  );

// 배열의 값 중 가장 높은 값의(높은 값 중복 시 lastIdx로 추출) 인덱스 추출
const getMaxValLastIdx = (arr: (number | null)[]): number => {
  let startIdx = 0;
  let endIdx = arr.length - 1;

  // 투 포인터 양방향 탐색
  const curStartPoint = {
    val: arr[startIdx],
    idx: startIdx,
  };
  const curEndPoint = {
    val: arr[endIdx],
    idx: endIdx,
  };

  while (startIdx <= endIdx) {
    const startVal = arr[startIdx];
    const endVal = arr[endIdx];

    if (endVal === null) {
      endIdx--;
    } else if (curEndPoint.val === null && typeof endVal === 'number') {
      curEndPoint.val = endVal;
      curEndPoint.idx = endIdx;
    }

    if (startVal === null) {
      startIdx++;
    } else if (curStartPoint.val === null && typeof startVal === 'number') {
      curStartPoint.val = startVal;
      curStartPoint.idx = startIdx;
    }

    if (
      startVal !== null &&
      endVal !== null &&
      typeof curStartPoint.val === 'number' &&
      typeof curEndPoint.val === 'number'
    ) {
      if (curStartPoint.val <= startVal) {
        curStartPoint.val = startVal;
        curStartPoint.idx = startIdx;
      }

      if (curEndPoint.val < endVal) {
        curEndPoint.val = endVal;
        curEndPoint.idx = endIdx;
      }

      startIdx++;
      endIdx--;
    }
  }

  return (curEndPoint.val ?? 0) >= (curStartPoint.val ?? 0) ? curEndPoint.idx : curStartPoint.idx;
};

type Cb = (comparison: number) => number;
interface Fn {
  (max: number, comparison: number): number;
  (max: number): Cb;
  (max: number, comparison?: number): number | Cb;
}
const getRatio = function (max: number, comparison?: number): number | Cb {
  if (comparison === undefined) {
    return (n) => getRatio(max, n);
  }
  if (comparison === 0 || max === 0) {
    return 0;
  }
  return comparison / max; // 소수점 처리
} as Fn;

const getTimezoneItemsByLocale = () =>
  TIMEZONE_ITEMS.map((timezoneItem) => {
    const key = timezoneItem.utcTimeOffset
      .replace('+', 'PLUS_')
      .replace('-', 'MINUS_')
      .replace(':', '_');

    return {
      ...timezoneItem,
      name: i18n.global.t(`DESC.TIMEZONE.${key}`),
    };
  });

/**
 * fromTime부터 toTime 이하까지 interval의 텀만큼의 시간 배열을 구하는 함수
 * fromTime, toTime은 'YYYY-MM-DD' ~ 'YYYY-MM-DD HH:mm:ss'
 * fromTime <= [fromTime, fromTime + i, fromTime + (i * 2), ... , fromTime + (i * n)] <= toTime
 */
const getIntervalDayArr = (fromTime: string, toTime: string, sec: number): string[] => {
  if (!fromTime || !toTime) return [];
  const result: any[] = [];
  let fromTimeUnix = +dayjs(fromTime);
  const toTimeUnix = +dayjs(toTime);
  while (fromTimeUnix < toTimeUnix) {
    result.push(dayjs(fromTimeUnix));
    fromTimeUnix += sec * 1000;
  }
  return result;
};

const checkFrameFetchCycle = ({
  defaultArr,
  prevArr,
  collectInfo = {},
}: {
  defaultArr: FetchInfo;
  prevArr: FetchInfo;
  collectInfo: CollectInfo | Record<string, never>;
}): FetchInfo | [] => {
  if (!collectInfo.collectInterval) {
    return prevArr?.length ? prevArr : defaultArr;
  }
  const { collectInterval, statName } = collectInfo;
  const isFirst = defaultArr[0] === prevArr[0] && prevArr[1] !== collectInterval;
  if (!isFirst && prevArr[1] !== collectInterval) {
    // eslint-disable-next-line no-alert
    window.alert(i18n.global.t('NOTI.UI.CYCLE_CHANGE', { statName }));
    window.location.reload();
    return [];
  }
  return [getIntervalMs(collectInterval).msTime, collectInterval];
};

const selectCollectInfo = (
  type: 'max' | 'min' = 'max',
  collectInfo?: CollectInfo[],
): CollectInfo => {
  if (!collectInfo || !collectInfo?.length) {
    return {} as CollectInfo;
  }

  let prevInterval: number = getIntervalMs(collectInfo[0].collectInterval).msTime;
  let result: CollectInfo = collectInfo[0];
  collectInfo.forEach((info) => {
    const currInterval = getIntervalMs(info.collectInterval).msTime;
    const isTypeMatched =
      type === 'max' ? prevInterval < currInterval : prevInterval > currInterval;
    if (isTypeMatched) {
      prevInterval = currInterval;
      result = info;
    }
  });

  return result;
};

// 유효하지 않은 planHashValue인지 체크하는 함수
const isFalsyPhv = (phv: any): boolean => [null, undefined, -1, ''].includes(phv);

// 배열의 가장 큰 값 Index 반환하는 함수
const getMaxIndex = (arr) => {
  let size = arr.length;
  let max = arr[0];
  let index = 0;
  while (size--) {
    if (arr[size] > max) {
      max = arr[size];
      index = size;
    }
  }
  return index;
};

const getAPIErrorStatusText = (e): string => {
  if (!Object.keys(e ?? {}).length) {
    return '';
  }

  // 응답 객체가 있는 경우 응답 코드로 처리함
  if (e?.response) {
    const { status, statusText } = e.response;

    if (statusText) {
      return statusText;
    }
    if (status === 403) {
      return localeEn.NOTI.API.NOT_PERMISSION;
    }
    if (status === 400) {
      return localeEn.NOTI.API.INVALID_REQUEST;
    }
    if (status === 405) {
      return localeEn.NOTI.API.NOT_ALLOW_METHOD;
    }
    if (status === 500) {
      return localeEn.NOTI.API.SERVER_ERROR;
    }
    if (status === 503) {
      return localeEn.NOTI.API.NOT_AVAILABLE_SERVICE;
    }

    return localeEn.NOTI.API.DEFAULT_ERROR;
  }

  if (e?.request) {
    if (e.code === 'ECONNABORTED') {
      return localeEn.NOTI.API.TIMEOUT_ERROR;
    }
    if (e.code === 'ERR_CANCELED') {
      return localeEn.NOTI.API.CANCEL_ERROR;
    }

    if (e.code === 'ERR_NETWORK') {
      return localeEn.NOTI.API.ERR_NETWORK;
    }

    return localeEn.NOTI.API.NOT_RECEIVE;
  }

  return localeEn.NOTI.API.REQUEST_ERROR;
};

const getMaxValue = (arr: number[]) => arr.sort((a, b) => b - a)[0];
const getMinValue = (arr: number[]) => arr.sort((a, b) => a - b)[0];
const getSumValue = (arr: number[]) => arr.reduce((a, b) => a + b, 0);
const getAvgValue = (arr: number[]) => getSumValue(arr) / arr.length;

/**
 * 숫자를 바이트 단위로 변환
 */
const formatBytes = (bytes: number, startUnit: FormatSize = 'Bytes', decimals = 2) => {
  let bytesNum = bytes;
  if (bytesNum === 0) return '0';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

  let i = sizes.indexOf(startUnit);
  if (i === -1) {
    console.log('startUnit is not valid');
    i = 0;
  }

  while (bytesNum >= k && i < sizes.length - 1) {
    bytesNum /= k;
    i++;
  }

  return `${bytesNum.toFixed(dm)} ${sizes[i]}`;
};

const getFileBlobType = (fileType: 'text' | 'excel' | 'json' | 'xml'): string => {
  switch (fileType) {
    case 'excel':
      return 'application/vnd.ms-excel';
    case 'xml':
      return 'application/xml';
    case 'json':
      return 'application/json;charset=utf-8';
    case 'text':
    default:
      return 'text/plain;charset=utf-8';
  }
};

const downloadByUrl = (path: string, name?: string) => {
  const el = document.createElement('a');
  el.href = path;
  el.download = name ?? path;
  el.style.display = 'none';

  document.body.appendChild(el);
  el.click();
  document.body.removeChild(el);
};

const fileDownload = ({
  fileName,
  fileData,
  fileType = 'text',
}: {
  fileName: string;
  fileData: string | Buffer | ArrayBuffer;
  fileType?: 'text' | 'excel' | 'json' | 'xml';
}) => {
  const blobType = getFileBlobType(fileType);
  const blob = new Blob([fileData], {
    type: blobType,
  });
  const url = window.URL.createObjectURL(blob);

  downloadByUrl(url, fileName);

  URL.revokeObjectURL(url);
};

/**
 * obj의 K-V를 순회하면서 V가 null인 경우를 삭제하는 로직
 * @param obj
 * @param selectedKeys  이 배열이 있는 경우는 selectedKeys가 있는 K만 null 체크함. 나머지는 pass.
 */
const removeNullValues = <T extends Record<string, any>>(obj: T, selectedKeys?: (keyof T)[]): T => {
  if (!Object.keys(obj)?.length) {
    return {} as T;
  }
  if (!selectedKeys?.length) {
    return Object.keys(obj).reduce((acc: any, key: string) => {
      if (obj[key] !== null) {
        acc[key] = obj[key];
      }
      return acc;
    }, {} as T);
  }
  return Object.keys(obj).reduce((acc: any, key: string) => {
    if (selectedKeys.includes(key)) {
      if (obj[key] !== null) {
        acc[key] = obj[key];
      }
    } else {
      acc[key] = obj[key];
    }
    return acc;
  }, {} as T);
};

const getConvertNumberOrElse = (
  fn: (v: number) => any,
  val?: number | null,
  defaultValue: number | string | undefined | null = null,
) => {
  if ((val !== 0 && !val) || val < 0) {
    return defaultValue;
  }
  return fn(val);
};
const waitTime = async (delayTimeMs: number) =>
  new Promise((resolve) => setTimeout(resolve, delayTimeMs));

const getCurrentTheme = () => {
  const datasetTheme = document.documentElement.dataset.theme ?? '';
  return PRODUCT_THEMES.includes(datasetTheme) ? datasetTheme : DEFAULT_PRODUCT_THEME;
};
const usePrismTheme = () => {
  onMounted(() => {
    if (getCurrentTheme() === 'light') {
      import('prismjs/themes/prism.css');
    }
  });
};

const getTimezoneForApiParam = (): TimezoneNameForApiParam => {
  const timeZone = store.getters['myInfo/getTimeZone'];
  const exceptions = ['America/Argentina/Buenos_Aires', 'America/Glace_Bay', 'America/St_Johns'];
  if (exceptions.includes(timeZone)) {
    return timeZone.replace('_', '').replace('/', '_');
  }
  return timeZone.replace('/', '_');
};

const getIntervalUnitOrder = (unit: string): number => {
  const unitOrder = ['ms', 's', 'm', 'h', 'd', 'w', 'M', 'y'];
  return unitOrder.indexOf(unit);
};

const getIntervalNumericValue = (str: string): number => {
  return +str.replace(/[^0-9]/g, '');
};

const getUnitFromInterval = (str: string): string => {
  if (str.endsWith('ms')) {
    return 'ms';
  }
  return str[str.length - 1];
};

const compareInterval = (aInterval: string, bInterval: string) => {
  const aUnitOrder = getIntervalUnitOrder(getUnitFromInterval(aInterval));
  const bUnitOrder = getIntervalUnitOrder(getUnitFromInterval(bInterval));
  const aVal = getIntervalNumericValue(aInterval);
  const bVal = getIntervalNumericValue(bInterval);

  // 같은 경우
  if (aInterval === bInterval) {
    return 0;
  }
  // 단위 우선 순위
  if (aUnitOrder > bUnitOrder) {
    return 1;
  }
  if (aUnitOrder < bUnitOrder) {
    return -1;
  }
  // 값 우선 순위
  if (aVal > bVal) {
    return 1;
  }
  return -1;
};

const scrollToBottom = (selectors: string) => {
  const windowContentEl = window.document.querySelector(selectors);
  if (windowContentEl) {
    nextTick(() => {
      windowContentEl.scrollTo({
        top: windowContentEl.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    });
  }
};

type SortObjectByKey = <T extends object>(
  array: T[],
  key: string,
  sortType?: 'ascending' | 'descending',
) => T[];
const sortObjectByKey: SortObjectByKey = (array, key, sortType = 'ascending') => {
  if (sortType === 'ascending') {
    return array.sort((a, b) => {
      return a[key] - b[key];
    });
  }
  return array.sort((a, b) => {
    return b[key] - a[key];
  });
};

const isIncludeTextWithoutWhitespace = (base: string, target: string): boolean => {
  const baseText = base.toUpperCase().replace(/\s+/g, '');
  const targetText = target.toUpperCase().replace(/\s+/g, '');

  return baseText.includes(targetText);
};

const getElapsedTime = (targetTime: number): { time: number; unit: string } => {
  const start = +new Date(targetTime);
  const end = +new Date();

  const diff = (end - start) / 1000;

  const times = [
    { name: i18n.global.t('WORD.ELAPSED_YEAR'), value: 60 * 60 * 24 * 365 },
    { name: i18n.global.t('WORD.ELAPSED_MONTH'), value: 60 * 60 * 24 * 30 },
    { name: i18n.global.t('WORD.ELAPSED_DAY'), value: 60 * 60 * 24 },
    { name: i18n.global.t('WORD.ELAPSED_HOUR'), value: 60 * 60 },
    { name: i18n.global.t('WORD.ELAPSED_MINUTE'), value: 60 },
    { name: i18n.global.t('WORD.ELAPSED_SECOND'), value: 1 },
  ];

  let resultTime;
  let resultUnit;

  for (let i = 0; i < times.length; i++) {
    const time = times[i];
    const betweenTime = Math.floor(diff / time.value);

    if (betweenTime > 0) {
      resultTime = betweenTime;
      resultUnit = time.name;
      break;
    }
  }

  return {
    time: resultTime,
    unit: resultUnit,
  };
};

const generateUUID = () => {
  return uuid();
};

// sql text의 placeholder(=?)를 default value 문자열 1(='1')로 치환하는 함수
const replaceSqlDefaultValue = ({
  sqlText,
  placeholder = '?',
  defaultValue = "'1'",
}: {
  sqlText: string | null;
  placeholder?: string;
  defaultValue?: string;
}) => {
  if (!sqlText) {
    return sqlText;
  }

  return sqlText.replaceAll(new RegExp(`@\\${placeholder}|\\${placeholder}`, 'gm'), defaultValue);
};

/**
 * https://www.figma.com/design/bydQRFqbKyYQRnR8qclpqN/97_2024-Components?node-id=6626-17857&t=qPHUSHOmcUfhJ9Q0-1
 */
const requestInterval = [
  'I5s',
  // 'I10s',
  // 'I15s',
  // 'I30s',
  'I1m',
  // 'I3m',
  // 'I5m',
  'I10m',
  // 'I20m',
  // 'I15m',
  // 'I30m',
  'I1h',
  // 'I2h',
  // 'I5h',
  'I1d',
  // 'I24h',
] as const;
export type RequestInterval = (typeof requestInterval)[number];

/**
 * @see @link https://www.figma.com/design/1SCjDJcHByNX3pEojbH9Eo/02.-%EA%B3%B5%ED%86%B5-%EA%B0%80%EC%9D%B4%EB%93%9C?node-id=5-20186&t=8j7nuAcHeJEuUWE7-4
 */
const getDataArrangeIntervalByTimeRange = (timeRange: number): RequestInterval => {
  // 7 * 24 * 60 * 60 * 1000 (7 - 31일)
  if (timeRange > 7 * ONE_DAY) {
    return 'I1d';
  }

  // 24 * 60 * 60 * 1000 (1 - 7일)
  if (timeRange > ONE_DAY) {
    return 'I1h';
  }
  // 60 * 60 * 1000 (1시간 초과)
  if (timeRange > ONE_HOUR) {
    return 'I10m';
  }
  if (timeRange > 10 * ONE_MINUTE) {
    return 'I1m';
  }
  return 'I5s';
};

const getTimeByPeriod = (period: string): number => {
  const pp = period.match(/p(\d+)([smhd])/);
  if (!pp) {
    return 600_000;
  }
  const [, num, unit] = pp;

  switch (unit) {
    case 's':
      return Number(num) * 1_000;
    case 'm':
      return Number(num) * 60_000;
    case 'h':
      return Number(num) * 3_600_000;
    case 'd':
      return Number(num) * 86_400_000;
    default:
      return 600_000;
  }
};

const getTimeIntervalByTimePeriod = (timePeriod: string): RequestInterval => {
  const timeRange = getTimeByPeriod(timePeriod);
  return getDataArrangeIntervalByTimeRange(timeRange);
};

const getAxesXOptionByInterval = (interval: RequestInterval) => {
  if (interval === 'I1d') {
    return {
      timeFormat: 'DD HH:mm',
      interval: 'day',
    };
  }
  if (interval === 'I1h') {
    return {
      timeFormat: 'DD HH:mm',
      interval: 'hour',
    };
  }
  if (interval === 'I10m' || interval === 'I1m') {
    return {
      timeFormat: 'HH:mm',
      interval: 'minute',
    };
  }
  return {
    timeFormat: 'HH:mm:ss',
    interval: 'second',
  };
};

const formatTmeByInterval = (time: number | string, interval: RequestInterval) => {
  // 기본적으로 dayjs 객체를 생성합니다.
  const datetime = dayjs(time);

  if (interval === 'I1d') {
    return datetime.format('YYYY-MM-DD 00:00:00');
  }
  if (interval === 'I1h') {
    return datetime.format('YYYY-MM-DD HH:00:00');
  }
  if (interval === 'I10m') {
    // 분의 일의 자리를 0으로 설정합니다.
    const minutes = datetime.minute();
    const roundedMinutes = minutes - (minutes % 10);
    return datetime.minute(roundedMinutes).second(0).format('YYYY-MM-DD HH:mm:00');
  }
  if (interval === 'I1m') {
    return datetime.second(0).format('YYYY-MM-DD HH:mm:00');
  }
  if (interval === 'I5s') {
    // 초를 5 또는 0으로 설정합니다.
    const seconds = datetime.second();
    const roundedSeconds = seconds - (seconds % 5);

    return datetime.second(roundedSeconds).format('YYYY-MM-DD HH:mm:ss');
  }
  return datetime.format('YYYY-MM-DD HH:mm:ss');
};

const getDataArrangeIntervalByStartEndTime = (
  startTimeStr: string,
  endTimeStr?,
): RequestInterval => {
  try {
    const endTime = endTimeStr ? dayjs(endTimeStr) : dayjs();
    const range = endTime.diff(dayjs(startTimeStr));
    return getDataArrangeIntervalByTimeRange(range);
  } catch (e) {
    // 타임 포멧 아니면 기본값
    return 'I1m';
  }
};

const getTimeByInterval = (interval: RequestInterval): number => {
  switch (interval) {
    case 'I5s':
      return 5 * 1000;
    case 'I1m':
      return 60 * 1000;
    case 'I10m':
      return 10 * 60 * 1000;
    case 'I1h':
      return 60 * 60 * 1000;
    case 'I1d':
      return 24 * 60 * 60 * 1000;
    default:
      throw new Error(`${interval}에 대한 time을 추가해주세요.`);
  }
};

const getCSSVariable = (varName?: string) => {
  let cssVariableName = varName;
  const match = varName?.match(/var\((--[^)]+)\)/);

  if (match && varName) {
    cssVariableName = match[1].trim();
    return getComputedStyle(document.documentElement).getPropertyValue(cssVariableName).trim();
  }

  return varName;
};

const adjustSummaryRange = (
  minMs: number,
  maxMs: number,
): { minMsElapse: number; maxMsElapse: number } => {
  const minMsElapse = minMs <= 1 ? minMs : Math.ceil(minMs / 100) * 100;
  const maxMsElapse = maxMs === 0 ? 0 : Math.floor(maxMs / 100) * 100 + 99;
  return {
    minMsElapse,
    maxMsElapse,
  };
};

export {
  getTimeIntervalByTimePeriod,
  sortObjectByKey,
  scrollToBottom,
  getVersion,
  truthyNumber,
  convertCamelToCapital,
  convertCamelToKebab,
  numberWithComma,
  formatNumberToLocale,
  confirmMsg,
  promiseConfirmMsg,
  showSuccessMsg,
  showErrorMsg,
  showLoadingMsg,
  getErrorMsgOfPromiseReject,
  showErrorMsgOfPromiseReject,
  showInfoMsg,
  showWarningMsg,
  setScrollTopForFullWindow,
  changeDurationMsToDateTimeFormat,
  standardTimeToUtcZeroTime,
  utcZeroTimeToStandardTime,
  convertTimeToRangeTime,
  getDataArrangeIntervalByTimeRange,
  getDataArrangeIntervalByStartEndTime,
  convertBytesToUnits,
  convertNumberToUnits,
  convertMsToSec,
  convertMsToDynamicTime,
  checkValid,
  formatNumWithCommasAndDecimal,
  formatNumWithCommasAndDecimalStr,
  replaceInstanceObjectType,
  roundToDigit,
  roundToDigitNumber,
  convertTimeToUnits,
  convertMbToGb,
  convertTimeToUnitsWithMaxUnit,
  replaceInstanceStateClass,
  trimSqlText,
  convertUnitValue,
  getToggleDigit,
  isOverMysql5dot7Version,
  isOverMysql812Version,
  isOverMysql828Version,
  compareMysqlVersion,
  getGridColumnFieldSuffixByToggle,
  onPreventClickBubbling,
  setShowSqlTextClsName,
  getUtcOffset,
  formatSqlText,
  setMysqlSetData,
  setOracleSetData,
  setSqlServerSetData,
  setPostgresqlSetData,
  makeMetricDisplayNames,
  getRtmSelectedInfo,
  getInstanceByDbType,
  getEnabledInstance,
  getRequireParam,
  getMaxValLastIdx,
  getRatio,
  getTimezoneItemsByLocale,
  getIntervalDayArr,
  checkFrameFetchCycle,
  selectCollectInfo,
  isFalsyPhv,
  getMaxIndex,
  getAPIErrorStatusText,
  getMaxValue,
  getMinValue,
  getAvgValue,
  getSumValue,
  formatBytes,
  downloadByUrl,
  fileDownload,
  removeNullValues,
  getConvertNumberOrElse,
  waitTime,
  getRoundedNumberWithComma,
  getRoundedNumberOrNull,
  getCurrentTheme,
  usePrismTheme,
  getTimezoneForApiParam,
  compareInterval,
  convertBpsToUnit,
  isIncludeTextWithoutWhitespace,
  getElapsedTime,
  generateUUID,
  replaceSqlDefaultValue,
  getTimeByInterval,
  getAxesXOptionByInterval,
  formatTmeByInterval,
  getCSSVariable,
  adjustSummaryRange,
};
