import { ComputedRef } from 'vue';
import { ChartUnitInfo } from '@/common/utils/types';
import { convertValueWithUnit } from './convertUnits.utils';

export const formatChartValueWithUnit = ({
  value,
  unit,
  decimal,
  toUnit = undefined,
  convertToLocaleString = false,
}: {
  value: number;
  unit: string;
  decimal: number;
  toUnit?: string;
  convertToLocaleString?: boolean;
}): string => {
  const { value: convertedValue, unit: convertedUnit } = convertValueWithUnit(
    { value, unit },
    { decimal, toUnit },
  );
  const localeValue = convertToLocaleString ? convertedValue.toLocaleString() : convertedValue;
  const convertedUnitWithSpace = convertedUnit.includes(' ') ? convertedUnit : ` ${convertedUnit}`;
  return `${localeValue}${convertedUnitWithSpace}`;
};

export const formatYAxisWithUnit = ({
  value,
  unit,
  decimal = 3,
  showUnit = true,
  toUnit = undefined,
}: {
  value: number;
  unit: string;
  decimal?: number;
  showUnit?: boolean;
  toUnit?: string;
}): string => {
  if (!showUnit) {
    const { value: convertedValue } = convertValueWithUnit({ value, unit }, { decimal, toUnit });
    return `${convertedValue}`;
  }

  return formatChartValueWithUnit({ value, unit, decimal, toUnit });
};

export const formatTooltipWithUnit = ({
  value,
  unit,
  decimal = 3,
}: {
  value: number;
  unit: string;
  decimal?: number;
}): string => {
  return formatChartValueWithUnit({ value, unit, decimal, convertToLocaleString: true });
};

export const useEvChartTooltipFormatter = (
  unitList: ComputedRef<ChartUnitInfo[]>,
  isHorizontal = false,
) => {
  const tooltipValueFormatter = ({ seriesId, x, y }) => {
    const value = isHorizontal ? x : y;
    const unit = unitList.value.find((unitInfo) => unitInfo.seriesId === seriesId)?.unit ?? '';
    if (unit) {
      return formatTooltipWithUnit({ value, unit });
    }
    return value.toLocaleString();
  };

  return {
    tooltipValueFormatter,
  };
};
