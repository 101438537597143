import { ref } from 'vue';
import { defineStore } from 'pinia';
import { MenuInfo } from '@/common/components/organisms/baseMenu/types';
import { VIEW_MODE } from '@/common/define/common.define';
import { useViewModeStore } from './view-mode';
import { BASE_MENU_VALUE } from '../components/organisms/baseMenu/baseMenu.define';

export interface MenuHistory {
  [key: string]: string;
}

export interface ExpandedMenu {
  expanded: boolean; // 기본 GNB 좌우 확장 여부
  menuValue?: string;
  subMenuValue?: string;
}

export interface PanelMenu {
  menuInfo: MenuInfo | null;
  subMenuValue?: string;
}

export const useBaseMenuStore = defineStore('baseMenuStore', () => {
  const { viewMode } = useViewModeStore();
  const menuHistory = ref<MenuHistory>({});
  const menuVisitRoute = ref<MenuInfo[]>([]);

  const expandedMenu = ref<ExpandedMenu>({ expanded: false });
  const panelMenu = ref<PanelMenu>({ menuInfo: null });
  const isBaseMenuHidden = ref<boolean>(false);

  const getLastMenu = (category: string) => menuHistory.value?.[category] ?? '';
  const updateLastMenu = (category: string, lastMenu: string) => {
    menuHistory.value[category] = lastMenu;
  };

  const destroyLastMenu = () => {
    menuHistory.value = {};
  };

  const toggleBaseMenu = () => {
    isBaseMenuHidden.value = !isBaseMenuHidden.value;
  };

  const selectPanelMenu = (subMenuValue: string) => {
    panelMenu.value.subMenuValue = subMenuValue;
  };

  const selectMenu = ({
    menuValue,
    subMenuValue,
  }: {
    menuValue?: string;
    subMenuValue?: string;
  }) => {
    if (menuValue) {
      expandedMenu.value.menuValue = menuValue;
    }
    if (subMenuValue) {
      expandedMenu.value.subMenuValue = subMenuValue;
    }
  };

  const openMenu = (menuList: MenuInfo) => {
    // 우측 패널 열림
    expandedMenu.value.subMenuValue = menuList.value;
    if (panelMenu.value.menuInfo?.value === menuList.value) {
      return;
    }
    panelMenu.value.menuInfo = menuList;
  };

  const closeMenu = () => {
    // 우측 패널 닫힘
    panelMenu.value.menuInfo = null;
    delete panelMenu.value.subMenuValue;
  };

  const shrinkMenu = () => {
    // 확장된 메뉴 축소
    expandedMenu.value.expanded = false;
    delete expandedMenu.value.subMenuValue;
  };

  const convertSubMenuForPADatabase = (subMenuValue?: string) => {
    if (!subMenuValue || viewMode !== VIEW_MODE.EXEMONE) return subMenuValue;
    if (subMenuValue.startsWith(BASE_MENU_VALUE.ORACLE)) {
      return BASE_MENU_VALUE.ORACLE;
    }
    if (subMenuValue.startsWith(BASE_MENU_VALUE.PG)) {
      return BASE_MENU_VALUE.PG;
    }
    if (subMenuValue.startsWith(BASE_MENU_VALUE.MYSQL)) {
      return BASE_MENU_VALUE.MYSQL;
    }
    if (subMenuValue.startsWith(BASE_MENU_VALUE.SQLSERVER)) {
      return BASE_MENU_VALUE.SQLSERVER;
    }
    return subMenuValue;
  };

  const expandMenu = (menuValue?: string, subMenuValue?: string) => {
    // 특정 메뉴 클릭하면 열림
    if (!expandedMenu.value.expanded) {
      // 메뉴 바 좁혀져 있을 때 확장
      expandedMenu.value.expanded = true;

      if (!expandedMenu.value.menuValue || expandedMenu.value.menuValue !== menuValue) {
        // 열린 메뉴가 없거나 다른 메뉴 클릭할 때
        expandedMenu.value.menuValue = menuValue;
      }
    } else if (expandedMenu.value.menuValue === menuValue) {
      // 메뉴 바 확장되어 있고 같은 메뉴 한번 더 클릭할 때
      if (
        !(expandedMenu.value.menuValue === BASE_MENU_VALUE.HELP) &&
        !(expandedMenu.value.menuValue === BASE_MENU_VALUE.SETTINGS)
      ) {
        // 고객지원 메뉴, 프로필 메뉴는 여닫는 형태가 아니므로 delete 불필요
        delete expandedMenu.value.menuValue;
      }
      delete expandedMenu.value.subMenuValue;
    } else {
      // 메뉴 바 확장되어 있고 다른 메뉴 클릭할 때
      expandedMenu.value.menuValue = menuValue;
    }
    if (subMenuValue) {
      // 열린 메뉴 내부의 하위 메뉴 선택
      expandedMenu.value.subMenuValue = convertSubMenuForPADatabase(subMenuValue);
    }

    if (
      panelMenu.value.menuInfo &&
      menuValue !== BASE_MENU_VALUE.SETTINGS &&
      menuValue !== BASE_MENU_VALUE.HELP
    ) {
      closeMenu();
    }
  };

  const resetMenu = () => {
    closeMenu();
    shrinkMenu();
    destroyLastMenu();
  };

  return {
    menuHistory,
    menuVisitRoute,

    getLastMenu,
    updateLastMenu,

    expandedMenu,
    panelMenu,

    selectPanelMenu,
    selectMenu,
    openMenu,
    closeMenu,

    expandMenu,
    shrinkMenu,

    resetMenu,

    isBaseMenuHidden,
    toggleBaseMenu,
  };
});
