import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { DB_TYPE } from '@/common/utils/define';
import { getRtmSelectedInfo } from '@/common/utils/commonUtils';
import { InstanceV2Item } from '@/openapi/meta/model';
import { mapMetricNamesToDataIds } from '@/database/utils/utils';
import { addXmUserEnvControllerAxios } from '@/openapi/metav8Over/api/xm-user-env-controller-api';

interface MetricInfo {
  metricName: string;
  displayName: string;
}
type SingleViewComp =
  | 'dailyTrend'
  | 'tempDbUsageGaugeChart'
  | 'tempDbUsageSizeChart'
  | 'alertCount'
  | 'overview'
  | 'dbMetric'
  | 'sessionGrid';
type SingleViewLoadingComp = {
  [key in SingleViewComp]: boolean;
};
interface State {
  selectedInstanceId: string | null;
  selectedInstanceInfo: InstanceV2Item;
  trendChartMetricNames: string[];
  dbMetricNames: string[];
  framesByFailedApi: Map<string, string>;
  loadingInfo: SingleViewLoadingComp;
}

const TREND_CHART_METRIC_NAMES_KEY = 'SQLSERVER_TREND_CHART_METRIC_NAMES';
const METRIC_CHART_METRIC_NAMES_KEY = 'SQLSERVER_METRIC_CHART_METRIC_NAMES';
const SELECTED_ID_KEY = 'SQLSERVER_SINGLE_VIEW_SELECTED_ID';
export const singleViewEnv: Module<State, RootState> = {
  namespaced: true,
  state: {
    selectedInstanceId: null,
    selectedInstanceInfo: {},
    trendChartMetricNames: [],
    dbMetricNames: [],
    loadingInfo: {
      dailyTrend: true,
      tempDbUsageGaugeChart: true,
      tempDbUsageSizeChart: true,
      alertCount: true,
      overview: true,
      dbMetric: true,
      sessionGrid: true,
    },
    framesByFailedApi: new Map(),
  },
  mutations: {
    setSelectedInstanceId(state: State, id: string) {
      state.selectedInstanceId = id;
    },
    setSelectedInstanceInfo(state: State, info: State['selectedInstanceInfo']) {
      state.selectedInstanceInfo = info;
    },
    setTrendChartMetricNames: (state: State, metricNames: string[]) => {
      state.trendChartMetricNames = metricNames;
    },
    setDbMetricNames: (state: State, metricNames: string[]) => {
      state.dbMetricNames = metricNames;
    },
    setFramesByFailedApi: (state: State, failedApiInfo) => {
      state.framesByFailedApi.set(failedApiInfo.frameName, failedApiInfo.statusText);
    },
    setLoadingInfo: (
      state: State,
      { frameName, isLoading }: { frameName: SingleViewComp; isLoading: boolean },
    ) => {
      state.loadingInfo[frameName] = isLoading;
    },
    deleteFramesByFailedApi: (state: State, frameNameOfFailedApi: string) => {
      if (state.framesByFailedApi.has(frameNameOfFailedApi)) {
        state.framesByFailedApi.delete(frameNameOfFailedApi);
      }
    },
    initFramesByFailedApi: (state: State) => {
      state.framesByFailedApi = new Map();
    },
    initSelectedInfo: (state: State) => {
      state.selectedInstanceId = null;
      state.selectedInstanceInfo = {};
    },
    initLoadingInfo: (state: State) => {
      Object.keys(state.loadingInfo).forEach((v) => {
        state.loadingInfo[v] = true;
      });
    },
  },
  actions: {
    fetchTrendChartMetricNames: async ({ commit, rootGetters }) => {
      const DEFAULT_NAMES = [
        'db_sqlserver_os.cpu_usage',
        'db_sqlserver_total_wait_time/sec',
        'db_sqlserver_active_sessions',
      ];
      const trendChartMetricNamesInUserEnv = rootGetters['userEnv/getUserEnvMap'].get(
        TREND_CHART_METRIC_NAMES_KEY,
      );

      if (trendChartMetricNamesInUserEnv) {
        await commit(
          'setTrendChartMetricNames',
          mapMetricNamesToDataIds({
            dbType: DB_TYPE.SQLSERVER,
            metricNames: JSON.parse(trendChartMetricNamesInUserEnv),
          }),
        );
        return;
      }

      await commit('setTrendChartMetricNames', DEFAULT_NAMES);
    },
    saveTrendChartMetricNames: async ({ commit }, metricNames) => {
      await addXmUserEnvControllerAxios({
        request: [
          {
            key: TREND_CHART_METRIC_NAMES_KEY,
            value: JSON.stringify(metricNames),
          },
        ],
      });
      commit(
        'userEnv/setUserEnvMap',
        { key: TREND_CHART_METRIC_NAMES_KEY, value: JSON.stringify(metricNames) },
        { root: true },
      );
    },
    fetchMetricChartMetricNames: async ({ commit, rootGetters }) => {
      const DEFAULT_NAMES = [
        'db_sqlserver_active_sessions',
        'db_sqlserver_page_lookups/sec',
        'db_sqlserver_page_reads/sec',
        'db_sqlserver_batch_requests/sec',
        'db_sqlserver_total_wait_time/sec',
      ];
      const metricNamesInUserEnv = rootGetters['userEnv/getUserEnvMap'].get(
        METRIC_CHART_METRIC_NAMES_KEY,
      );

      if (metricNamesInUserEnv) {
        await commit(
          'setDbMetricNames',
          mapMetricNamesToDataIds({
            dbType: DB_TYPE.SQLSERVER,
            metricNames: JSON.parse(metricNamesInUserEnv),
          }),
        );
        return;
      }

      await commit('setDbMetricNames', DEFAULT_NAMES);
    },
    saveMetricChartMetricNames: async ({ commit }, metricNames) => {
      await addXmUserEnvControllerAxios({
        request: [
          {
            key: METRIC_CHART_METRIC_NAMES_KEY,
            value: JSON.stringify(metricNames),
          },
        ],
      });
      commit(
        'userEnv/setUserEnvMap',
        { key: METRIC_CHART_METRIC_NAMES_KEY, value: JSON.stringify(metricNames) },
        { root: true },
      );
    },
    fetchLastSelectedId: async ({ commit, dispatch, rootGetters }) => {
      try {
        const data = rootGetters['userEnv/getUserEnvMap'].get(SELECTED_ID_KEY);
        const idInfo = JSON.parse(data ?? '{}');
        const folderList = rootGetters['monitoringFolder/getFolders'];
        const { folderId, instanceId, selectedInfo } = getRtmSelectedInfo({
          idInfo,
          folderList,
          dbType: DB_TYPE.SQLSERVER,
        });

        // userEnv에서 조회한 instanceId와 실제 화면에 보여져야 할 instanceId가 다른 경우
        // e.g. userEnv에서는 A라는 인스턴스가 저장되어 있지만 A라는 인스턴스가 disable로 변경된 상태인 경우
        if (idInfo.instanceId !== instanceId) {
          await dispatch('saveLastSelectedId', {
            folderId,
            instanceId,
          });
        }

        commit('setSelectedInstanceId', instanceId);
        commit('setSelectedInstanceInfo', selectedInfo);
      } catch (e: any) {
        console.log(e);
      }
    },
    saveLastSelectedId: async ({ commit, rootGetters }, selectedInfo: Record<string, string>) => {
      if (!selectedInfo.folderId || !selectedInfo.instanceId) {
        return;
      }
      try {
        commit('setSelectedInstanceId', selectedInfo.instanceId);
        const selectedInstanceInfo = rootGetters['monitoringFolder/getFolders']
          .find((item) => item.folderId === selectedInfo.folderId)
          .instances.find((item) => item.instanceId === selectedInfo.instanceId);
        commit('setSelectedInstanceInfo', selectedInstanceInfo);
        await addXmUserEnvControllerAxios({
          request: [
            {
              key: SELECTED_ID_KEY,
              value: JSON.stringify(selectedInfo),
            },
          ],
        });
        commit(
          'userEnv/setUserEnvMap',
          { key: SELECTED_ID_KEY, value: JSON.stringify(selectedInfo) },
          { root: true },
        );
      } catch (e: any) {
        console.log(e);
      }
    },
  },
  getters: {
    getSelectedInstanceId: (state: State) => state.selectedInstanceId,
    getSelectedInstanceInfo: (state: State) => state.selectedInstanceInfo,
    getSelectedDbType: (state: State) => state.selectedInstanceInfo.type,
    getSelectedDbVersion: (state: State) => state.selectedInstanceInfo.instanceState?.version,

    isExistFramesByFailedApi: (state: State): boolean => !!state.framesByFailedApi.size,
    getFramesByFailedApi: (state: State) => state.framesByFailedApi,

    getDbMetricNames: (state: State) => state.dbMetricNames,
    getDbMetricInfos: (state, getters, rootState, rootGetters) => {
      const displayMetricNames = rootGetters['dbMetric/getMetricDisplayNames'];
      return state.dbMetricNames.reduce(
        (acc, cur) => [
          ...acc,
          {
            metricName: cur,
            displayName: displayMetricNames.get(cur),
          },
        ],
        [] as MetricInfo[],
      );
    },
    getTrendChartMetricNames: (state: State) => state.trendChartMetricNames,
    getTrendChartMetricInfos: (state, getters, rootState, rootGetters) => {
      const displayMetricNames = rootGetters['dbMetric/getMetricDisplayNames'];
      return state.trendChartMetricNames.reduce(
        (acc, cur) => [
          ...acc,
          {
            metricName: cur,
            displayName: displayMetricNames.get(cur),
          },
        ],
        [] as MetricInfo[],
      );
    },
    getLoadingInfo: (state: State) => state.loadingInfo,
    isAllLoading: (state: State) => Object.values(state.loadingInfo).some((value) => value),
  },
};
