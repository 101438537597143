import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { getAPIErrorStatusText, roundToDigitNumber } from '@/common/utils/commonUtils';
import {
  getTopGlobalTempMysqlV7ControllerAxios,
  getTopQueriesMysqlV7ControllerAxios,
  getTopRedoUsageMysqlV7ControllerAxios,
  getTopSortListMysqlV7ControllerAxios,
  topTempSessionUsageMysqlV7ControllerAxios,
} from '@/openapi/mysqlV7/api/mysql-v7-controller-api';
import { getMetricData } from '@/common/api/data';
import { MetricResponseItem } from '@/openapi/data/model';
import { AlertLogsChild, AlertLogsItem } from '@/openapi/alert/model';
import { TopMetricItemV7 } from '@/openapi/mysqlV7/model';
import { checkValidMetric, MetricFailError } from '@/database/utils/utils';
import { MetricRequest } from '@/common/utils';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { getCurrentByServiceAlertCommonControllerAxios } from '@/openapi/alert/api/alert-common-controller-api';
import { lowerCase } from 'lodash-es';
import { CHECK_BY_COMBINATION } from '@/alert/utils/define';

interface TopRankRes extends TopMetricItemV7 {
  isSupported?: boolean; // Mysql > Global Temp Usage, Session Temp Usage 지표에 적용.
}

interface State {
  sortMergePasses: TopRankRes[];
  globalTempUsage: TopRankRes[];
  sessionTempUsage: TopRankRes[];
  queriesDelta: TopRankRes[];
  redoUsage: TopRankRes[];
  alertList: AlertLogsItem[];
  topRankMetric: MetricResponseItem[];
  errorStatusText: {
    sortMergePasses: string;
    globalTempUsage: string;
    sessionTempUsage: string;
    queriesDelta: string;
    redoUsage: string;
    alertList: string;
    topRankMetric: string;
  };
}

interface AlertInfo {
  warning: number;
  critical: number;
  instanceId?: string;
  instanceName?: string;
}

interface AlertTypeInfo {
  total: number;
  Other?: number;
  instanceId?: string;
  instanceName?: string;
}

export const rankMetric: Module<State, RootState> = {
  namespaced: true,
  state: {
    sortMergePasses: [],
    globalTempUsage: [],
    sessionTempUsage: [],
    queriesDelta: [],
    redoUsage: [],
    alertList: [],
    topRankMetric: [],
    errorStatusText: {
      sortMergePasses: '',
      globalTempUsage: '',
      sessionTempUsage: '',
      queriesDelta: '',
      redoUsage: '',
      alertList: '',
      topRankMetric: '',
    },
  },
  mutations: {
    setSortMergePasses: (state: State, data: TopRankRes[]) => {
      state.sortMergePasses = data;
    },
    setGlobalTempUsage: (state: State, data: TopRankRes[]) => {
      state.globalTempUsage = data;
    },
    setSessionTempUsage: (state: State, data: TopRankRes[]) => {
      state.sessionTempUsage = data;
    },
    setQueriesDelta: (state: State, data: TopRankRes[]) => {
      state.queriesDelta = data;
    },
    setRedoUsage: (state: State, data: TopRankRes[]) => {
      state.redoUsage = data;
    },
    setAlertList: (state: State, data: AlertLogsItem[]) => {
      state.alertList = data;
    },
    setTopRankMetric: (state: State, data: MetricResponseItem[]) => {
      state.topRankMetric = data;
    },
    setErrorStatusText: (
      state: State,
      { errorStatusText, type }: { errorStatusText: string; type: keyof State['errorStatusText'] },
    ) => {
      state.errorStatusText[type] = errorStatusText;
    },
  },
  actions: {
    fetchSortMergePasses: async ({ commit }, instanceIds) => {
      const frameName = 'sortMergePasses';
      try {
        const { data } = await getTopSortListMysqlV7ControllerAxios({
          instanceIds,
          size: 15,
          frameName: FRAME_NAMES.MYSQL_MULTI_VIEW.RANK_SORT_MERGE_PASSES,
        });
        commit('setSortMergePasses', data?.data ?? []); // COUNT

        commit('setErrorStatusText', { errorStatusText: '', type: frameName });
        commit('mysqlMultiViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', { errorStatusText: statusText, type: frameName });
        commit('mysqlMultiViewEnv/setFramesByFailedApi', { frameName, statusText }, { root: true });
      }
    },
    fetchGlobalTempUsage: async ({ commit }, instanceIds) => {
      const frameName = 'globalTempUsage';
      try {
        const { data } = await getTopGlobalTempMysqlV7ControllerAxios({
          instanceIds,
          size: 15,
          frameName: FRAME_NAMES.MYSQL_MULTI_VIEW.RANK_GLOBAL_TEMP_USAGE,
        });
        let result: any[] = [];
        if (data?.data?.length) {
          result = data.data.map((v) => {
            if (!v.value && v.value !== 0) {
              return v;
            }
            v.value *= 1024 * 1024;
            return v;
          });
        }
        commit('setGlobalTempUsage', result); // SIZE

        commit('setErrorStatusText', { errorStatusText: '', type: frameName });
        commit('mysqlMultiViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', { errorStatusText: statusText, type: frameName });
        commit('mysqlMultiViewEnv/setFramesByFailedApi', { frameName, statusText }, { root: true });
      }
    },
    fetchSessionTempUsage: async ({ commit }, instanceIds) => {
      const frameName = 'sessionTempUsage';
      try {
        const { data } = await topTempSessionUsageMysqlV7ControllerAxios({
          instanceIds,
          size: 15,
          frameName: FRAME_NAMES.MYSQL_MULTI_VIEW.RANK_SESSION_TEMP_USAGE,
        });
        let result: any[] = [];
        if (data?.data?.length) {
          result = data.data.map((v) => {
            if (!v.value && v.value !== 0) {
              return v;
            }
            v.value *= 1024 * 1024;
            return v;
          });
        }
        commit('setSessionTempUsage', result); // SIZE

        commit('setErrorStatusText', { errorStatusText: '', type: frameName });
        commit('mysqlMultiViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', { errorStatusText: statusText, type: frameName });
        commit('mysqlMultiViewEnv/setFramesByFailedApi', { frameName, statusText }, { root: true });
      }
    },
    fetchQueriesDelta: async ({ commit }, instanceIds) => {
      const frameName = 'queriesDelta';
      try {
        const { data } = await getTopQueriesMysqlV7ControllerAxios({
          instanceIds,
          size: 15,
          frameName: FRAME_NAMES.MYSQL_MULTI_VIEW.RANK_QUERIES,
        });
        commit('setQueriesDelta', data?.data ?? []); // COUNT

        commit('setErrorStatusText', { errorStatusText: '', type: frameName });
        commit('mysqlMultiViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', { errorStatusText: statusText, type: frameName });
        commit('mysqlMultiViewEnv/setFramesByFailedApi', { frameName, statusText }, { root: true });
      }
    },
    fetchRedoUsage: async ({ commit }, instanceIds) => {
      const frameName = 'redoUsage';
      try {
        const { data } = await getTopRedoUsageMysqlV7ControllerAxios({
          instanceIds,
          size: 15,
          frameName: FRAME_NAMES.MYSQL_MULTI_VIEW.RANK_REDO_USAGE,
        });
        let result: any[] = [];
        if (data?.data?.length) {
          result = data.data.map((v) => {
            if (!v.value && v.value !== 0) {
              return v;
            }
            v.value = roundToDigitNumber(v.value, 1);
            return v;
          });
        }
        commit('setRedoUsage', result); // 소수점 1자리 퍼센트

        commit('setErrorStatusText', { errorStatusText: '', type: frameName });
        commit('mysqlMultiViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', { errorStatusText: statusText, type: frameName });
        commit('mysqlMultiViewEnv/setFramesByFailedApi', { frameName, statusText }, { root: true });
      }
    },
    fetchAlertList: async ({ commit }, instanceIds) => {
      const frameName = 'alertList';
      try {
        const { data } = await getCurrentByServiceAlertCommonControllerAxios({
          targetIds: instanceIds,
          targetKind: 'database',
          frameName: FRAME_NAMES.MYSQL_MULTI_VIEW.RANK_ALERT,
        });
        commit('setAlertList', data?.data ?? []);

        commit('setErrorStatusText', { errorStatusText: '', type: frameName });
        commit('mysqlMultiViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', { errorStatusText: statusText, type: frameName });
        commit('mysqlMultiViewEnv/setFramesByFailedApi', { frameName, statusText }, { root: true });
      }
    },
    fetchTopRankMetric: async ({ commit }, instanceIds) => {
      const frameName = 'topRankMetric';
      try {
        const METRIC_NAMES = [
          'db_mysql_sort_merge_passes',
          'db_mysql_global_temp_usage',
          'db_mysql_session_temp_usage',
          'db_mysql_queries_delta',
          'db_mysql_usage',
        ];
        const metricRequests = METRIC_NAMES.map<MetricRequest>((name) => ({
          aggregationType: 'byTarget',
          period: 'p1h',
          interval: 'I1m',
          category: 'mysql',
          dataId: name,
          summaryType: 'current',
          targetIds: instanceIds,
        }));
        const { data } = await getMetricData({
          metricV7Requests: metricRequests,
          frameName: FRAME_NAMES.MYSQL_MULTI_VIEW.RANK_METRIC,
        });

        checkValidMetric(data?.data ?? []);
        commit('setTopRankMetric', data?.data ?? []);

        commit('setErrorStatusText', { errorStatusText: '', type: frameName });
        commit('mysqlMultiViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e) {
        const statusText =
          e instanceof MetricFailError ? e.getErrorStatusText() : getAPIErrorStatusText(e);
        commit('setErrorStatusText', { errorStatusText: statusText, type: frameName });
        commit('mysqlMultiViewEnv/setFramesByFailedApi', { frameName, statusText }, { root: true });
      }
    },
  },
  getters: {
    getSortMergePasses: (state: State) => state.sortMergePasses,
    getGlobalTempUsage: (state: State) => state.globalTempUsage,
    getSessionTempUsage: (state: State) => state.sessionTempUsage,
    getQueriesDelta: (state: State) => state.queriesDelta,
    getRedoUsage: (state: State) => state.redoUsage,
    getAlertList:
      (state: State, getters, rootState, rootGetters) =>
      ({
        selectedInstanceId,
        isValidData = false,
      }: {
        selectedInstanceId?: string;
        isValidData?: boolean;
      }) => {
        const instanceIds = rootGetters['mysqlMultiViewEnv/getInstanceIds'];

        const filteredAlerts = state.alertList.reduce((filtered, alert) => {
          if (alert.ruleCriteria === CHECK_BY_COMBINATION) {
            const filteredChildren = (alert?.child || []).reduce((children, child) => {
              const filteredChildTargets = (child?.target || []).filter((target) => {
                if (!instanceIds.includes(target.targetId)) {
                  return false;
                }

                if (!selectedInstanceId) {
                  return true;
                }

                return target.targetId === selectedInstanceId;
              });

              if (filteredChildTargets.length) {
                children.push({
                  ...child,
                  target: filteredChildTargets,
                });
              }

              return children;
            }, [] as AlertLogsChild[]);

            if (filteredChildren.length) {
              filtered.push({ ...alert, child: filteredChildren });
            }

            return filtered;
          }

          if (!selectedInstanceId) {
            filtered.push(alert);
            return filtered;
          }

          const targetId = alert.target?.[0]?.targetId;
          if (targetId === selectedInstanceId) {
            filtered.push(alert);
          }

          return filtered;
        }, [] as AlertLogsItem[]);

        return filteredAlerts.filter((alert) =>
          isValidData ? alert.ruleName && alert.ruleType : alert,
        );
      },
    getTotalAlertCount:
      (state: State, getters) =>
      ({ selectedInstanceId }: { selectedInstanceId?: string }): AlertInfo =>
        getters.getAlertList({ selectedInstanceId }).reduce(
          (acc, { lastAlert }) => {
            const lowerLastAlert = lowerCase(lastAlert);
            if (lowerLastAlert === 'warning') {
              acc.warning++;
            } else if (lowerLastAlert === 'critical') {
              acc.critical++;
            }
            return acc;
          },
          { critical: 0, warning: 0 },
        ),
    getAlertCountByInstanceId: (state: State, getters, rootState, rootGetters): AlertInfo[] => {
      const instances = rootGetters['mysqlMultiViewEnv/getInstances'].reduce(
        (acc, { instanceId, name }) => {
          acc[instanceId] = { name };
          return acc;
        },
        {} as Record<string, string>,
      );
      const instanceIds = Object.keys(instances);

      const alertCountByInstanceId = instanceIds.reduce(
        (acc, instanceId) => {
          acc[instanceId] = {
            warning: 0,
            critical: 0,
            instanceName: instances[instanceId].name,
            instanceId,
          };
          return acc;
        },
        {} as Record<string, AlertInfo>,
      );

      const aggregator = (
        acc: Record<string, AlertInfo>,
        { targetId, lastAlert }: Partial<{ targetId: string; lastAlert: string }>,
      ) => {
        if (!targetId || !lastAlert) {
          return;
        }

        if (!instanceIds.includes(targetId)) {
          return;
        }

        const lowerLastAlert = lowerCase(lastAlert);
        if (!['critical', 'warning'].includes(lowerLastAlert)) {
          return;
        }

        acc[targetId][lowerLastAlert]++;
      };

      state.alertList.reduce((acc, { ruleCriteria, lastAlert, target, child }) => {
        if (ruleCriteria === CHECK_BY_COMBINATION) {
          const targetIds = (child || [])
            .map((childAlert) => childAlert.target.map(({ targetId }) => targetId))
            .flat();
          new Set(targetIds).forEach((targetId) =>
            aggregator(acc, {
              targetId,
              lastAlert,
            }),
          );
          return acc;
        }

        (target || []).forEach(({ targetId }) => {
          aggregator(acc, { targetId, lastAlert });
        });
        return acc;
      }, alertCountByInstanceId);

      return Object.values(alertCountByInstanceId).sort((a: AlertInfo, b: AlertInfo) => {
        const aTotal = a.warning + a.critical;
        const bTotal = b.warning + b.critical;

        if (aTotal === bTotal) {
          return b.critical - a.critical;
        }

        return bTotal - aTotal;
      });
    },
    getAlertTypeByInstanceId: (state: State, getters, rootState, rootGetters) => {
      const instances = rootGetters['mysqlMultiViewEnv/getInstances'].reduce(
        (acc, { instanceId, name }) => {
          acc[instanceId] = { name };
          return acc;
        },
        {} as Record<string, string>,
      );
      const instanceIds = Object.keys(instances);

      const alertCountByTypes = state.alertList.reduce(
        (acc, { ruleType }) => {
          if (!ruleType) {
            return acc;
          }

          if (!acc[ruleType]) {
            acc[ruleType] = 0;
          }
          acc[ruleType]++;

          return acc;
        },
        {} as Record<string, number>,
      );

      const TOP_LIMIT = 10;
      const topAlertNames = Object.entries(alertCountByTypes)
        .sort(([, nextVal], [, curVal]) => curVal - nextVal)
        .slice(0, TOP_LIMIT)
        .map(([ruleType, _]) => [ruleType, 0]);

      const alertTypeCountByInstanceId = instanceIds.reduce(
        (acc, instanceId) => {
          acc[instanceId] = {
            total: 0,
            ...Object.fromEntries(topAlertNames),
            instanceName: instances[instanceId].name,
            instanceId,
          };

          if (topAlertNames.length > TOP_LIMIT) {
            acc[instanceId].Other = 0;
          }

          return acc;
        },
        {} as Record<string, AlertTypeInfo>,
      );

      const aggregator = (
        acc: Record<string, AlertTypeInfo>,
        { targetId, ruleType }: Partial<{ targetId: string; ruleType: string }>,
      ) => {
        if (!targetId || !ruleType) {
          return;
        }

        if (!instanceIds.includes(targetId)) {
          return;
        }

        if (acc[targetId][ruleType] !== undefined) {
          acc[targetId][ruleType]++;
        } else {
          acc[targetId].Other++;
        }

        acc[targetId].total++;
      };

      state.alertList.reduce((acc, { ruleCriteria, ruleType, target, child }) => {
        if (ruleCriteria === CHECK_BY_COMBINATION) {
          const targetIds = (child || [])
            .map((childAlert) => childAlert.target.map(({ targetId }) => targetId))
            .flat();
          new Set(targetIds).forEach((targetId) =>
            aggregator(acc, {
              targetId,
              ruleType,
            }),
          );
          return acc;
        }

        (target || []).forEach(({ targetId }) => {
          aggregator(acc, { targetId, ruleType });
        });
        return acc;
      }, alertTypeCountByInstanceId);

      return Object.values(alertTypeCountByInstanceId).sort((a: any, b: any) => b.total - a.total);
    },
    getTopRankMetric: (state: State) => state.topRankMetric,
    getErrorStatusText: (state: State): State['errorStatusText'] => state.errorStatusText,
  },
};
