import {
  BY_STAT_CHART_TYPES,
  BY_TARGET_CHART_TYPES,
  DEFAULT_WIDGET_COLOR_OPTION,
  DEFAULT_WIDGET_SIZE,
  DEFAULT_WIDGET_TITLE_STYLE,
  DISPLAY_STYLE_KEY,
  MAX_LENGTH_FOR_DASHBOARD,
  WIDGET_CHART_TYPE_KEY,
  WIDGET_TYPE_KEY,
} from '@/dashboard/utils/define';
import {
  ByStatChartTypes,
  ByTargetChartTypes,
  DashboardData,
  DashboardWidgetGroupItem,
  DisplayStyleType,
  GridLayoutItem,
  LENGTH_RULE_KEY,
  ViewPackListItem,
  WidgetChartData,
  WidgetChartType,
  WidgetCreateType,
  WidgetDataType,
  WidgetSeriesType,
  WidgetTimePeriod,
  WidgetTimePeriodList,
  WidgetType,
} from '@/dashboard/utils/types';
import { camelCase, isEqual } from 'lodash-es';
import { TargetTagType, WidgetChartDataStatusInfo } from '@/common/utils/types';
import { COLOR_PALETTE_LIST, DEFAULT_ORDERED_COLOR_KEY_LIST } from '@/dashboard/utils/color.define';
import { confirmMsg, generateUUID } from '@/common/utils/commonUtils';
import { i18n } from '@/common/locale';
import { store } from '@/common/store';
import { FilterVariableOperationType } from '../stores/global-filter';
import { ReferenceInfo } from '../components/widgetSettingsWindow/referenceSettingOption/referenceSettingOption.setup';
import { ChartOptions } from '../components/widgets/widgets.types';
import type { DashboardRowData } from '../views/dashboardListView/dashboardListView.types';

export const isJsonType = (value: string): boolean => {
  try {
    const json = JSON.parse(value);
    return typeof json === 'object';
  } catch (e) {
    return false;
  }
};

export const convertSnakeToCamel = (str: string): string => camelCase(str.toLowerCase());

export const convertCamelToCapitalStart = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const getWidgetCompName = (chartType: string, displayStyle: string): string => {
  const chartTypeCapital = convertCamelToCapitalStart(convertSnakeToCamel(chartType));
  const displayStyleCapital = convertCamelToCapitalStart(convertSnakeToCamel(displayStyle));
  return `${chartTypeCapital}${displayStyleCapital}Widget`;
};

export const convertGridItemToWidgetItem = (
  {
    i,
    x,
    y,
    w,
    h,
    widgetType,
    chartType,
    displayStyle,
    timePeriod,
    chartData,
    colorTheme,
    titleOption,
    headerType,
    widgetColor,
    chartOption,
    filterOption,
    calendarTimeRange,
    customId,
    createType,
  }: GridLayoutItem,
  isGroup = false,
): DashboardWidgetGroupItem => {
  const widgetChartData =
    chartData?.map((data) => ({
      ...data,
      targets: data.targets.map((target) => ({
        ...target,
        tagValueId: target.tagValue,
      })),
      value: data.value ? JSON.stringify(data.value) : '',
    })) ?? [];
  const widgetItem: DashboardWidgetGroupItem = {
    widgetId: i,
    x,
    y,
    width: w,
    height: h,
    widgetType,
    chartType,
    displayStyle,
    period: timePeriod,
    titleOption: JSON.stringify({ ...titleOption, headerType }),
    widgetColor: JSON.stringify(widgetColor),
    chartOption: JSON.stringify(chartOption),
    filterOption: filterOption != null ? JSON.stringify(filterOption) : undefined,
    colorTheme,
    chartData: widgetChartData,
    value: calendarTimeRange ? JSON.stringify(calendarTimeRange) : '',
    customId,
    createType,
  };

  if (isGroup) {
    return {
      ...widgetItem,
      children: [],
    };
  }

  return widgetItem;
};

export const convertWidgetItemToGridItem = (item: DashboardWidgetGroupItem): GridLayoutItem => {
  const titleOption =
    item.titleOption && isJsonType(item.titleOption)
      ? JSON.parse(item.titleOption)
      : {
          showTitle: true,
          titleText: '',
          titleStyle: {
            ...DEFAULT_WIDGET_TITLE_STYLE,
          },
        };
  const widgetColor =
    item.widgetColor && isJsonType(item.widgetColor)
      ? JSON.parse(item.widgetColor)
      : {
          ...DEFAULT_WIDGET_COLOR_OPTION,
        };
  const chartOption =
    item.chartOption && isJsonType(item.chartOption) ? JSON.parse(item.chartOption) : {};
  const filterOption =
    item.filterOption && isJsonType(item.filterOption) ? JSON.parse(item.filterOption) : undefined;
  const calendarTimeRange =
    item.value && isJsonType(item.value) ? JSON.parse(item.value) : undefined;

  const widgetType = (item.widgetType as WidgetType) ?? WIDGET_TYPE_KEY.GRAPHS;
  const chartType = (item.chartType as WidgetChartType) ?? WIDGET_CHART_TYPE_KEY.TIME_SERIES;
  const displayStyle = (item.displayStyle as DisplayStyleType) ?? '';
  const widgetSize = DEFAULT_WIDGET_SIZE[chartType][displayStyle];
  const compName = getWidgetCompName(
    WIDGET_CHART_TYPE_KEY[chartType],
    DISPLAY_STYLE_KEY[displayStyle] ?? '',
  );

  const widgetChartData = item.chartData?.map((data) => ({
    ...data,
    id: data.id ?? generateUUID(),
    category: data.category ?? '',
    seriesType: data.seriesType as WidgetSeriesType,
    dataType: data.dataType as WidgetDataType,
    targets: data.targets.map((target) => ({
      ...target,
      tagType: (target.tagType as TargetTagType) ?? '',
      tagValue: target.tagValueId ?? '',
    })),
    value: data.value && isJsonType(data.value) ? JSON.parse(data.value) : '',
  }));

  return {
    i: item.widgetId,
    x: item.x!,
    y: item.y!,
    w: item.width!,
    h: item.height!,
    minW: widgetSize?.minWidth,
    minH: widgetSize?.minHeight,
    widgetType,
    chartType,
    displayStyle,
    chartData: widgetChartData,
    colorTheme: item.colorTheme ?? '',
    compName,
    timePeriod: item.period as WidgetTimePeriod,
    children: [],
    titleOption,
    headerType: titleOption?.headerType ?? 'basic',
    widgetColor,
    chartOption,
    filterOption,
    calendarTimeRange,
    customId: item.customId,
    createType: item.createType,
  };
};

export const getStringByteLength = (s: string) => {
  const textEncoder = new TextEncoder();
  return textEncoder.encode(s).length;
};

export const checkValidDashboardName = (dashboardName: string): string => {
  let errorMsg = '';

  // 이모지 및 돔태그 형태에 대한 정규표현식
  const dashboardNameRule = /[\ud800-\udbff][\udc00-\udfff]|<[^>]+>/;
  if (!dashboardName.trim()) {
    errorMsg = i18n.global.t('MESSAGE.ENTER_DASHBOARD_NAME');
  } else if (dashboardNameRule.test(dashboardName)) {
    errorMsg = 'Only letters, numbers, and special characters are allowed.';
  }

  return errorMsg;
};

export const checkValidViewPackName = (
  name: string,
  id: number,
  viewPackList: ViewPackListItem[],
): string => {
  let errorMsg = '';

  const viewPackNameRule = /([^a-zA-Z0-9\s-_ㄱ-ㅎㅏ-ㅣ가-힣])/;
  if (!name.trim()) {
    errorMsg = 'Please enter a View Pack name.';
  } else if (viewPackNameRule.test(name)) {
    errorMsg = 'Only letters, numbers, -, _ are allowed.';
  } else {
    const isDuplicatedName = viewPackList.find(
      (viewPack) => +viewPack.id !== id && viewPack.name.toLowerCase() === name.toLowerCase(),
    );

    if (isDuplicatedName) {
      errorMsg = 'It has the same View Pack name.';
    }
  }

  return errorMsg;
};

export const adjustByMaxLength = (targetString: string, ruleKey: LENGTH_RULE_KEY): string => {
  let result = targetString;

  const maxLength = MAX_LENGTH_FOR_DASHBOARD[ruleKey];

  if (result?.length) {
    while (getStringByteLength(result) > maxLength) {
      result = targetString.slice(0, result.length - 1);
    }
  }

  return result;
};

export const convertToImageURL = (
  imageSrc: string | undefined,
  type: 'dashboard' | 'viewPack' | 'widget',
): URL => {
  try {
    if (!imageSrc) {
      throw new Error('image src not exist');
    }
    return new URL(imageSrc, import.meta.url);
  } catch {
    return new URL(`/src/common/assets/widgets/${type}-default.png`, import.meta.url);
  }
};

export const isWidgetTimePeriod = (timePeriod: any): timePeriod is WidgetTimePeriod => {
  return WidgetTimePeriodList.includes(timePeriod as WidgetTimePeriod);
};

export const isByTargetChartType = (
  chartType: WidgetChartType,
): chartType is ByTargetChartTypes => {
  return BY_TARGET_CHART_TYPES.includes(chartType as ByTargetChartTypes);
};

export const isByStatChartType = (chartTypeInfo: {
  chartType: string;
  displayStyle: string;
}): chartTypeInfo is ByStatChartTypes => {
  return BY_STAT_CHART_TYPES.some((typeInfo) => isEqual(typeInfo, chartTypeInfo));
};

export const getDefaultSeriesColors = (): string[] => {
  const mergedColorPaletteList = COLOR_PALETTE_LIST.reduce((acc, obj) => {
    Object.keys(obj).forEach((key) => {
      acc[key] = obj[key];
    });
    return acc;
  }, {});

  return (
    DEFAULT_ORDERED_COLOR_KEY_LIST.map((key) => {
      const colors: { background: string; border: string }[] = mergedColorPaletteList[key];
      return colors[colors?.length - 1].background;
    }) ?? []
  );
};

export const getDefaultSeriesColor = (index: number): string => {
  const colors = getDefaultSeriesColors();

  return colors[index % colors.length];
};

export const getLimitSeriesCountByChartType = (
  chartType: WidgetChartType,
  displayStyle: DisplayStyleType,
): number | undefined => {
  if (['ONE_DAY_COMPARE', 'PIE', 'EQUALIZER', 'GAUGE'].includes(chartType)) {
    return 1;
  }
  if (chartType === 'SCOREBOARD' && displayStyle === 'STYLE_2') {
    return 2;
  }
  return undefined;
};

export const getChartDataStatusById = (
  chartDataStatusInfoList: WidgetChartDataStatusInfo[],
  chartDataId: string,
): WidgetChartDataStatusInfo => {
  const chartDataStatus = chartDataStatusInfoList.find(
    ({ chartDataId: statusChartDataId }) => statusChartDataId === chartDataId,
  );
  return chartDataStatus ?? { status: 'success', chartDataId };
};

export const getSeriesLimitDataStatusById = (
  chartDataStatusInfoList: WidgetChartDataStatusInfo[],
  chartDataId: string,
  idx: number,
  chartType: WidgetChartType,
  displayType: DisplayStyleType = '',
): WidgetChartDataStatusInfo => {
  const limitSeriesCount = getLimitSeriesCountByChartType(chartType, displayType);

  if (idx === -1 || (limitSeriesCount && idx >= limitSeriesCount)) {
    return {
      status: 'fail',
      type: 'custom',
      chartDataId,
      reason: i18n.global.t('MESSAGE.SERIES_LIMIT', { limit: limitSeriesCount }),
    };
  }

  return getChartDataStatusById(chartDataStatusInfoList, chartDataId);
};
export const initDefaultCustomColors = <Item>(
  list: Item[],
  key?: string,
): (Item & { color: string | Record<string, string> })[] => {
  return list.map((item, index) => ({
    ...item,
    color: key
      ? {
          [key]: getDefaultSeriesColor(index),
        }
      : getDefaultSeriesColor(index),
  }));
};

export const sortDashboardList =
  (
    group: string,
    indexInfo: {
      favorite: number;
      startScreen: number;
      groupId: number;
      name: number;
      shared: number;
      createUser: number;
      lastModified: number;
      featuredDashboard: number;
      featuredReport: number;
      customReport: number;
      sharePermission: number;
      creator: number;
    },
  ) =>
  (a: DashboardRowData, b: DashboardRowData): number => {
    type DashboardSortData = {
      favorite: number;
      startScreen: number;
      groupId: string;
      name: string;
      lastModified: string;
      shared: number;
      featured: number;
      customReport: number;
      sharePermission: {
        delete: boolean;
        edit: boolean;
        shared: boolean;
        shareUsers: number[];
      };
      creator: {
        name: string;
        activeId: string;
        profileImage: string;
      };
    };

    const parseRowData = (rowData: DashboardRowData): DashboardSortData => {
      return {
        favorite: +rowData[indexInfo.favorite],
        startScreen: rowData[indexInfo.startScreen] ? 2 : 0,
        groupId: String(rowData[indexInfo.groupId]),
        name: `${rowData[indexInfo.name]}`,
        lastModified: `${rowData[indexInfo.lastModified]}`,
        shared: rowData[indexInfo.shared] && !rowData[indexInfo.createUser] ? 2 : 0,
        featured: rowData[indexInfo.featuredDashboard] || rowData[indexInfo.featuredReport] ? 1 : 0,
        customReport: rowData[indexInfo.customReport] ? 1 : 0,
        sharePermission: {
          shareUsers: rowData[indexInfo.sharePermission].shareUsers,
          delete: rowData[indexInfo.sharePermission].delete,
          edit: rowData[indexInfo.sharePermission].edit,
          shared: rowData[indexInfo.sharePermission].shared,
        },
        creator: {
          name: rowData[indexInfo.creator].name,
          activeId: rowData[indexInfo.creator].activeId,
          profileImage: rowData[indexInfo.creator].profileImage,
        },
      };
    };

    const dataA: DashboardSortData = parseRowData(a);
    const dataB: DashboardSortData = parseRowData(b);

    const priorityOrder: Array<keyof DashboardSortData> = [
      'startScreen',
      'favorite',
      'featured',
      'shared',
      'customReport',
    ];

    let comparisonResult: number | undefined;

    priorityOrder.some((key) => {
      const valueA = dataA[key] as number;
      const valueB = dataB[key] as number;
      if (valueA !== valueB) {
        comparisonResult = valueB - valueA;
        return true;
      }
      return false;
    });

    if (comparisonResult !== undefined) {
      return comparisonResult;
    }

    return dataA.name.localeCompare(dataB.name);
  };

export const uploadJsonFile = async (e, ctx): Promise<string[]> => {
  e.stopPropagation();
  e.preventDefault();

  const files: FileList = e.target ? e.target.files : null;

  if (!files.length) {
    return [];
  }

  const isAllJsonExtension = Array.from(files).every(
    (file) => file.name.split('.')[1]?.toLowerCase() === 'json',
  );

  if (!isAllJsonExtension) {
    confirmMsg(ctx, {
      msgStr: 'Only json files are allowed.',
      okCallback: () => {},
      useHTML: false,
      showClose: false,
      showCancelBtn: false,
    });
    return [];
  }

  const readFilePromise = (file: File): Promise<string> => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const jsonData = event.target?.result as string;
        resolve(jsonData);
      };

      reader.readAsText(file);
    });
  };

  const readFiles = Array.from(files).map((file) => readFilePromise(file));
  return Promise.all(readFiles).then((jsonFiles) => jsonFiles);
};

export const encodeArgParam = (
  value: string | boolean | number | (string | number)[] | null | undefined,
): string | undefined => {
  if (value == null) return undefined;
  switch (typeof value) {
    case 'string':
      return `'${value}'`;
    case 'number':
    case 'boolean':
      return `${value}`;
    case 'object':
      if (Array.isArray(value)) {
        return value.map(encodeArgParam).join(', ');
      }
      break;
    default:
      return `${value}`;
  }
  return `${value}`;
};

export const decodeArgParam = (value: string | undefined): string | undefined => {
  if (value == null) return undefined;
  return value.replaceAll("'", '').trim();
};

export const disabledUseCustomStat = (chartType: WidgetChartType) => {
  return ['ONE_DAY_COMPARE', 'ACTION_VIEW', 'GAUGE'].includes(chartType);
};

export const filterChartDataByCreateType = (
  chartType: WidgetChartType,
  createType: WidgetCreateType,
  statCategory: string,
) => {
  const isChartCanUseCustomStat = !disabledUseCustomStat(chartType);
  return (
    (isChartCanUseCustomStat && createType === 'reference') ||
    ((!isChartCanUseCustomStat || createType === 'base') && statCategory !== 'custom')
  );
};

export const isStatClear = (value: WidgetChartData) => {
  return !value.dataId && !value.category;
};

export const isCustomStatId = (statId: string) => {
  return statId.startsWith('qm_');
};

export const isChartOptionDisabled = (
  option: 'fill' | 'target' | 'dataType' | 'seriesType' | 'alias',
  statInfo: {
    id: string;
    isReference: boolean;
  },
  defaultDisabled: boolean,
) => {
  if (statInfo.isReference) {
    const isCustomStat = isCustomStatId(statInfo.id);
    switch (option) {
      case 'fill':
        return !!isCustomStat;
      case 'target':
        return true;
      case 'dataType':
        return !!isCustomStat;
      case 'seriesType':
        return false;
      case 'alias':
        return false;
      default:
        return false;
    }
  }
  return defaultDisabled;
};

export const isWidgetReferenceGlobalTimePeriod = <T extends WidgetChartType>(
  chartOption: ChartOptions<T>,
): boolean => {
  return (
    !!chartOption &&
    !!chartOption.referenceInfo &&
    Object.values(chartOption.referenceInfo as ReferenceInfo).some((variableList) =>
      variableList.some(({ value }) => value?.type === 'globalTimePeriod'),
    )
  );
};

export const isGridItemNotUseTimePeriod = ({
  widgetType,
  createType,
  timePeriod,
  chartOption,
}: GridLayoutItem) => {
  return (
    ['TEXT', 'IMAGE', 'GROUP', 'FILTER'].includes(widgetType) ||
    (createType === 'reference' && !isWidgetReferenceGlobalTimePeriod(chartOption)) ||
    timePeriod !== 'globalTime'
  );
};

export const isUserHasEditPermission = (sharePermission: DashboardData['sharePermission']) => {
  const myUserId = store.getters['myInfo/getAccountInfo']?.userId;
  return !!sharePermission.shareUsers?.includes(myUserId) && sharePermission.edit;
};

export const isUserHasDeletePermission = (sharePermission: DashboardData['sharePermission']) => {
  const myUserId = store.getters['myInfo/getAccountInfo']?.userId;
  return !!sharePermission.shareUsers?.includes(myUserId) && sharePermission.delete;
};

export const isUserHasResharePermission = (sharePermission: DashboardData['sharePermission']) => {
  const myUserId = store.getters['myInfo/getAccountInfo']?.userId;
  return !!sharePermission.shareUsers?.includes(myUserId) && sharePermission.shared;
};

export const convertToOperationType = (
  tagType: TargetTagType | string,
): FilterVariableOperationType => {
  switch (tagType) {
    case 'attribute':
      return 'AND';
    case 'tag':
    case 'globalVariable':
    case 'serviceGroup':
      return 'OR';
    default:
      throw new Error(`${tagType} 타겟태그타입이 추가되었습니다.`);
  }
};
