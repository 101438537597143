<script setup lang="ts">
import { RouterLink } from 'vue-router';
import TagItem from '@/common/components/molecules/tag/TagItem.vue';
import { setup, type Props, type Emit } from './breadCrumbElementLink.setup';

const props = withDefaults(defineProps<Props>(), {
  mode: 'common',
});

const emit = defineEmits<Emit>();

const {
  isCurrent,
  isMultiViewExpanded,
  isInstanceClickBlocked,
  onLinkClick,
  onInstanceClick,
  onInstanceMultiViewExpand,
  onFavoriteClick,
} = setup(props, emit);
</script>

<template>
  <section class="link-wrapper">
    <!-- common & dashboard -->
    <template v-if="props.mode !== 'instance'">
      <!-- 즐겨찾기 버튼 -->
      <button
        v-if="props.mode === 'dashboard'"
        type="button"
        class="bread-crumb-element-link__favorite"
        @click="onFavoriteClick"
      >
        <ev-icon
          size="small"
          :icon="props.menu?.favoriteId ? 'icon-fill-star' : 'icon-star'"
        />
      </button>

      <router-link
        :to="`${props.menu.path || props.menu.subMenuList?.[0].children?.[0]?.path}`"
        :title="props.menu.text"
        :class="['bread-crumb-element-link', { 'bread-crumb-element-link--active': isCurrent }]"
        active-class="bread-crumb-element-link--active"
        @click="onLinkClick"
      >
        {{ props.menu.text }}
      </router-link>
    </template>

    <!-- instance -->
    <template v-if="props.mode === 'instance'">
      <button
        type="button"
        :title="props.menu.text"
        :class="[
          'bread-crumb-element-link',
          {
            'bread-crumb-element-link--active': isCurrent,
            'bread-crumb-element-link--blocked': isInstanceClickBlocked,
          },
        ]"
        @click="onInstanceClick"
      >
        <span class="bread-crumb-element-link__text">{{ props.menu.text }}</span>
        <tag-item
          size="xsmall"
          html-type="span"
        >
          {{ props.menu.instanceType === 'multiView' ? 'multi' : 'single' }}
        </tag-item>
      </button>
      <ev-button
        v-if="props.menu.instanceType === 'multiView' && props.menu.children?.length"
        type="icon"
        size="xsmall"
        shape="border"
        :class="['bread-crumb-element-link__arrow', { selected: isMultiViewExpanded }]"
        @click="onInstanceMultiViewExpand"
      >
        <ev-icon icon="icon-fill-arrow-right" />
      </ev-button>
    </template>
  </section>
</template>

<style scoped lang="scss">
.bread-crumb-element-link {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 320px;
  padding: 6.5px 40px 6.5px 12px;
  overflow: hidden;
  cursor: pointer;
  background-color: transparent;
  font-size: 13px;
  line-height: 15px;
  color: var(--app-header-breadcrumb-font-color);
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;

  &__favorite {
    position: absolute;
    top: 50%;
    left: 12px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    border-radius: 2px;
    background-color: transparent;
    transform: translateY(-50%);

    &:hover {
      background-color: var(--icon-button-borderless-hover-bg-color);
    }

    .icon-fill-star {
      background-color: var(--icon-star-favorite-color);
    }

    + .bread-crumb-element-link {
      padding-left: 32px;
    }
  }

  &:hover:not(&--active) {
    background-color: var(--app-header-breadcrumb-hover-bg-color);
  }

  &--active {
    background-color: var(--app-header-breadcrumb-active-bg-color);
  }

  &--blocked {
    cursor: not-allowed;
  }

  &__text {
    margin-right: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    right: 6px;
    transform: translateY(-50%);
  }
}
.link-wrapper {
  position: relative;
}
</style>
