import { ref } from 'vue';
import { defineStore } from 'pinia';
import { AddTabPanelInfo, useSlideDetailStore } from '@/common/stores/slide-detail';

export const usePaSlideDetailStore = defineStore('paSlideDetail', () => {
  // NOTE: 개선 필요
  const isSlideDetailOpened = ref<boolean>(false);
  const { addTabPanel, setOpened } = useSlideDetailStore();

  const openSlideDetail = (panelInfo: AddTabPanelInfo) => {
    addTabPanel({
      ...panelInfo,
      init: true,
    });

    isSlideDetailOpened.value = true;
  };

  const closeSlideDetail = () => {
    isSlideDetailOpened.value = false;
    setOpened(false);
  };
  return { isSlideDetailOpened, openSlideDetail, closeSlideDetail };
});
