import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { getAPIErrorStatusText, roundToDigitNumber } from '@/common/utils/commonUtils';
import { CurrentItem, MetricResponse, MetricResponseItem } from '@/openapi/data/model';
import { CurrentRequest, MetricRequest } from '@/common/utils/types';
import { MetricFailError } from '@/database/utils/utils';
import { getCurrentDataV7ControllerAxios } from '@/openapi/data/api/data-v7-controller-api';

import { getMetricData } from '@/common/api/data';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { getCurrentByServiceAlertCommonControllerAxios } from '@/openapi/alert/api/alert-common-controller-api';
import { AlertLogsChild, AlertLogsItem } from '@/openapi/alert/model';
import { isNumber, lowerCase } from 'lodash-es';
import { CHECK_BY_COMBINATION } from '@/alert/utils/define';

interface TopRankRes {
  instanceId: string;
  instanceName: string;
  value: number;
  // DB Type, DB Version에 따라 지원이 안되는 경우가 있음
  isSupported?: boolean; // Mysql > Global Temp Usage, Session Temp Usage 지표에 적용.
}

interface RankMetricErrorStatus {
  ageUsedRatio: string;
  tempUsage: string;
  filesystemUsage: string;
  cacheHitRatio: string;
  trxTime: string;
  alert: string;
  // metric 차트 오류 표시할 때 사용
  db_postgresql_ageusedratio: string;
  db_postgresql_tempusage: string;
  db_postgresql_trxtime: string;
  db_postgresql_filesystemusage: string;
  db_postgresql_cachehitratio: string;
}
interface State {
  topVacuumUsage: TopRankRes[];
  topTempUsage: TopRankRes[];
  topFilesystemUsage: TopRankRes[];
  topQueryIo: TopRankRes[];
  topTransactionTime: TopRankRes[];
  alertList: AlertLogsItem[];
  topRankMetric: MetricResponseItem[];
  vacuumUsageSeries: MetricResponseItem[];
  tempUsageSeries: MetricResponseItem[];
  filesystemUsageSeries: MetricResponseItem[];
  queryIoSeries: MetricResponseItem[];
  transactionTimeSeries: MetricResponseItem[];
  errorStatus: RankMetricErrorStatus;
}

interface AlertInfo {
  warning: number;
  critical: number;
  instanceId?: string;
  instanceName?: string;
}

interface AlertTypeInfo {
  total: number;
  Other?: number;
  instanceId?: string;
  instanceName?: string;
}

const FRAME_NAME = FRAME_NAMES.POSTGRESQL_MULTI_VIEW.RANK;

const mapDataByInstance = (data: CurrentItem[] = []) => {
  return (data || []).reduce((acc, { targetId, value }) => {
    if (!targetId) {
      return acc;
    }

    acc[targetId] = value;
    return acc;
  }, {});
};

// 요청의 dataId 순서와 응답의 dataId 순서가 동일하는 가정
const mapToErrorStatus = ({
  dataIds,
  response,
}: {
  dataIds: string[];
  response: MetricResponse[];
}) => {
  return dataIds.map((dataId, idx) => ({
    errorMetricName: dataId,
    statusText: response[idx]?.status === 'fail' ? response[idx].reason : '',
  }));
};

const sortRank = (sortType: 'ASC' | 'DESC') => {
  const sortFunc = {
    ASC: (val1: number, val2: number) => (val1 < val2 ? -1 : 1),
    DESC: (val1: number, val2: number) => (val1 > val2 ? -1 : 1),
  };

  return (val1: number | null, val2: number | null) => {
    if (!isNumber(val1)) {
      return 1;
    }
    if (!isNumber(val2)) {
      return -1;
    }

    return sortFunc[sortType](val1, val2);
  };
};

export const rankMetric: Module<State, RootState> = {
  namespaced: true,
  state: {
    topVacuumUsage: [],
    topTempUsage: [],
    topFilesystemUsage: [],
    topQueryIo: [],
    topTransactionTime: [],
    alertList: [],
    topRankMetric: [],
    vacuumUsageSeries: [],
    tempUsageSeries: [],
    filesystemUsageSeries: [],
    queryIoSeries: [],
    transactionTimeSeries: [],
    errorStatus: {} as RankMetricErrorStatus,
  },
  mutations: {
    setVacuumUsage: (state: State, data: TopRankRes[]) => {
      state.topVacuumUsage = data;
    },
    setTempUsage: (state: State, data: TopRankRes[]) => {
      state.topTempUsage = data;
    },
    setFilesystemUsage: (state: State, data: TopRankRes[]) => {
      state.topFilesystemUsage = data;
    },
    setQueryIo: (state: State, data: TopRankRes[]) => {
      state.topQueryIo = data;
    },
    setTransactionTime: (state: State, data: TopRankRes[]) => {
      state.topTransactionTime = data;
    },
    setAlertList: (state: State, data: AlertLogsItem[]) => {
      state.alertList = data;
    },
    setTopRankMetric: (state: State, data: MetricResponseItem[]) => {
      state.topRankMetric = data;
    },
    setVacuumUsageSeries: (state: State, data: MetricResponseItem[]) => {
      state.vacuumUsageSeries = data;
    },
    setTempUsageSeries: (state: State, data: MetricResponseItem[]) => {
      state.tempUsageSeries = data;
    },
    setFilesystemUsageSeries: (state: State, data: MetricResponseItem[]) => {
      state.filesystemUsageSeries = data;
    },
    setQueryIoSeries: (state: State, data: MetricResponseItem[]) => {
      state.queryIoSeries = data;
    },
    setTransactionTimeSeries: (state: State, data: MetricResponseItem[]) => {
      state.transactionTimeSeries = data;
    },
    setErrorStatus: (
      state: State,
      { statusText, errorMetricName }: { statusText: string; errorMetricName: string },
    ) => {
      state.errorStatus[errorMetricName] = statusText;
    },
  },
  actions: {
    fetchTopVacuumUsage: async ({ commit, rootGetters }, instanceIds) => {
      try {
        const params: CurrentRequest[] = [
          {
            aggregationType: 'byTarget',
            category: 'postgresql',
            dataId: 'db_postgresql_ageusedratio',
            targetIds: instanceIds,
            summaryType: 'current',
            period: 'p1m',
          },
        ];
        const { data } = await getCurrentDataV7ControllerAxios({
          currentV7Requests: params,
          isTimeout: true,
          frameName: FRAME_NAMES.POSTGRESQL_MULTI_VIEW.RANK_VACUUM_USAGE,
        });

        const { current, status, reason } = data?.data?.[0] || {};
        if (status === 'fail') {
          throw new MetricFailError({ reason });
        }

        const dataByInstance = mapDataByInstance(current);

        const sortDesc = sortRank('DESC');
        const vacuumUsageData = instanceIds
          .map((instanceId) => {
            const value = dataByInstance[instanceId];
            return {
              instanceId,
              instanceName: rootGetters['postgresqlMultiViewEnv/getInstanceById'](instanceId)?.name,
              value: !value && value !== 0 ? value : roundToDigitNumber(value, 1),
            };
          })
          .sort(({ value: val1 }, { value: val2 }) => sortDesc(val1, val2));

        commit('setVacuumUsage', vacuumUsageData); // 소수점 1자리 퍼센트
        commit('setErrorStatus', { statusText: '', errorMetricName: 'ageUsedRatio' });
      } catch (e: any) {
        const statusText =
          e instanceof MetricFailError ? e.getErrorStatusText() : getAPIErrorStatusText(e);
        commit('setErrorStatus', { statusText, errorMetricName: 'ageUsedRatio' });
        commit(
          'postgresqlMultiViewEnv/setFramesByFailedApi',
          { frameName: FRAME_NAME, statusText },
          { root: true },
        );
      }
    },
    fetchTopTempUsage: async ({ commit, rootGetters }, instanceIds) => {
      try {
        const params: CurrentRequest[] = [
          {
            aggregationType: 'byTarget',
            category: 'postgresql',
            dataId: 'db_postgresql_tempusage',
            targetIds: instanceIds,
            summaryType: 'current',
            period: 'p1m',
          },
        ];
        const { data } = await getCurrentDataV7ControllerAxios({
          currentV7Requests: params,
          isTimeout: true,
          frameName: FRAME_NAMES.POSTGRESQL_MULTI_VIEW.RANK_TEMP_USAGE,
        });

        const { current, status, reason } = data?.data?.[0] || {};
        if (status === 'fail') {
          throw new MetricFailError({ reason });
        }

        const dataByInstance = mapDataByInstance(current);

        const sortDesc = sortRank('DESC');
        const tempUsageData = instanceIds
          .map((instanceId) => ({
            instanceId,
            instanceName: rootGetters['postgresqlMultiViewEnv/getInstanceById'](instanceId)?.name,
            value: dataByInstance[instanceId],
          }))
          .sort(({ value: val1 }, { value: val2 }) => sortDesc(val1, val2));

        commit('setTempUsage', tempUsageData); // SIZE : API에서 Byte로 옴
        commit('setErrorStatus', { statusText: '', errorMetricName: 'tempUsage' });
      } catch (e: any) {
        const statusText =
          e instanceof MetricFailError ? e.getErrorStatusText() : getAPIErrorStatusText(e);
        commit('setErrorStatus', { statusText, errorMetricName: 'tempUsage' });
        commit(
          'postgresqlMultiViewEnv/setFramesByFailedApi',
          { frameName: FRAME_NAME, statusText },
          { root: true },
        );
      }
    },
    fetchTopFilesystemUsage: async ({ commit, rootGetters }, instanceIds) => {
      try {
        const params: CurrentRequest[] = [
          {
            aggregationType: 'byTarget',
            category: 'postgresql',
            dataId: 'db_postgresql_filesystemusage',
            targetIds: instanceIds,
            summaryType: 'current',
            period: 'p1m',
          },
        ];
        const { data } = await getCurrentDataV7ControllerAxios({
          currentV7Requests: params,
          isTimeout: true,
          frameName: FRAME_NAMES.POSTGRESQL_MULTI_VIEW.RANK_FILESYSTEM_USAGE,
        });

        const { current, status, reason } = data?.data?.[0] || {};
        if (status === 'fail') {
          throw new MetricFailError({ reason });
        }

        const dataByInstance = mapDataByInstance(current);

        const sortDesc = sortRank('DESC');
        const filesystemUsageData = instanceIds
          .map((instanceId) => ({
            instanceId,
            instanceName: rootGetters['postgresqlMultiViewEnv/getInstanceById'](instanceId)?.name,
            value: dataByInstance[instanceId],
          }))
          .sort(({ value: val1 }, { value: val2 }) => sortDesc(val1, val2));

        commit('setFilesystemUsage', filesystemUsageData); // SIZE : API에서 Byte로 옴
        commit('setErrorStatus', { statusText: '', errorMetricName: 'filesystemUsage' });
      } catch (e: any) {
        const statusText =
          e instanceof MetricFailError ? e.getErrorStatusText() : getAPIErrorStatusText(e);
        commit('setErrorStatus', { statusText, errorMetricName: 'filesystemUsage' });
        commit(
          'postgresqlMultiViewEnv/setFramesByFailedApi',
          { frameName: FRAME_NAME, statusText },
          { root: true },
        );
      }
    },
    fetchTopQueryIo: async ({ commit, rootGetters }, instanceIds) => {
      try {
        const params: CurrentRequest[] = [
          {
            aggregationType: 'byTarget',
            category: 'postgresql',
            dataId: 'db_postgresql_cachehitratio',
            targetIds: instanceIds,
            summaryType: 'current',
            period: 'p1m',
          },
        ];
        const { data } = await getCurrentDataV7ControllerAxios({
          currentV7Requests: params,
          isTimeout: true,
          frameName: FRAME_NAMES.POSTGRESQL_MULTI_VIEW.RANK_QUERY_IO,
        });

        const { current, status, reason } = data?.data?.[0] || {};
        if (status === 'fail') {
          throw new MetricFailError({ reason });
        }

        const dataByInstance = mapDataByInstance(current);

        const sortAsc = sortRank('ASC');
        const queryIoData = instanceIds
          .map((instanceId) => {
            const value = dataByInstance[instanceId];
            return {
              instanceId,
              instanceName: rootGetters['postgresqlMultiViewEnv/getInstanceById'](instanceId)?.name,
              value: !value && value !== 0 ? value : roundToDigitNumber(value, 1),
            };
          })
          .sort(({ value: val1 }, { value: val2 }) => sortAsc(val1, val2));

        commit('setQueryIo', queryIoData); // 소수점 1자리 퍼센트
        commit('setErrorStatus', { statusText: '', errorMetricName: 'cacheHitRatio' });
      } catch (e: any) {
        const statusText =
          e instanceof MetricFailError ? e.getErrorStatusText() : getAPIErrorStatusText(e);
        commit('setErrorStatus', { statusText, errorMetricName: 'cacheHitRatio' });
        commit(
          'postgresqlMultiViewEnv/setFramesByFailedApi',
          { frameName: FRAME_NAME, statusText },
          { root: true },
        );
      }
    },
    fetchTopTransactionTime: async ({ commit, rootGetters }, instanceIds) => {
      try {
        const params: CurrentRequest[] = [
          {
            aggregationType: 'byTarget',
            category: 'postgresql',
            dataId: 'db_postgresql_trxtime',
            targetIds: instanceIds,
            summaryType: 'current',
            period: 'p1m',
          },
        ];
        const { data } = await getCurrentDataV7ControllerAxios({
          currentV7Requests: params,
          isTimeout: true,
          frameName: FRAME_NAMES.POSTGRESQL_MULTI_VIEW.RANK_TRANSACTION_TIME,
        });

        const { current, status, reason } = data?.data?.[0] || {};
        if (status === 'fail') {
          throw new MetricFailError({ reason });
        }

        const dataByInstance = mapDataByInstance(current);

        const sortDesc = sortRank('DESC');
        const transactionTimeData = instanceIds
          .map((instanceId) => {
            const value = dataByInstance[instanceId];
            return {
              instanceId,
              instanceName: rootGetters['postgresqlMultiViewEnv/getInstanceById'](instanceId)?.name,
              value: !value && value !== 0 ? value : roundToDigitNumber(value, 1),
            };
          })
          .sort(({ value: val1 }, { value: val2 }) => sortDesc(val1, val2));

        commit('setTransactionTime', transactionTimeData); // ms
        commit('setErrorStatus', { statusText: '', errorMetricName: 'trxTime' });
      } catch (e: any) {
        const statusText =
          e instanceof MetricFailError ? e.getErrorStatusText() : getAPIErrorStatusText(e);
        commit('setErrorStatus', { statusText, errorMetricName: 'trxTime' });
        commit(
          'postgresqlMultiViewEnv/setFramesByFailedApi',
          { frameName: FRAME_NAME, statusText },
          { root: true },
        );
      }
    },
    fetchAlertList: async ({ commit }, instanceIds) => {
      try {
        const { data } = await getCurrentByServiceAlertCommonControllerAxios({
          targetIds: instanceIds,
          targetKind: 'database',
          frameName: FRAME_NAMES.POSTGRESQL_MULTI_VIEW.RANK_ALERT,
        });
        commit('setAlertList', data?.data ?? []);
        commit('setErrorStatus', { statusText: '', errorMetricName: 'alert' });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatus', { statusText, errorMetricName: 'alert' });
        commit(
          'postgresqlMultiViewEnv/setFramesByFailedApi',
          { frameName: FRAME_NAME, statusText },
          { root: true },
        );
      }
    },
    fetchTopRankMetric: async ({ commit }, instanceIds) => {
      const ONE_MIN_METRIC_NAMES = [
        'db_postgresql_ageusedratio',
        'db_postgresql_tempusage',
        'db_postgresql_trxtime',
      ];
      const TEN_MIN_METRIC_NAMES = ['db_postgresql_filesystemusage', 'db_postgresql_cachehitratio'];

      try {
        const intervalOneMinRequests = ONE_MIN_METRIC_NAMES.map<MetricRequest>((name) => ({
          aggregationType: 'byTarget',
          period: 'p1h',
          interval: 'I1m',
          category: 'postgresql',
          dataId: name,
          summaryType: 'current',
          targetIds: instanceIds,
        }));

        const intervalTenMinRequests = TEN_MIN_METRIC_NAMES.map<MetricRequest>((name) => ({
          aggregationType: 'byTarget',
          period: 'p1h',
          interval: 'I1m',
          category: 'postgresql',
          dataId: name,
          summaryType: 'current',
          targetIds: instanceIds,
        }));

        const { data: intervalOneMinRes } = await getMetricData({
          metricV7Requests: intervalOneMinRequests,
          frameName: FRAME_NAMES.POSTGRESQL_MULTI_VIEW.RANK_TOP_METRIC_1MIN,
        });
        const { data: intervalTenMinRes } = await getMetricData({
          metricV7Requests: intervalTenMinRequests,
          frameName: FRAME_NAMES.POSTGRESQL_MULTI_VIEW.RANK_TOP_METRIC_10MIN,
        });

        const intervalOneMinData = intervalOneMinRes?.data ?? [];
        const intervalTenMinData = intervalTenMinRes?.data ?? [];

        const allRankMetricTimeSeriesData = [...intervalOneMinData, ...intervalTenMinData];
        commit('setTopRankMetric', allRankMetricTimeSeriesData);

        const allRankMetricError = [
          ...mapToErrorStatus({
            dataIds: ONE_MIN_METRIC_NAMES,
            response: intervalOneMinData,
          }),
          ...mapToErrorStatus({
            dataIds: TEN_MIN_METRIC_NAMES,
            response: intervalTenMinData,
          }),
        ];
        allRankMetricError.forEach((errorStatus) => {
          commit('setErrorStatus', errorStatus);
        });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        [...ONE_MIN_METRIC_NAMES, ...TEN_MIN_METRIC_NAMES].forEach((dataId) => {
          commit('setErrorStatus', { statusText, errorMetricName: dataId });
        });
        commit(
          'postgresqlMultiViewEnv/setFramesByFailedApi',
          { frameName: FRAME_NAME, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getVacuumUsage: (state: State) => state.topVacuumUsage,
    getTempUsage: (state: State) => state.topTempUsage,
    getFilesystemUsage: (state: State) => state.topFilesystemUsage,
    getQueryIo: (state: State) => state.topQueryIo,
    getTransactionTime: (state: State) => state.topTransactionTime,
    getAlertList:
      (state: State, getters, rootState, rootGetters) =>
      ({
        selectedInstanceId,
        isValidData = false,
      }: {
        selectedInstanceId?: string;
        isValidData?: boolean;
      }) => {
        const instanceIds = rootGetters['postgresqlMultiViewEnv/getInstanceIds'];

        const filteredAlerts = state.alertList.reduce((filtered, alert) => {
          if (alert.ruleCriteria === CHECK_BY_COMBINATION) {
            const filteredChildren = (alert?.child || []).reduce((children, child) => {
              const filteredChildTargets = (child?.target || []).filter((target) => {
                if (!instanceIds.includes(target.targetId)) {
                  return false;
                }

                if (!selectedInstanceId) {
                  return true;
                }

                return target.targetId === selectedInstanceId;
              });

              if (filteredChildTargets.length) {
                children.push({
                  ...child,
                  target: filteredChildTargets,
                });
              }

              return children;
            }, [] as AlertLogsChild[]);

            if (filteredChildren.length) {
              filtered.push({ ...alert, child: filteredChildren });
            }

            return filtered;
          }

          if (!selectedInstanceId) {
            filtered.push(alert);
            return filtered;
          }

          const targetId = alert.target?.[0]?.targetId;
          if (targetId === selectedInstanceId) {
            filtered.push(alert);
          }

          return filtered;
        }, [] as AlertLogsItem[]);

        return filteredAlerts.filter((alert) =>
          isValidData ? alert.ruleName && alert.ruleType : alert,
        );
      },
    getTotalAlertCount:
      (state: State, getters) =>
      ({ selectedInstanceId }: { selectedInstanceId?: string }): AlertInfo =>
        getters.getAlertList({ selectedInstanceId }).reduce(
          (acc, { lastAlert }) => {
            const lowerLastAlert = lowerCase(lastAlert);
            if (lowerLastAlert === 'warning') {
              acc.warning++;
            } else if (lowerLastAlert === 'critical') {
              acc.critical++;
            }
            return acc;
          },
          { critical: 0, warning: 0 },
        ),
    getAlertCountByInstanceId: (state: State, getters, rootState, rootGetters): AlertInfo[] => {
      const instances = rootGetters['postgresqlMultiViewEnv/getInstances'].reduce(
        (acc, { instanceId, name }) => {
          acc[instanceId] = { name };
          return acc;
        },
        {} as Record<string, string>,
      );
      const instanceIds = Object.keys(instances);

      const alertCountByInstanceId = instanceIds.reduce(
        (acc, instanceId) => {
          acc[instanceId] = {
            warning: 0,
            critical: 0,
            instanceName: instances[instanceId].name,
            instanceId,
          };
          return acc;
        },
        {} as Record<string, AlertInfo>,
      );

      const aggregator = (
        acc: Record<string, AlertInfo>,
        { targetId, lastAlert }: Partial<{ targetId: string; lastAlert: string }>,
      ) => {
        if (!targetId || !lastAlert) {
          return;
        }

        if (!instanceIds.includes(targetId)) {
          return;
        }

        const lowerLastAlert = lowerCase(lastAlert);
        if (!['critical', 'warning'].includes(lowerLastAlert)) {
          return;
        }

        acc[targetId][lowerLastAlert]++;
      };

      state.alertList.reduce((acc, { ruleCriteria, lastAlert, target, child }) => {
        if (ruleCriteria === CHECK_BY_COMBINATION) {
          const targetIds = (child || [])
            .map((childAlert) => childAlert.target.map(({ targetId }) => targetId))
            .flat();
          new Set(targetIds).forEach((targetId) =>
            aggregator(acc, {
              targetId,
              lastAlert,
            }),
          );
          return acc;
        }

        (target || []).forEach(({ targetId }) => {
          aggregator(acc, { targetId, lastAlert });
        });
        return acc;
      }, alertCountByInstanceId);

      return Object.values(alertCountByInstanceId).sort((a: AlertInfo, b: AlertInfo) => {
        const aTotal = a.warning + a.critical;
        const bTotal = b.warning + b.critical;

        if (aTotal === bTotal) {
          return b.critical - a.critical;
        }

        return bTotal - aTotal;
      });
    },
    getAlertTypeByInstanceId: (state: State, getters, rootState, rootGetters) => {
      const instances = rootGetters['postgresqlMultiViewEnv/getInstances'].reduce(
        (acc, { instanceId, name }) => {
          acc[instanceId] = { name };
          return acc;
        },
        {} as Record<string, string>,
      );
      const instanceIds = Object.keys(instances);

      const alertCountByTypes = state.alertList.reduce(
        (acc, { ruleType }) => {
          if (!ruleType) {
            return acc;
          }

          if (!acc[ruleType]) {
            acc[ruleType] = 0;
          }
          acc[ruleType]++;

          return acc;
        },
        {} as Record<string, number>,
      );

      const TOP_LIMIT = 10;
      const topAlertNames = Object.entries(alertCountByTypes)
        .sort(([, nextVal], [, curVal]) => curVal - nextVal)
        .slice(0, TOP_LIMIT)
        .map(([alertType, _]) => [alertType, 0]);

      const alertTypeCountByInstanceId = instanceIds.reduce(
        (acc, instanceId) => {
          acc[instanceId] = {
            total: 0,
            ...Object.fromEntries(topAlertNames),
            instanceName: instances[instanceId].name,
            instanceId,
          };

          if (topAlertNames.length > TOP_LIMIT) {
            acc[instanceId].Other = 0;
          }

          return acc;
        },
        {} as Record<string, AlertTypeInfo>,
      );

      const aggregator = (
        acc: Record<string, AlertTypeInfo>,
        { targetId, ruleType }: Partial<{ targetId: string; ruleType: string }>,
      ) => {
        if (!targetId || !ruleType) {
          return;
        }

        if (!instanceIds.includes(targetId)) {
          return;
        }

        if (acc[targetId][ruleType] !== undefined) {
          acc[targetId][ruleType]++;
        } else {
          acc[targetId].Other++;
        }

        acc[targetId].total++;
      };

      state.alertList.reduce((acc, { ruleCriteria, ruleType, target, child }) => {
        if (ruleCriteria === CHECK_BY_COMBINATION) {
          const targetIds = (child || [])
            .map((childAlert) => childAlert.target.map(({ targetId }) => targetId))
            .flat();
          new Set(targetIds).forEach((targetId) =>
            aggregator(acc, {
              targetId,
              ruleType,
            }),
          );
          return acc;
        }

        (target || []).forEach(({ targetId }) => {
          aggregator(acc, { targetId, ruleType });
        });
        return acc;
      }, alertTypeCountByInstanceId);

      return Object.values(alertTypeCountByInstanceId).sort((a: any, b: any) => b.total - a.total);
    },
    getTopRankMetric: (state: State) => state.topRankMetric,
    getVacuumUsageSeries: (state: State) => state.vacuumUsageSeries,
    getErrorStatus: (state: State) => state.errorStatus,
  },
};
