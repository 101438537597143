export const CONFIG = {
  ACCOUNT: {
    GRIDCOLUMNS_config_myInfo_Tokens: 'GRIDCOLUMNS_config_myInfo_Tokens',
    GRIDCOLUMNS_config_userUserGroup_userList: 'GRIDCOLUMNS_config_userUserGroup_userList',
    GRIDCOLUMNS_config_permission_targetList: 'GRIDCOLUMNS_config_permission_targetList',
    GRIDCOLUMNS_config_permission_targetUserGroup: 'GRIDCOLUMNS_config_permission_targetUserGroup',
    GRIDCOLUMNS_config_permission_rolePermissionList:
      'GRIDCOLUMNS_config_permission_rolePermissionList',
  },
  PLATFORM: {
    GRIDCOLUMNS_config_infrastructure_hostList: 'GRIDCOLUMNS_config_infrastructure_hostList',
    GRIDCOLUMNS_config_infrastructure_hostListDetail_configList:
      'GRIDCOLUMNS_config_infrastructure_hostListDetail_configList',
    GRIDCOLUMNS_config_infrastructure_hostListDetail_configSetting:
      'GRIDCOLUMNS_config_infrastructure_hostListDetail_configSetting',
    GRIDCOLUMNS_config_infrastructure_networkDevice_deviceList:
      'GRIDCOLUMNS_config_infrastructure_networkDevice_deviceList',
    GRIDCOLUMNS_config_infrastructure_oidPackManager_oidList:
      'GRIDCOLUMNS_config_infrastructure_oidPackManager_oidList',
    GRIDCOLUMNS_config_infrastructure_oidPackManagerAddGroup_oidList:
      'GRIDCOLUMNS_config_infrastructure_oidPackManagerAddGroup_oidList',
    GRIDCOLUMNS_config_infrastructure_messageQueue_messageQueueList:
      'GRIDCOLUMNS_config_infrastructure_messageQueue_messageQueueList',
    GRIDCOLUMNS_config_cloud_instanceList: 'GRIDCOLUMNS_config_cloud_instanceList',
    GRIDCOLUMNS_config_cloud_collectorList: 'GRIDCOLUMNS_config_cloud_collectorList',
    GRIDCOLUMNS_config_cloud_collectorDetail_configList:
      'GRIDCOLUMNS_config_cloud_collectorDetail_configList',
    GRIDCOLUMNS_config_kubernetes_namespaceList: 'GRIDCOLUMNS_config_kubernetes_namespaceList',
    GRIDCOLUMNS_config_kubernetes_exportFile_configList:
      'GRIDCOLUMNS_config_kubernetes_exportFile_configList',
    GRIDCOLUMNS_config_application_wasList: 'GRIDCOLUMNS_config_application_wasList',
    GRIDCOLUMNS_config_application_wasListDetail_configList:
      'GRIDCOLUMNS_config_application_wasListDetail_configList',
    GRIDCOLUMNS_config_application_wasListDetail_configSetting:
      'GRIDCOLUMNS_config_application_wasListDetail_configSetting',
    GRIDCOLUMNS_config_database_instance_instanceList:
      'GRIDCOLUMNS_config_database_instance_instanceList',
    GRIDCOLUMNS_config_database_agentManagementListDetail_instanceList:
      'GRIDCOLUMNS_config_database_agentManagementListDetail_instanceList',
    GRIDCOLUMNS_config_database_instanceListDetail_customConfiguration:
      'GRIDCOLUMNS_config_database_instanceListDetail_customConfiguration',
  },
  MONITORING: {
    GRIDCOLUMNS_config_business_businessList: 'GRIDCOLUMNS_config_business_businessList',
    GRIDCOLUMNS_config_log_loggingList: 'GRIDCOLUMNS_config_log_loggingList',
    GRIDCOLUMNS_config_log_loggingListDetail_accessLog:
      'GRIDCOLUMNS_config_log_loggingListDetail_accessLog',
    GRIDCOLUMNS_config_log_loggingListDetail_pathList:
      'GRIDCOLUMNS_config_log_loggingListDetail_pathList',
    GRIDCOLUMNS_config_log_pathList: 'GRIDCOLUMNS_config_log_pathList',
    GRIDCOLUMNS_config_log_pathListDetail_filePathGuide:
      'GRIDCOLUMNS_config_log_pathListDetail_filePathGuide',
    GRIDCOLUMNS_config_log_pathListDetail_config: 'GRIDCOLUMNS_config_log_pathListDetail_config',
    GRIDCOLUMNS_config_report_dashboardList: 'GRIDCOLUMNS_config_report_dashboardList',
    GRIDCOLUMNS_config_report_scheduleList: 'GRIDCOLUMNS_config_report_scheduleList',
    GRIDCOLUMNS_config_report_scheduleListDetail_sendInformation:
      'GRIDCOLUMNS_config_report_scheduleListDetail_sendInformation',
    GRIDCOLUMNS_config_license_licenseList: 'GRIDCOLUMNS_config_license_licenseList',
    GRIDCOLUMNS_config_license_licenseTarget_List: 'GRIDCOLUMNS_config_license_licenseTarget_List',
    GRIDCOLUMNS_config_license_hostList: 'GRIDCOLUMNS_config_license_hostList',
    GRIDCOLUMNS_config_license_ndmList: 'GRIDCOLUMNS_config_license_ndmList',
    GRIDCOLUMNS_config_license_kafkaList: 'GRIDCOLUMNS_config_license_kafkaList',
    GRIDCOLUMNS_config_license_containerList: 'GRIDCOLUMNS_config_license_containerList',
    GRIDCOLUMNS_config_license_appContainerList: 'GRIDCOLUMNS_config_license_appContainerList',
    GRIDCOLUMNS_config_license_apmList: 'GRIDCOLUMNS_config_license_apmList',
    GRIDCOLUMNS_config_license_redisList: 'GRIDCOLUMNS_config_license_redisList',
    GRIDCOLUMNS_config_license_postgresqlList: 'GRIDCOLUMNS_config_license_postgresqlList',
    GRIDCOLUMNS_config_license_mysqlList: 'GRIDCOLUMNS_config_license_mysqlList',
    GRIDCOLUMNS_config_license_oracleList: 'GRIDCOLUMNS_config_license_oracleList',
    GRIDCOLUMNS_config_license_sqlServerList: 'GRIDCOLUMNS_config_license_sqlServerList',
  },
  ALERT: {
    GRIDCOLUMNS_config_userAlert_ruleList: 'GRIDCOLUMNS_config_userAlert_ruleList',
    GRIDCOLUMNS_config_userAlertRuleSetting_ruleDataEventPreview:
      'GRIDCOLUMNS_config_userAlertRuleSetting_ruleDataEventPreview',
    GRIDCOLUMNS_config_userAlertRuleSetting_ruleDataEventLogging:
      'GRIDCOLUMNS_config_userAlertRuleSetting_ruleDataEventLogging',
    GRIDCOLUMNS_config_systemAlert_hostList: 'GRIDCOLUMNS_config_systemAlert_hostList',
    GRIDCOLUMNS_config_systemAlert_deviceList: 'GRIDCOLUMNS_config_systemAlert_deviceList',
    GRIDCOLUMNS_config_systemAlert_nodeList: 'GRIDCOLUMNS_config_systemAlert_nodeList',
    GRIDCOLUMNS_config_systemAlert_wasList: 'GRIDCOLUMNS_config_systemAlert_wasList',
    GRIDCOLUMNS_config_systemAlert_instanceList: 'GRIDCOLUMNS_config_systemAlert_instanceList',
    GRIDCOLUMNS_config_notification_slackNotificationList:
      'GRIDCOLUMNS_config_notification_slackNotificationList',
    GRIDCOLUMNS_config_notification_telegramNotificationList:
      'GRIDCOLUMNS_config_notification_telegramNotificationList',
    GRIDCOLUMNS_config_notification_mailSMTPNotificationList:
      'GRIDCOLUMNS_config_notification_mailSMTPNotificationList',
    GRIDCOLUMNS_config_notification_mailNCPNotificationList:
      'GRIDCOLUMNS_config_notification_mailNCPNotificationList',
    GRIDCOLUMNS_config_notification_smsNotificationList:
      'GRIDCOLUMNS_config_notification_smsNotificationList',
    GRIDCOLUMNS_config_notification_webhookNotificationList:
      'GRIDCOLUMNS_config_notification_webhookNotificationList',
  },
  MANAGEMENT: {
    GRIDCOLUMNS_config_tag_valueList: 'GRIDCOLUMNS_config_tag_valueList',
    GRIDCOLUMNS_config_dataRetention_tableList: 'GRIDCOLUMNS_config_dataRetention_tableList',
    GRIDCOLUMNS_config_dataRetentionUsageTrend_tableList:
      'GRIDCOLUMNS_config_dataRetentionUsageTrend_tableList',
    GRIDCOLUMNS_config_dataRetentionUsageTrend_retentionSetting:
      'GRIDCOLUMNS_config_dataRetentionUsageTrend_retentionSetting',
  },
  COMMON: {
    GRIDCOLUMNS_config_tagInformation: 'GRIDCOLUMNS_config_tagInformation',
    GRIDCOLUMNS_config_alertNotification: 'GRIDCOLUMNS_config_alertNotification',
  },
};
