import { LOGIN_CUSTOMER } from '@/common/define/common.define';
import { LoginCustomer } from '@/login/utils/types';
import { XmServerProperty } from '@/openapi/secure/model';
import { defineStore } from 'pinia';
import { Component, computed, defineAsyncComponent, ref } from 'vue';

export type PropertyType = XmServerProperty;
type LoginComponentMap = Record<LoginCustomer, Component>;

interface CustomOption {
  useLoginAnimation?: boolean;
  useLoadingAnimation?: boolean;
  useLoginTitle?: boolean;
}

const loginOptions: Record<LoginCustomer, CustomOption> = {
  [LOGIN_CUSTOMER.EXEMONE]: {
    useLoginAnimation: true,
    useLoadingAnimation: true,
    useLoginTitle: true,
  },
  [LOGIN_CUSTOMER.HYUNDAICAPITAL]: {
    useLoginAnimation: true,
    useLoadingAnimation: true,
    useLoginTitle: false,
  },
  [LOGIN_CUSTOMER.LGE]: {
    useLoginAnimation: false,
    useLoadingAnimation: false,
    useLoginTitle: true,
  },
};

const loginComponent: LoginComponentMap = {
  EXEMONE: defineAsyncComponent(() => import('@/login/components/loginForm/LoginForm.vue')),
  HYUNDAICAPITAL: defineAsyncComponent(
    () => import('@/login/components/loginForm/hyundaicapital/HyundaiCapitalLoginForm.vue'),
  ),
  LGE: defineAsyncComponent(() => import('@/login/components/loginForm/LoginForm.vue')),
};

const loadStyle: Record<LoginCustomer, null | (() => Promise<typeof import('*.scss')>)> = {
  [LOGIN_CUSTOMER.EXEMONE]: null,
  [LOGIN_CUSTOMER.HYUNDAICAPITAL]: () => import('@/common/styles/customer/_hyundaicapital.scss'),
  [LOGIN_CUSTOMER.LGE]: () => import('@/common/styles/customer/_lge.scss'),
};

export const usePropertyStore = defineStore('property', () => {
  const propertyData = ref({} as PropertyType);
  const isSessionTimerActive = () => !!propertyData.value.sessionTimeoutMode;
  const isValidUser = () =>
    Object.keys(LOGIN_CUSTOMER).includes(
      propertyData.value.loginCustomer ?? LOGIN_CUSTOMER.EXEMONE,
    );
  const loginCustomer = computed(
    () =>
      (isValidUser() ? propertyData.value?.loginCustomer : LOGIN_CUSTOMER.EXEMONE) ||
      LOGIN_CUSTOMER.EXEMONE,
  );

  const getOptions = {
    loginOptions: () => loginOptions[loginCustomer.value],
    loginComponent: (customer?: LoginCustomer) => loginComponent[customer ?? loginCustomer.value],
    loadStyle: () => loadStyle[loginCustomer.value]?.(),
  };

  const isMatchedCustomer = (customer: LoginCustomer) => loginCustomer.value === customer;

  return {
    propertyData,
    isSessionTimerActive,
    loginCustomer,
    getOptions,
    isMatchedCustomer,
  };
});
