/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  SlowQueriesV7Response,
  SqlByIntervalV7Response,
  SqlDetailV7Response,
  SqlIntervalSearchResponse,
  SqlListByIntervalResponse,
  SqlSortByResponse,
  SqlSummaryListResponse,
  SqlTextBySqlIdResponse,
  TopSqlAnalysisV7Response,
  TopSqlSortByV7Response,
  TopSqlV7Response,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const slowQueriesSqlListV7ControllerAxios = ({
  instanceIds,
  fromTime,
  isFullText,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  instanceIds: Array<string>;
  fromTime?: string;
  isFullText?: boolean;
  period?:
    | 'p10m'
    | 'p10s'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p1s'
    | 'p30m'
    | 'p30s'
    | 'p3h'
    | 'p3m'
    | 'p3s'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SlowQueriesV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/slow-queries';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      isFullText,
      period,
      toTime,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const topSqlListSqlListV7ControllerAxios = ({
  instanceId,
  size,
  sort,
  fromTime,
  isFullText,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  instanceId: string;
  size: number;
  sort: 'blkWriteTime' | 'tempBlksWritten';
  fromTime?: string;
  isFullText?: boolean;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopSqlSortByV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/sqls-10min'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      isFullText,
      period,
      size,
      sort,
      toTime,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getSqlByIntervalSqlListV7ControllerAxios = ({
  column,
  instanceId,
  dbUserIds,
  fromTime,
  period,
  queryIds,
  toTime,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  column:
    | 'calls'
    | 'diskBlksWritten'
    | 'meanTime'
    | 'sharedBlksHit'
    | 'sharedBlksRead'
    | 'tempRead'
    | 'totalTime';
  instanceId: string;
  dbUserIds?: Array<number>;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  queryIds?: Array<string>;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SqlByIntervalV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/trend/sql-by-interval';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      column,
      dbUserIds,
      fromTime,
      instanceId,
      period,
      queryIds,
      toTime,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopSqlAnalysisSqlListV7ControllerAxios = ({
  instanceId,
  size,
  sort,
  dbUserId,
  fromTime,
  isFullText,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  instanceId: string;
  size: number;
  sort:
    | 'calls'
    | 'diskBlksWritten'
    | 'meanTime'
    | 'sharedBlksHit'
    | 'sharedBlksRead'
    | 'tempRead'
    | 'totalTime';
  dbUserId?: number;
  fromTime?: string;
  isFullText?: boolean;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopSqlAnalysisV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/trend/top-sql-analysis';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      dbUserId,
      fromTime,
      instanceId,
      isFullText,
      period,
      size,
      sort,
      toTime,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopSqlSqlListV7ControllerAxios = ({
  instanceId,
  size,
  fromTime,
  isFullText,
  period,
  sort,
  toTime,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  instanceId: string;
  size: number;
  fromTime?: string;
  isFullText?: boolean;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  sort?:
    | 'calls'
    | 'diskBlksWritten'
    | 'meanTime'
    | 'sharedBlksHit'
    | 'sharedBlksRead'
    | 'tempRead'
    | 'totalTime';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopSqlV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/trend/{instanceId}/top-sql'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      isFullText,
      period,
      size,
      sort,
      toTime,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sqlListSqlListV7ControllerAxios = ({
  instanceId,
  fromTime,
  isFullText,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  instanceId: string;
  fromTime?: string;
  isFullText?: boolean;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SqlSortByResponse> => {
  const localVarPath = '/api/v7/postgres/{instanceId}/sql-list'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      isFullText,
      period,
      toTime,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sqlListByIntervalSqlListV7ControllerAxios = ({
  instanceIds,
  interval,
  queryId,
  fromTime,
  period,
  sqlId,
  timezone,
  toTime,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  instanceIds: Array<string>;
  interval:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  queryId: string;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  sqlId?: string;
  timezone?:
    | 'Africa_Cairo'
    | 'America_Argentina_BuenosAires'
    | 'America_Caracas'
    | 'America_Chicago'
    | 'America_GlaceBay'
    | 'America_New_York'
    | 'America_Nome'
    | 'America_Noronha'
    | 'America_Phoenix'
    | 'America_StJohns'
    | 'America_Tijuana'
    | 'Asia_Anadyr'
    | 'Asia_Bangkok'
    | 'Asia_Dhaka'
    | 'Asia_Kabul'
    | 'Asia_Kathmandu'
    | 'Asia_Kolkata'
    | 'Asia_Rangoon'
    | 'Asia_Seoul'
    | 'Asia_Shanghai'
    | 'Asia_Tashkent'
    | 'Asia_Yerevan'
    | 'Atlantic_Azores'
    | 'Australia_Adelaide'
    | 'Australia_Brisbane'
    | 'Australia_Darwin'
    | 'Europe_Berlin'
    | 'Europe_London'
    | 'Europe_Moscow'
    | 'Pacific_Apia'
    | 'Pacific_Honolulu'
    | 'Pacific_Midway'
    | 'Pacific_Noumea';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SqlListByIntervalResponse> => {
  const localVarPath = '/api/v7/postgres/sql-list-by-interval';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      interval,
      period,
      queryId,
      sqlId,
      timezone,
      toTime,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sqlSummaryListSqlListV7ControllerAxios = ({
  instanceIds,
  fromTime,
  isFullText,
  period,
  queryId,
  sqlId,
  sqlText,
  toTime,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  instanceIds: Array<string>;
  fromTime?: string;
  isFullText?: boolean;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  queryId?: string;
  sqlId?: string;
  sqlText?: string;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SqlSummaryListResponse> => {
  const localVarPath = '/api/v7/postgres/sql-summary-list';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      isFullText,
      period,
      queryId,
      sqlId,
      sqlText,
      toTime,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sqlTextSqlListV7ControllerAxios = ({
  sqlId,
  sqlTextHashId,
  fromTime,
  instanceId,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  sqlId: string;
  sqlTextHashId: string;
  fromTime?: string;
  instanceId?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SqlTextBySqlIdResponse> => {
  const localVarPath = '/api/v7/postgres/sql-text';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceId,
      period,
      sqlId,
      sqlTextHashId,
      toTime,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sqlDetailSqlListV7ControllerAxios = ({
  instanceId,
  dbId,
  dbUserId,
  fromTime,
  period,
  queryId,
  sqlId,
  toTime,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  instanceId: string;
  dbId?: number;
  dbUserId?: number;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  queryId?: string;
  sqlId?: string;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SqlDetailV7Response> => {
  const localVarPath = '/api/v7/postgres/{instanceId}/sqls'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      dbId,
      dbUserId,
      fromTime,
      period,
      queryId,
      sqlId,
      toTime,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sqlByIntervalSearchSqlListV7ControllerAxios = ({
  column,
  instanceIds,
  interval,
  fromTime,
  period,
  queryId,
  timezone,
  toTime,
  username,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  column: 'calls' | 'sharedBlockHit' | 'sharedBlockRead' | 'totalTime';
  instanceIds: Array<string>;
  interval:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  queryId?: string;
  timezone?:
    | 'Africa_Cairo'
    | 'America_Argentina_BuenosAires'
    | 'America_Caracas'
    | 'America_Chicago'
    | 'America_GlaceBay'
    | 'America_New_York'
    | 'America_Nome'
    | 'America_Noronha'
    | 'America_Phoenix'
    | 'America_StJohns'
    | 'America_Tijuana'
    | 'Asia_Anadyr'
    | 'Asia_Bangkok'
    | 'Asia_Dhaka'
    | 'Asia_Kabul'
    | 'Asia_Kathmandu'
    | 'Asia_Kolkata'
    | 'Asia_Rangoon'
    | 'Asia_Seoul'
    | 'Asia_Shanghai'
    | 'Asia_Tashkent'
    | 'Asia_Yerevan'
    | 'Atlantic_Azores'
    | 'Australia_Adelaide'
    | 'Australia_Brisbane'
    | 'Australia_Darwin'
    | 'Europe_Berlin'
    | 'Europe_London'
    | 'Europe_Moscow'
    | 'Pacific_Apia'
    | 'Pacific_Honolulu'
    | 'Pacific_Midway'
    | 'Pacific_Noumea';
  toTime?: string;
  username?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SqlIntervalSearchResponse> => {
  const localVarPath = '/api/v7/postgresql/search/sql-by-interval';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      column,
      fromTime,
      instanceIds,
      interval,
      period,
      queryId,
      timezone,
      toTime,
      username,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  slowQueriesSqlListV7ControllerAxios,
  topSqlListSqlListV7ControllerAxios,
  getSqlByIntervalSqlListV7ControllerAxios,
  getTopSqlAnalysisSqlListV7ControllerAxios,
  getTopSqlSqlListV7ControllerAxios,
  sqlListSqlListV7ControllerAxios,
  sqlListByIntervalSqlListV7ControllerAxios,
  sqlSummaryListSqlListV7ControllerAxios,
  sqlTextSqlListV7ControllerAxios,
  sqlDetailSqlListV7ControllerAxios,
  sqlByIntervalSearchSqlListV7ControllerAxios,
};
