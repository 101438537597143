import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { Ref, ref, toRaw, unref } from 'vue';
import { Command } from '@/worker/commands';
import { CommandOptions, useCommand } from '@/worker/composables/useCommand';
import { getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { StatItem } from '@/openapi/data/model';
import { mapMetricNamesToDataIds } from '@/database/utils/utils';
import { DB_TYPE } from '@/common/utils';
import { addXmUserEnvControllerAxios } from '@/openapi/metav8Over/api/xm-user-env-controller-api';

export type OverviewMetricData = {
  metricName: string;
  value: number;
  width: number | string;
};

export type OverviewChartData = {
  instanceId: string;
  instanceName: string;
  maxAlertLevel?: number;
  metrics: OverviewMetricData[];
};

interface OverviewState {
  metricNameInfo: StatItem[];
  metricNamesInChartUsed: string[];
  displayNamesInChartUsed: string[];
  overviewChart: Ref<OverviewChartData[]>;
  errorStatusText: Ref<string>;
}

const OVERVIEW_METRIC_NAMES_KEY = 'ORACLE_OVERVIEW_METRIC_NAMES';
const frameName = 'Overview';

// 요청 파라미터에 추가하지 않아도 응답에 항상 포함되어 있는 metric
const DEFAULT_METRIC_NAMES = [
  'cpu',
  'memory',
  'latency',
  'tps',
  'CPU time',
  'wait time',
  'DB time',
];
const DEFAULT_DISPLAY_NAMES = ['CPU', 'Memory', 'Latency', 'TPS'];

export const overview: Module<OverviewState, RootState> = {
  namespaced: true,
  state: {
    metricNameInfo: [],
    metricNamesInChartUsed: [],
    displayNamesInChartUsed: [],
    overviewChart: ref([]),
    errorStatusText: ref(''),
  },
  mutations: {
    setErrorStatusText: (state: OverviewState, errorStatusText: Ref<string>) => {
      state.errorStatusText = errorStatusText;
    },
    setOverviewChart: (state: OverviewState, data: Ref<OverviewChartData[]>) => {
      state.overviewChart = data;
    },
    setMetricNameInfo: (state: OverviewState, metricNames: StatItem[]) => {
      // category가 'Time Model'인 지표만 사용
      const timeModelMetricNameInfo = metricNames
        .filter((metricName) => metricName.category === 'Time Model')
        .map((metricInfo) => ({
          category: metricInfo.category,
          metricName: metricInfo.dataId,
          displayName: metricInfo.displayName,
          unit: metricInfo.unit,
        }));

      const defaultMetricNames = [
        {
          metricName: 'latency',
          displayName: 'Latency',
          unit: 'sec',
        },
        {
          metricName: 'tps',
          displayName: 'TPS',
          unit: 'sec',
        },
      ].map((metricInfo) => ({
        category: 'Time Model',
        metricName: metricInfo.metricName,
        displayName: metricInfo.displayName,
        unit: metricInfo.unit,
      }));

      state.metricNameInfo = [...defaultMetricNames, ...timeModelMetricNameInfo];
    },
    setMetricNamesInChartUsed: (state: OverviewState, metricNames: string[]) => {
      state.metricNamesInChartUsed = metricNames;
    },
    setDisplayNamesInChartUsed: (state: OverviewState, displayNames: string[]) => {
      state.displayNamesInChartUsed = displayNames;
    },
    changeMetricNamesInChartUsed: (
      state: OverviewState,
      { idx, metricName }: { idx: number; metricName: string },
    ) => {
      state.metricNamesInChartUsed[idx] = metricName;
    },
    changeDisplayNamesInChartUsed: (
      state: OverviewState,
      { idx, displayName }: { idx: number; displayName: string },
    ) => {
      state.displayNamesInChartUsed[idx] = displayName;
    },
  },
  actions: {
    initMetricNamesInChartUsed: async ({ commit, rootGetters }) => {
      const metricNamesInUserEnv =
        rootGetters['userEnv/getUserEnvMap'].get(OVERVIEW_METRIC_NAMES_KEY) ?? '{}';
      const parseMetricNamesInUserEnv = JSON.parse(metricNamesInUserEnv);
      if (parseMetricNamesInUserEnv?.metricNames) {
        await commit(
          'setMetricNamesInChartUsed',
          mapMetricNamesToDataIds({
            dbType: DB_TYPE.ORACLE,
            metricNames: parseMetricNamesInUserEnv.metricNames,
          }),
        );
      } else {
        await commit('setMetricNamesInChartUsed', [...DEFAULT_METRIC_NAMES.slice(0, 4)]);
      }

      if (parseMetricNamesInUserEnv?.displayNames) {
        await commit('setDisplayNamesInChartUsed', parseMetricNamesInUserEnv.displayNames);
      } else {
        await commit('setDisplayNamesInChartUsed', DEFAULT_DISPLAY_NAMES);
      }
    },
    async saveMetricNamesInUserEnv(
      { commit },
      { metricNames, displayNames }: { metricNames: string[]; displayNames: string[] },
    ) {
      const metricInfo = {
        metricNames,
        displayNames,
      };
      await addXmUserEnvControllerAxios({
        request: [
          {
            key: OVERVIEW_METRIC_NAMES_KEY,
            value: JSON.stringify(metricInfo),
          },
        ],
      });
      commit(
        'userEnv/setUserEnvMap',
        { key: OVERVIEW_METRIC_NAMES_KEY, value: JSON.stringify(metricInfo) },
        { root: true },
      );
    },
    fetchOverviewChart: async ({ state, commit, getters, rootGetters }) => {
      const command: Command<'oracle'> = {
        namespace: 'oracle',
        method: 'overviewChart',
        params: {
          metricNames: toRaw(unref(getters.getMetricNamesInChartUsed)),
          instanceIds: toRaw(unref(rootGetters['oracleMultiViewEnv/getInstanceIds'])),
        },
      };

      const options: CommandOptions = {
        initialData: state.overviewChart,
        immediate: false,
      };

      const { data, apiTraceInfo, error, execute } = useCommand<OverviewChartData[]>(
        command,
        options,
      );
      await execute(command.params);

      commit('setOverviewChart', data);
      commit('setErrorStatusText', getAPIErrorStatusText(error.value));
      commit('setAPITrace', { frameName, apiTraceInfo }, { root: true });
      commit(
        'oracleMultiViewEnv/setFramesByFailedApi',
        { frameName, statusText: getAPIErrorStatusText(error.value) },
        { root: true },
      );
    },
  },
  getters: {
    getErrorStatusText: (state: OverviewState) => state.errorStatusText,
    getOverviewChart: (state: OverviewState) => state.overviewChart,
    getMetricNameInfo: (state: OverviewState) => state.metricNameInfo,
    getMetricNamesInChartUsed: (state: OverviewState) => state.metricNamesInChartUsed,
    getDisplayNamesInChartUsed: (state: OverviewState) => state.displayNamesInChartUsed,
  },
};
