import { RouteRecordRaw } from 'vue-router';
import { requireAuth, requireInstances, setDayjsTimezone } from '@/common/router/navigationGuard';
import RtmViewport from '@/common/components/templates/RtmViewport.vue';
import PaViewport from '@/common/components/templates/PaViewport.vue';
import { VIEW_MODE } from '@/common/define/common.define';
import { extractAccessibleRoutes } from '@/common/auth/auth.utils';
import { ROLE_PERMISSION_KEY } from '@/common/define/rolePermission.define';

const PREFIX_MYSQL = 'mysql_';

const mysqlMenu: RouteRecordRaw[] = [
  {
    path: '/mysql/',
    name: `${PREFIX_MYSQL}Real Time Monitoring`,
    component: RtmViewport,
    children: [
      {
        path: 'multiView',
        name: `${PREFIX_MYSQL}Multi View`,
        component: () =>
          import(/* webpackChunkName: "mysql-multi-view" */ '../views/multiView/MultiView.vue'),
      },
      {
        path: 'singleView',
        name: `${PREFIX_MYSQL}Single View`,
        component: () =>
          import(/* webpackChunkName: "mysql-single-view" */ '../views/singleView/SingleView.vue'),
      },
    ],
    beforeEnter: [requireAuth, requireInstances, setDayjsTimezone],
    meta: {
      rolePermission: ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_DASHBOARD,
    },
  },
  {
    path: '/mysql/',
    name: `${PREFIX_MYSQL}SQL Analysis`,
    component: PaViewport,
    children: [
      {
        path: 'topNAnalysis',
        name: `${PREFIX_MYSQL}Top N Analysis`,
        component: () =>
          import(
            /* webpackChunkName: "mysql-top-n-analysis" */ '../views/topNAnalysis/TopNAnalysis.vue'
          ),
        beforeEnter: [requireInstances],
      },
      {
        path: 'searchSql',
        name: `${PREFIX_MYSQL}Search SQL`,
        component: () =>
          import(/* webpackChunkName: "mysql-search-sql" */ '../views/searchSql/SearchSql.vue'),
        beforeEnter: [requireInstances],
      },
    ],
    beforeEnter: [requireAuth],
    meta: {
      rolePermission: {
        [VIEW_MODE.EXEMONE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ANALYSIS_DATABASE,
        [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_MYSQL_SQL_ANALYSIS,
      },
    },
  },
  {
    path: '/mysql/',
    name: `${PREFIX_MYSQL}Session Analysis`,
    component: PaViewport,
    children: [
      {
        path: 'searchSession',
        name: `${PREFIX_MYSQL}Search Session`,
        component: () =>
          import(
            /* webpackChunkName: "mysql-search-session" */ '../views/searchSession/SearchSession.vue'
          ),
      },
    ],
    beforeEnter: [requireAuth, requireInstances],
    meta: {
      rolePermission: {
        [VIEW_MODE.EXEMONE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ANALYSIS_DATABASE,
        [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_MYSQL_SESSION_ANALYSIS,
      },
    },
  },
  {
    path: '/mysql/',
    name: `${PREFIX_MYSQL}Performance Analysis`,
    component: PaViewport,
    children: [
      {
        path: 'trendAnalysis',
        name: `${PREFIX_MYSQL}Trend Analysis`,
        component: () =>
          import(
            /* webpackChunkName: "mysql-trend-analysis" */ '../views/trendAnalysis/TrendAnalysis.vue'
          ),
        beforeEnter: [requireInstances],
      },
      {
        path: 'parameterHistory',
        name: `${PREFIX_MYSQL}Parameter History`,
        component: () =>
          import(
            /* webpackChunkName: "mysql-parameter-history" */ '../views/parameterHistory/ParameterHistory.vue'
          ),
        beforeEnter: [requireInstances],
      },
    ],
    beforeEnter: [requireAuth],
    meta: {
      rolePermission: {
        [VIEW_MODE.EXEMONE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ANALYSIS_DATABASE,
        [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_MYSQL_PERFORMANCE_ANALYSIS,
      },
    },
  },
  {
    path: '/mysql/',
    name: `${PREFIX_MYSQL}DataSpace Analysis`,
    component: PaViewport,
    children: [
      {
        path: 'objectSize',
        name: `${PREFIX_MYSQL}Object Size`,
        component: () =>
          import(
            /* webpackChunkName: "mysql-object-size-analysis" */ '../views/objectSize/ObjectSize.vue'
          ),
      },
    ],
    beforeEnter: [requireAuth, requireInstances],
    meta: {
      rolePermission: {
        [VIEW_MODE.EXEMONE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ANALYSIS_DATABASE,
        [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_MYSQL_DATASPACE_ANALYSIS,
      },
    },
  },
];

const subMenuMappingMySql = {
  instanceView: `${PREFIX_MYSQL}Real Time Monitoring`,
  sqlAnalysis: `${PREFIX_MYSQL}SQL Analysis`,
  sessionAnalysis: `${PREFIX_MYSQL}Session Analysis`,
  performanceAnalysis: `${PREFIX_MYSQL}Performance Analysis`,
  dataSpaceAnalysis: `${PREFIX_MYSQL}DataSpace Analysis`,
};

const getMysqlNaviMenu = (
  subMenu?:
    | 'instanceView'
    | 'sqlAnalysis'
    | 'sessionAnalysis'
    | 'performanceAnalysis'
    | 'dataSpaceAnalysis',
) => {
  const filteredMySqlMenu = extractAccessibleRoutes(mysqlMenu);

  if (subMenu && subMenuMappingMySql[subMenu]) {
    return filteredMySqlMenu.filter((menu) => menu.name === subMenuMappingMySql[subMenu]);
  }

  return filteredMySqlMenu.filter((menu) => menu.name !== subMenuMappingMySql.instanceView);
};

export { mysqlMenu, getMysqlNaviMenu };
