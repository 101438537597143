/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  ClusterInformationResponse,
  ClusterMapResponse,
  DbClusterListResponse,
  DbClusterRequest,
  InstanceListV8Response,
  InstanceTargetResponse,
  InstanceTestConnectionResponse,
  InstanceTestDbConnectionRequestV8,
  InstanceV6Response,
  InstanceV8Request,
  InstanceV8Response,
  OsClusterListResponse,
  OsClusterRequest,
  ResultResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const removeInstancesMetaDatabaseInstanceV8ControllerAxios = ({
  instanceIds,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  instanceIds: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v8/database-instance/database-instances';
  const httpMethod = 'delete';

  const data: any = {};
  const config: any = {
    params: {
      instanceIds,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getClusterInformationMetaDatabaseInstanceV8ControllerAxios = ({
  instanceId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  instanceId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ClusterInformationResponse> => {
  const localVarPath = '/api/v8/database-instance/{instanceId}/cluster/information'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getClusterMapMetaDatabaseInstanceV8ControllerAxios = ({
  instanceId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  instanceId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ClusterMapResponse> => {
  const localVarPath = '/api/v8/database-instance/{instanceId}/cluster/map'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getAllGroupInstancesMetaDatabaseInstanceV8ControllerAxios = ({
  databaseGroupId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  databaseGroupId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<InstanceListV8Response> => {
  const localVarPath =
    '/api/v8/database-instance/database-groups/{databaseGroupId}/database-instances'.replace(
      `{${'databaseGroupId'}}`,
      encodeURIComponent(String(databaseGroupId)),
    );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getByInstanceIdMetaDatabaseInstanceV8ControllerAxios = ({
  instanceId,
  tenantId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  instanceId: string;
  tenantId?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<InstanceV8Response> => {
  const localVarPath = '/api/v8/database-instance/instances/{instanceId}'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      tenantId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTargetCheckMetaDatabaseInstanceV8ControllerAxios = ({
  instanceId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  instanceId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<InstanceTargetResponse> => {
  const localVarPath = '/api/v8/database-instance/{instanceId}/target-check'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const updateMetaDatabaseInstanceV8ControllerAxios = ({
  instanceId,
  request,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  instanceId: string;
  request: InstanceV8Request;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<InstanceV6Response> => {
  const localVarPath = '/api/v8/database-instance/{instanceId}'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const addMetaDatabaseInstanceV8ControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  request: InstanceV8Request;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<InstanceV6Response> => {
  const localVarPath = '/api/v8/database-instance';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getDbClusterRoleMetaDatabaseInstanceV8ControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  request: DbClusterRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<DbClusterListResponse> => {
  const localVarPath = '/api/v8/database-instance/db-cluster-role';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getOsClusterListMetaDatabaseInstanceV8ControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  request: OsClusterRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<OsClusterListResponse> => {
  const localVarPath = '/api/v8/database-instance/os-cluster-list';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const testDbConnectionV8MetaDatabaseInstanceV8ControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  request: InstanceTestDbConnectionRequestV8;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<InstanceTestConnectionResponse> => {
  const localVarPath = '/api/v8/database-instance/test-db-connection';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  removeInstancesMetaDatabaseInstanceV8ControllerAxios,
  getClusterInformationMetaDatabaseInstanceV8ControllerAxios,
  getClusterMapMetaDatabaseInstanceV8ControllerAxios,
  getAllGroupInstancesMetaDatabaseInstanceV8ControllerAxios,
  getByInstanceIdMetaDatabaseInstanceV8ControllerAxios,
  getTargetCheckMetaDatabaseInstanceV8ControllerAxios,
  updateMetaDatabaseInstanceV8ControllerAxios,
  addMetaDatabaseInstanceV8ControllerAxios,
  getDbClusterRoleMetaDatabaseInstanceV8ControllerAxios,
  getOsClusterListMetaDatabaseInstanceV8ControllerAxios,
  testDbConnectionV8MetaDatabaseInstanceV8ControllerAxios,
};
