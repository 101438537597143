import { useInternational } from '@/common/locale';
import router from '@/common/router';
import { useAuthStore } from '@/common/stores/auth';
import { usePropertyStore } from '@/common/stores/property';
import { ONE_MINUTE, ONE_SECOND } from '@/common/utils/define';
import { useRepeat } from '@/worker/composables/useRepeat';
import { throttle } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { AppConfig, computed, getCurrentInstance, onBeforeUnmount, onMounted, ref } from 'vue';
import { useSessionTimerStore } from '@/common/stores/session-timer';

const FETCH_CYCLE = ONE_SECOND;
const FIVE_MINUTES = 5 * (ONE_MINUTE / ONE_SECOND);

const setup = () => {
  const ctx: AppConfig['globalProperties'] =
    getCurrentInstance()!.appContext.config.globalProperties;
  const { t } = useInternational();
  const rootElement = document.querySelector('body');
  const tokenInfo = computed(() => ({
    sessionToken: useAuthStore().getToken('sessionToken') ?? '',
    accessToken: useAuthStore().getToken('accessToken'),
    refreshToken: useAuthStore().getToken('refreshToken'),
  }));
  const isOpenModal = ref<boolean>(false);

  const sessionTimerStore = useSessionTimerStore();
  const { fetchSessionTime, updateSessionTime } = sessionTimerStore;
  const { isTimerRunning, isExpiredSessionTime, displaySessionTime, remainingSessionTime } =
    storeToRefs(sessionTimerStore);
  const isVisibleTimer = computed<boolean>(
    () => usePropertyStore().isSessionTimerActive() && isTimerRunning.value,
  );
  const isLessThanFiveMinutes = computed<boolean>(() => remainingSessionTime.value < FIVE_MINUTES);
  useRepeat(() => fetchSessionTime(tokenInfo.value.sessionToken), FETCH_CYCLE, {
    isSetup: true,
    isImmediate: true,
  });
  const invalidSessionTimeHandler = (event) => {
    if (!event.target.closest('.ev-message-box')) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (!isOpenModal.value) {
      ctx.$messagebox({
        message: t('MESSAGE.SESSION_TIMEOUT'),
        showClose: false,
        showCancelBtn: false,
        closeOnClickModal: false,
        useHTML: true,
        onClose: async () => {
          await router.push('/logout');
          isOpenModal.value = false;
        },
      });
      isOpenModal.value = true;
    }
  };

  const loginSessionTimeCheck = throttle(async (event): Promise<void> => {
    if (!isVisibleTimer.value) {
      return;
    }

    if (isExpiredSessionTime.value) {
      invalidSessionTimeHandler(event);
      return;
    }

    if (!isExpiredSessionTime.value && tokenInfo.value.sessionToken) {
      await updateSessionTime({
        refreshToken: tokenInfo.value.refreshToken,
        sessionToken: tokenInfo.value.sessionToken,
        accessToken: tokenInfo.value.accessToken,
      });
      await fetchSessionTime(tokenInfo.value.sessionToken);
    }
  }, ONE_SECOND);

  onMounted(() => {
    if (usePropertyStore().isSessionTimerActive()) {
      isTimerRunning.value = true;
      rootElement?.addEventListener('click', loginSessionTimeCheck, true);
    }
  });

  onBeforeUnmount(() => {
    if (usePropertyStore().isSessionTimerActive()) {
      isTimerRunning.value = false;
      rootElement?.removeEventListener('click', loginSessionTimeCheck, true);
    }
  });

  return {
    t,
    isVisibleTimer,
    isExpiredSessionTime,
    isLessThanFiveMinutes,
    displaySessionTime,
  };
};

export { setup };
