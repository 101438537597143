/**
 * 10분 데이터
 */
import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { setEvBarChartData } from '@/common/utils/chartUtils';
import { convertCamelToCapital, getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { getTopSessionSqlPostgresqlV7ControllerAxios } from '@/openapi/postgresqlV7/api/postgresql-v7-controller-api';
import { TopSessionSqlItem } from '@/openapi/postgresqlV7/model';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { addXmUserEnvControllerAxios } from '@/openapi/metav8Over/api/xm-user-env-controller-api';

export interface TopSqlState {
  topSql: TopSessionSqlItem[];
  orderBy: string;
  errorStatusText: string;
}

const TOP_SQL_ORDER_BY_KEY = 'PG_TOP_SQL_ORDER_BY';
export const topSql: Module<TopSqlState, RootState> = {
  namespaced: true,
  state: {
    topSql: [],
    orderBy: 'calls',
    errorStatusText: '',
  },
  mutations: {
    setTopSql: (state: TopSqlState, data: TopSessionSqlItem[]) => {
      state.topSql = data;
    },
    setOrderBy: (state: TopSqlState, orderByValue: string) => {
      state.orderBy = orderByValue;
    },
    setErrorStatusText: (state: TopSqlState, errorStatusText: string) => {
      state.errorStatusText = errorStatusText;
    },
  },
  actions: {
    fetchTopSqlOrderBy: async ({ commit, rootGetters }) => {
      const data = rootGetters['userEnv/getUserEnvMap'].get(TOP_SQL_ORDER_BY_KEY);
      commit('setOrderBy', data || 'calls');
    },
    saveTopSqlOrderBy: async ({ commit }, orderByValue: string) => {
      try {
        await commit('setOrderBy', orderByValue);
        await addXmUserEnvControllerAxios({
          request: [
            {
              key: TOP_SQL_ORDER_BY_KEY,
              value: orderByValue,
            },
          ],
        });
        commit(
          'userEnv/setUserEnvMap',
          { key: TOP_SQL_ORDER_BY_KEY, value: orderByValue },
          { root: true },
        );
      } catch (e: any) {
        console.log(e);
      }
    },
    fetchTopSql: async ({ getters, commit }, { instanceId }) => {
      const frameName = FRAME_NAMES.POSTGRESQL_SINGLE_VIEW.SQL_FUNCTION_TOP_SQL;
      try {
        const orderBy = await getters.getOrderBy;
        const { data } = await getTopSessionSqlPostgresqlV7ControllerAxios({
          instanceId,
          sort: orderBy,
          frameName: `${frameName}(${convertCamelToCapital(orderBy)})`,
        });
        commit('setTopSql', data.data);
        commit('setErrorStatusText', '');
        commit('postgresqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'postgresqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getTopSql: (state: TopSqlState) => state.topSql,
    getSqlIds: (state: TopSqlState) => state.topSql.map((v) => v.sqlId).reverse(),
    getCalls: (state: TopSqlState) => setEvBarChartData(state.topSql.map((v) => v.diff)),
    getErrorStatusText: (state: TopSqlState) => state.errorStatusText,
    getOrderBy: (state: TopSqlState) => state.orderBy,
  },
};
