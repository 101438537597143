import { camelCase, startCase } from 'lodash-es';
import { showErrorMsg, showSuccessMsg } from '@/common/utils/commonUtils';
import { LicenseTargetDisplayName } from './license.data';

export const getDisplayLicenseTargetName = (licenseTarget: string) => {
  return LicenseTargetDisplayName[licenseTarget] ?? startCase(camelCase(licenseTarget));
};

export const showLicenseNotification = (type: 'error' | 'success', ctx, message: string) => {
  if (type === 'error') {
    showErrorMsg(ctx, message);
  } else {
    showSuccessMsg(ctx, message);
  }
};
