import { getCurrentInstance } from 'vue';
import { defineStore } from 'pinia';
import { useInternational } from '@/common/locale';
import { showErrorMsg } from '@/common/utils/commonUtils';
import { useSlideDetail } from './slide-detail.logic';

export * from './slide-detail.logic';

export const useSlideDetailStore = defineStore('slideDetail', () => {
  const { addTabPanel, ...rest } = useSlideDetail();

  const ctx = getCurrentInstance()!.appContext.config.globalProperties;
  const { t } = useInternational();

  const withErrorMsg =
    <T extends (...args: any[]) => any>(fn: T) =>
    (...args: Parameters<T>) => {
      try {
        fn(...args);
      } catch (e: unknown) {
        if (e instanceof Error) {
          showErrorMsg(ctx, t(e.message));
        }
      }
    };

  return {
    addTabPanel: withErrorMsg(addTabPanel),
    ...rest,
  };
});
