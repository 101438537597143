<script setup lang="ts">
import { setup, type Emits, type Props } from './baseMenu.setup';

const props = defineProps<Props>();
const emits = defineEmits<Emits>();
const { onClickTitle } = setup(props, emits);
</script>

<template>
  <h1
    :class="['base-menu-header__logo', { expand: props.isExpand }]"
    @click="onClickTitle"
  >
    {{ props?.productName }}
  </h1>
  <div
    class="base-menu-header__text"
    @click="onClickTitle"
  />
</template>

<style scoped lang="scss">
.base-menu-header {
  &__logo {
    background: url('@/common/assets/logo-exem-one.svg') no-repeat center center;
    background-size: 30px 30px;

    .view-mode-maxgauge & {
      background-image: url('@/common/assets/logo-maxgauge.svg');
    }
  }

  &__text {
    flex: 1;
    height: 100%;
    cursor: pointer;
    background: url('@/common/assets/logo-exem-one-text-white.svg') no-repeat center left;

    .view-mode-maxgauge & {
      background-image: url('@/common/assets/logo-maxgauge-text-white.svg');
    }
  }
}
</style>
