import { computed, nextTick, onMounted, ref, useSlots, watch } from 'vue';
import { baseGridOption } from '@/common/utils/define';
import {
  useCustomCellRender,
  useCustomColumns,
  useDeselect,
  useGridSetting,
  useGridTooltip,
  useSearchBar,
} from '@/common/components/molecules/grid/uses';
import { debounce } from 'lodash-es';
import {
  ClickedCellInfo,
  ClickedRowInfo,
  ColumnWithHideInfo,
  CustomColumn,
  DblClickedRowInfo,
  GridOption,
  GridSearchBarOption,
  RowData,
} from '@/common/utils/types';
import { ExportExcelOption } from '@/common/utils/exportExcelUtils';
import { BasePaginationProps } from '@/common/components/molecules/basePagination/basePagination.setup';
import { useInternational } from '@/common/locale';
import { COLUMN_EVENT_TYPE } from '@/common/components/molecules/grid/baseGrid.define';

interface PaginationInfo extends BasePaginationProps {
  use?: boolean;
  useClient?: boolean;
}

export interface Props<
  ColumnData extends CustomColumn[] | Readonly<CustomColumn[]>,
  RowDataType = RowData<ColumnData>,
> {
  columns: ColumnData;
  rows: RowDataType[];
  columnEnvKey?: string;
  type?: 'page' | 'widget';
  disabledRows?: RowDataType[];
  selectedRows?: RowDataType[];
  uncheckable?: RowDataType[];
  checkedRows?: RowDataType[];
  searchWord?: string;
  option?: GridOption;
  searchBarOption?: GridSearchBarOption;
  tooltipOption?: 'list' | 'chip';
  shownChipCount?: 1;
  pagination?: PaginationInfo;
  exportExcelOption?: ExportExcelOption;
  expanded?: RowDataType[];
  hasDynamicColumns?: boolean;
  refObj?: any;
  customClass?: {
    chipCellTooltip?: string[];
  };
}

export interface Emit<
  ColumnData extends CustomColumn[] | Readonly<CustomColumn[]>,
  RowDataType = RowData<ColumnData>,
> {
  (e: 'click-cell', value: ClickedCellInfo<RowDataType>): void;
  (e: 'click-cell-chip', value: { evt: any; chips: string[]; rowIndex: number }): void;
  (e: 'click-cell-chip-more', value: any): void;
  (
    e: 'page-change',
    value: {
      current: number;
      perDataCnt: NonNullable<BasePaginationProps['perData']>;
      total: number;
    },
  ): void;
  (e: 'on-search', value: string): void;
  (e: 'export-to-excel-from-server', value: { columns: ColumnData }): void;
  // v-model
  (e: 'update:selected-rows', value: RowDataType[]): void;
  (e: 'update:checked-rows', value: RowDataType[]): void;
  (e: 'update:ref-obj', value: RowDataType[]): void;
  (e: 'update:search-word', value: string): void;
  (e: 'update:columns', value: ColumnData): void;
  // ev-grid-event
  (e: 'check-row', isCheck: boolean, rowIndex: number, row: RowDataType): void;
  (e: 'check-all', isCheck: boolean, rows: RowDataType[]): void;
  (e: 'click-row', rowInfo: ClickedRowInfo<RowDataType>): void;
  (e: 'update:expanded', rowInfo: RowDataType[]): void;
  (e: 'dblclick-row', row: DblClickedRowInfo<RowDataType>): void;
  (
    e: 'sortColumn',
    value: { order: 'asc' | 'desc' | 'init'; field: string; column: CustomColumn },
  ): void;
  (e: 'change-column-status', gridInfos: { columns: ColumnWithHideInfo[] }): void;
  (e: 'change-column-info', columnInfo: { type: string; columns: ColumnWithHideInfo[] }): void;
}

export const useClassName = <
  ColumnData extends CustomColumn[] | Readonly<CustomColumn[]>,
  RowDataType = RowData<ColumnData>,
>(
  props: Props<ColumnData, RowDataType>,
) => {
  const optionClassName = computed(() => {
    const { searchBarOption, option } = props;
    const slots = useSlots();
    let headerOptionCount = 0;
    if (searchBarOption?.use) {
      headerOptionCount += 1;
    }
    if (option?.useGridSetting?.use || slots?.toolbar) {
      headerOptionCount += 1;
    }

    if (headerOptionCount) {
      return `has-option-h${headerOptionCount}`;
    }

    return '';
  });
  return { optionClassName };
};
const setup = <
  ColumnData extends CustomColumn[] | Readonly<CustomColumn[]>,
  RowDataType = RowData<ColumnData>,
>(
  props: Props<ColumnData, RowDataType>,
  emit: Emit<ColumnData, RowDataType>,
) => {
  const { t } = useInternational();

  const { gridSettingOption, setColumns: setColumnsForExcelDownload } = useGridSetting(props, emit);

  const computedOption = computed<GridOption>(() => ({
    ...baseGridOption,
    ...props.option,
    useGridSetting: gridSettingOption.value,
    page: {
      use: false,
    },
    columnMenuText: {
      ascending: t('WORD.ASCENDING'),
      descending: t('WORD.DESCENDING'),
      filter: t('WORD.FILTER'),
      hide: t('WORD.HIDE'),
    },
    emptyText: t('MESSAGE.NO_DATA'),
  }));

  const { computedColumns, customColumnFields, saveColumnInfo } = useCustomColumns(
    props,
    computedOption.value,
    setColumnsForExcelDownload,
  );
  const { optionClassName } = useClassName(props);

  const { slotNamesOfCustomCell } = useCustomCellRender();

  const { tooltipItems, customTooltipRef, clickCount, clickChip, hideTooltip } = useGridTooltip(
    props,
    emit,
  );

  const {
    searchWord,
    searchBarOption,
    filterGrid,

    filterSearchResultMV,
    filterGridByFilterSearch,
  } = useSearchBar(props, emit);

  const currentPageNum = ref(1);
  const perDataCnt = ref<number>(props.pagination?.perData ?? 20);

  const filterPageRange = (rowIdx, dataCountOfPage, currentPage) => {
    if (!props?.pagination?.use || props?.pagination?.useClient) {
      return true;
    }
    const fromIdx = dataCountOfPage * (currentPage - 1);
    const toIdx = dataCountOfPage * currentPage;

    return fromIdx <= rowIdx && rowIdx < toIdx;
  };

  const expanded = computed({
    get: () => props.expanded ?? [],
    set: (val) => emit('update:expanded', val),
  });

  const rows = computed(() => {
    const isTokenFilter = props?.searchBarOption?.use && props?.searchBarOption?.filterSearch?.use;
    const isClientSearchFilter =
      props?.searchBarOption?.use && props?.searchBarOption?.useClientFilter;

    if (isClientSearchFilter) {
      return props.rows;
    }

    if (isTokenFilter) {
      return filterGridByFilterSearch(props.rows, filterSearchResultMV.value);
    }

    return filterGrid(props.columns, props.rows, searchWord.value.trim().toLowerCase());
  });

  const gridRef = ref();

  const { selectedRows } = useDeselect(props, emit);

  const checkedRows = computed<RowDataType[]>({
    get: () => props.checkedRows ?? [],
    set: (val) => emit('update:checked-rows', val),
  });

  const refObj = computed<RowDataType[]>({
    get: () => props.refObj ?? {},
    set: (val) => emit('update:ref-obj', val),
  });

  const totalOfRows = computed(() => {
    const isClientTotal = props?.pagination?.use && props?.pagination?.useClient;
    return isClientTotal ? props?.pagination?.total : rows.value.length;
  });

  const clickCell = (event: MouseEvent, field, value, row, cellIndex) => {
    emit('click-cell', {
      event,
      field,
      value,
      row,
      cellIndex,
    });
  };

  const emitPageChange = debounce(() => {
    const { pagination } = props ?? {};
    if (pagination?.use) {
      const total = props.pagination?.useClient ? props.pagination.total : rows.value.length;

      emit('page-change', {
        current: currentPageNum.value,
        perDataCnt: perDataCnt.value,
        total,
      });
    }
  }, 100);

  const getDisplayRows = (filteredRows) =>
    props?.pagination?.use
      ? filteredRows.filter((d, idx) =>
          filterPageRange(idx, perDataCnt.value, currentPageNum.value),
        )
      : filteredRows;

  const emitSearch = debounce(() => {
    emit('on-search', searchWord.value.trim());
    currentPageNum.value = 1;
    emitPageChange();
  }, 300);

  const updateColumns = async (columns) => {
    if (!columns) {
      return;
    }
    await saveColumnInfo(columns);
    emit('update:columns', columns as ColumnData);
  };

  const emitEvent = {
    [COLUMN_EVENT_TYPE.DISPLAY]: (e) => {
      emit('change-column-status', e);
    },
    [COLUMN_EVENT_TYPE.SORT]: (e) => {
      emit('sortColumn', e);
    },
  };
  const onChangeColumnInfo = async (type, eventValue) => {
    const { columns } = eventValue ?? {};

    await updateColumns(columns);
    emitEvent[type]?.(eventValue);
    emit('change-column-info', { type, columns });
  };

  const currentTooltipFieldName = ref('');
  const currentTooltipRowData = ref({});
  const currentTooltipColumnIndex = ref(-1);
  const isChipCellClickable = computed(() => {
    return props.columns.some((column) => column.rendererType === 'chip-cell-clickable');
  });

  const clickCountWithFieldData = ({
    evt,
    chips,
    totalChips,
    rowIndex,
    fieldName,
    rowData,
    columnIndex,
  }) => {
    tooltipItems.value = totalChips;
    currentTooltipFieldName.value = fieldName;
    currentTooltipRowData.value = rowData;
    currentTooltipColumnIndex.value = columnIndex;
    emit('click-cell-chip-more', { chips, totalChips, rowIndex });
    customTooltipRef.value.show(evt);
  };

  const onClickTooltipItem = (
    event: MouseEvent,
    fieldName: string,
    value: string,
    rowData: any,
    columnIndex: number,
  ) => {
    clickCell(event, fieldName, value, rowData, columnIndex);
    hideTooltip();
  };

  watch(searchWord, () => {
    emitSearch();
  });

  // ## Comment 순수한 데이터 변경시에 page-change 호출을 회피하기 위한 변수.
  let isChangingPage = false;
  let isUpdatingGridBySelf = false;

  watch(
    () => [currentPageNum.value, perDataCnt.value],
    () => {
      if (isUpdatingGridBySelf) {
        return;
      }

      isChangingPage = true;
      emitPageChange();
    },
  );

  watch(rows, async (_new, _old) => {
    if (props.pagination?.use && !isChangingPage) {
      isUpdatingGridBySelf = true;
      currentPageNum.value = 1;
    }

    const tableBody = gridRef?.value?.querySelector('.table-body');
    const useScrollMaintain = props.option?.maintainScrollOnUpdateRows;
    if (tableBody) {
      const { scrollTop } = tableBody;

      nextTick(() => {
        // NOTE: _old[0] => 위로 추가된 경우에만 해당
        const newAddedLines = _new.findIndex((row) => row === _old[0]);
        const newAddedLinesHeight = newAddedLines * (props.option?.rowHeight ?? 0);

        if (useScrollMaintain && newAddedLines !== -1 && scrollTop > 10) {
          tableBody.scrollBy({
            top: newAddedLinesHeight,
          });
        }

        tableBody.scrollTo({
          top: useScrollMaintain ? undefined : 0,
          left: useScrollMaintain ? undefined : 0,
          behavior: 'auto',
        });
      });
    }

    isChangingPage = false;
    await nextTick();
    isUpdatingGridBySelf = false;
  });

  onMounted(() => {
    emitPageChange();
  });

  return {
    t,
    gridRef,
    rows,
    selectedRows,
    checkedRows,
    refObj,
    slotNamesOfCustomCell,
    searchWord,
    searchBarOption,
    computedColumns,
    computedOption,
    customColumnFields,
    optionClassName,
    tooltipItems,
    customTooltipRef,
    hideTooltip,
    clickCell,
    clickCount,
    clickChip,
    getDisplayRows,
    onChangeColumnInfo,
    currentPageNum,
    perDataCnt,
    totalOfRows,
    expanded,
    filterSearchResultMV,
    filterGridByFilterSearch,
    currentTooltipFieldName,
    currentTooltipRowData,
    currentTooltipColumnIndex,
    isChipCellClickable,
    onClickTooltipItem,
    clickCountWithFieldData,
  };
};

export { setup };
