/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  CreateLogPathRequest,
  LogPathResponse,
  ResultResponse,
  TargetLogPathResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const deletePathLogPathV8ControllerAxios = ({
  pathId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  pathId: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v8/meta/path';
  const httpMethod = 'delete';

  const data: any = {};
  const config: any = {
    params: {
      pathId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getALlPathListLogPathV8ControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<LogPathResponse> => {
  const localVarPath = '/api/v8/meta/path';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getPathLogPathV8ControllerAxios = ({
  pathId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  pathId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<LogPathResponse> => {
  const localVarPath = '/api/v8/meta/path/{pathId}'.replace(
    `{${'pathId'}}`,
    encodeURIComponent(String(pathId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTargetLogPathListLogPathV8ControllerAxios = ({
  loggingId,
  targetIds,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  loggingId: string;
  targetIds: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TargetLogPathResponse> => {
  const localVarPath = '/api/v8/meta/path/path-list/{loggingId}'.replace(
    `{${'loggingId'}}`,
    encodeURIComponent(String(loggingId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      targetIds,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const updatePathLogPathV8ControllerAxios = ({
  pathId,
  request,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  pathId: string;
  request: CreateLogPathRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v8/meta/path/{pathId}'.replace(
    `{${'pathId'}}`,
    encodeURIComponent(String(pathId)),
  );
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const addPathLogPathV8ControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  request: CreateLogPathRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v8/meta/path';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  deletePathLogPathV8ControllerAxios,
  getALlPathListLogPathV8ControllerAxios,
  getPathLogPathV8ControllerAxios,
  getTargetLogPathListLogPathV8ControllerAxios,
  updatePathLogPathV8ControllerAxios,
  addPathLogPathV8ControllerAxios,
};
