import { computed, reactive, ref } from 'vue';
import dayjs from 'dayjs';
import { useInternational } from '@/common/locale';
import { secondsToPeriod } from '@/common/utils/time.utils';
import { GLOBAL_TIME_RANGE_FORMAT } from '../timePeriodIndicator/timePeriodIndicator.define';

export interface Props {
  dateTimePeriod: string[];
  timeRangeSecLimit?: number;
}

export interface Emit {
  (e: 'update:isShow', value: boolean): void;
  (e: 'clickCancel'): void;
  (e: 'clickOk', value: string[]): void;
}

export const setup = (props: Props, emit: Emit) => {
  const { t } = useInternational();
  const FULL_DAY_RANGE = ['00:00:00', '23:59:59'];

  let from = dayjs(props.dateTimePeriod?.[0], GLOBAL_TIME_RANGE_FORMAT);
  if (!from.isValid()) from = dayjs().startOf('day');

  let to = dayjs(props.dateTimePeriod?.[1], GLOBAL_TIME_RANGE_FORMAT);
  if (!to.isValid()) to = dayjs().endOf('day');

  const fromDate = from.format('YYYY-MM-DD');
  const toDate = to.format('YYYY-MM-DD');
  const datePickerValues = ref([fromDate, toDate]);
  const timePickerValues = ref([...FULL_DAY_RANGE]);

  const fromDateTime = computed(() =>
    dayjs(`${datePickerValues.value[0]} ${timePickerValues.value[0]}`),
  );
  const toDateTime = computed(() =>
    dayjs(`${datePickerValues.value[1]} ${timePickerValues.value[1]}`),
  );

  const diffFromTo = computed(() => toDateTime.value.diff(fromDateTime.value, 'second'));
  const isOverLimit = computed(() => {
    if (!props.timeRangeSecLimit || props.timeRangeSecLimit < 0) return false;
    return diffFromTo.value > props.timeRangeSecLimit;
  });

  const timeValidityInfo = reactive({
    fromTime: computed(() => {
      if (isOverLimit.value) {
        const period = secondsToPeriod(props.timeRangeSecLimit!);
        return t('MESSAGE.VALIDATION_GUIDE', {
          value: t(`WORD.${period.locale}`, { value: period.value }),
        });
      }

      if (fromDateTime.value > toDateTime.value) {
        return t('WORD.ERROR');
      }

      return '';
    }),
    toTime: computed(() => {
      if (isOverLimit.value) {
        const period = secondsToPeriod(props.timeRangeSecLimit!);
        return t('MESSAGE.VALIDATION_GUIDE', {
          value: t(`WORD.${period.locale}`, { value: period.value }),
        });
      }

      if (fromDateTime.value > toDateTime.value) {
        return t('WORD.ERROR');
      }

      return '';
    }),
  });

  const onClickResetDateTimePicker = () => {
    const today = dayjs.tz().format('YYYY-MM-DD');
    datePickerValues.value = [today, today];
    timePickerValues.value = [...FULL_DAY_RANGE];
  };

  const getDisabledDate = (localeMidnightTime: Date) => {
    return dayjs(localeMidnightTime).format('YYYY-MM-DD') > dayjs.tz().format('YYYY-MM-DD');
  };

  const onClickCancel = () => {
    emit('clickCancel');
  };

  const onClickOk = () => {
    emit('clickOk', [
      fromDateTime.value.format('YYYY.MM.DD HH:mm:ss'),
      toDateTime.value.format('YYYY.MM.DD HH:mm:ss'),
    ]);
  };

  return {
    t,
    datePickerValues,
    timePickerValues,
    timeValidityInfo,
    isOverLimit,
    onClickResetDateTimePicker,
    getDisabledDate,
    onClickCancel,
    onClickOk,
  };
};
